import React, { useState, useMemo } from 'react';
import { object } from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { dateValueFormatter, dateWithoutTimeComparator, currentLocaleText } from '../../lib/ag-grid';
import BulkActionsSidebar from '../BulkActionsSidebar';
import ConfirmationModal from '../reusable/ConfirmationModal';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import { unassignRespondentFromGroups } from '../../store/respondents/actions';
import * as actions from '../../table/actionsList';
import respondentGroupsBulkActions from '../../table/bulkActions/respondentGroups';
import groupsTypesMap from '../../constants/groupTypes';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const RespondentGroups = ({ respondent }) => {
  const [selectedGroups, setSelectedGroups] = useState([]);
  const dispatch = useDispatch();
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedGroups });

  const [isRemoveFromGroupsModalOpen, setRemoveFromGroupsModalState] = useState(false);

  const onRemoveConfirm = () => {
    dispatch(
      unassignRespondentFromGroups(
        respondent.respondentID,
        selectedGroups.map((group) => group.groupID),
        () => {
          setRemoveFromGroupsModalState(false);
        },
      ),
    );
  };

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.REMOVE_RESPONDENT_FROM_GROUPS]: () => setRemoveFromGroupsModalState(true),
  };
  const bulkActions = useMemo(() => {
    return respondentGroupsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
    }));
  }, [selectedGroups]);

  const { groups } = respondent;

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      setSelectedGroups(selectedData);
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      field: 'description',
      headerName: I18n.t('Name'),
      celldatatype: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'notes',
      headerName: I18n.t('Description'),
      celldatatype: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'inviteType',
      headerName: I18n.t('Type'),
      celldatatype: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      valueGetter: ({ data }) => I18n.t(groupsTypesMap[data.groupType]),
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'creationDate',
      headerName: I18n.t('Creation Date'),
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      sort: 'desc',
      valueGetter: (params) => {
        return new Date(params.data.creationDate);
      },
      valueFormatter: dateValueFormatter,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        maxNumConditions: 1,
        comparator: dateWithoutTimeComparator,
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  return (
    <RespondentGroupsContainer>
      <BulkActionsSidebar actions={bulkActions} onClose={closeSidebar} isOpen={isSidebarOpen} />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={groups}
          rowSelection={rowSelection}
          onFilterChanged={handleFilterChanged}
          localeText={currentLocaleText}
        />
      </div>
      <ConfirmationModal
        title={I18n.t('Remove from groups')}
        onClose={() => setRemoveFromGroupsModalState(false)}
        isVisible={isRemoveFromGroupsModalOpen}
        description={I18n.t('Are you sure you want to remove respondent from selected group(s)')}
        onConfirm={onRemoveConfirm}
        caution
      />
    </RespondentGroupsContainer>
  );
};

RespondentGroups.propTypes = {
  respondent: object.isRequired,
};

const RespondentGroupsContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-bottom: 8rem;
  margin-top: 10px;
`;

export default RespondentGroups;
