/* eslint-disable no-param-reassign */

import * as actions from './actions';
import * as reportActions from '../reports/actions';
import handleActions from '../immerHandleActions';

import respondentsColumns from '../../table/columns/respondents';

import {
  TEXT_FILTER_TYPES,
  DATE_FILTER_TYPES,
  textFilterOptions,
  datesFilterOptions,
} from '../../constants/advancedFilterOptions';

const RESPONDENTS_FILTER_FIELDS = respondentsColumns
  .filter((item) => item.name !== 'actions')
  .map((column) => ({ name: column.name, type: column.type }));

const initialInputsState = RESPONDENTS_FILTER_FIELDS.map((fieldObj) => ({
  name: fieldObj.name,
  value: fieldObj.type === 'date' ? null : '',
  type: fieldObj.type,
  filterType:
    fieldObj.type === 'date'
      ? datesFilterOptions.find((item) => item.name === DATE_FILTER_TYPES.THREE_DAYS)
      : textFilterOptions.find((item) => item.name === TEXT_FILTER_TYPES.CONTAINS),
}));

const initialFilters = {
  isApplied: false,
  dirty: false,
  appliedInputs: initialInputsState,
  inputs: initialInputsState,
};

export const initPaginationState = {
  pageNumber: -1,
  pageSize: 5000,
  loaded: 0,
  totalCount: 0,
  allLoaded: false,
};

const initialState = {
  respondents: [],
  selectedRespondents: [],
  respondentToEdit: null,
  respondentInvites: [],
  search: '',
  pagesAvailable: 0,
  totalCount: 0,
  lastPageIndex: null,
  filter: initialFilters,
  availableReports: [],
  availableNorms: [],
  shouldResetTableState: false,
  lastTableParams: null,
  allRespondentsCount: 0,
  pagination: initPaginationState,
  answerSheet: {
    batteries: [],
    categories: [],
    languages: [],
    tests: [],
  },
};

const respondentsReducer = handleActions(
  // FILTERS PART
  {
    [actions.updatePagination]: (draft, { payload }) => {
      const allLoaded = payload.loaded >= payload.totalCount;
      draft.pagination = { ...draft.pagination, ...payload, allLoaded };
    },
    [actions.setRespondentsSearchValue]: (draft, { payload: { searchValue } }) => {
      draft.search = searchValue;
    },
    [actions.setRespondentsFilterValue]: (draft, { payload: { fieldName, value } }) => {
      draft.filter.inputs = draft.filter.inputs.map((item) => (item.name === fieldName ? { ...item, value } : item));
      draft.filter.dirty = true;
    },
    [actions.setRespondentsFilterType]: (draft, { payload: { fieldName, filterType } }) => {
      const initValue = fieldName.toLowerCase().includes('date')
        ? datesFilterOptions.find((item) => item.name === DATE_FILTER_TYPES.THREE_DAYS)
        : textFilterOptions.find((item) => item.name === TEXT_FILTER_TYPES.CONTAINS);
      draft.filter.inputs = draft.filter.inputs.map((item) =>
        item.name === fieldName ? { ...item, filterType: filterType === 'initial' ? initValue : filterType } : item,
      );
      draft.filter.dirty = true;
    },
    [actions.applyRespondentsFilters]: (draft) => {
      draft.filter.dirty = false;
      draft.filter.isApplied = true;
      draft.filter.appliedInputs = draft.filter.inputs;
    },
    [actions.clearRespondentsFilters]: (draft) => {
      draft.filter.dirty = true;
      draft.filter.inputs = initialInputsState;
    },
    [actions.deleteRespondentsAppliedFilter]: (draft, { payload: { fieldName } }) => {
      draft.filter.appliedInputs = draft.filter.appliedInputs.map((input) =>
        input.name === fieldName ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
      draft.filter.inputs = draft.filter.inputs.map((input) =>
        input.name === fieldName ? { ...input, value: input.type === 'date' ? null : '' } : input,
      );
    },
    [actions.resetRespondentsTableState]: (draft) => {
      draft.search = '';
      draft.filter = { ...initialFilters };
      draft.shouldResetTableState = true;
      draft.selectedRespondents = [];
    },
    // END OF FILTERS, EVERYTHING ELSE
    [actions.fetchRespondentsSuccess]: (draft, { payload: { data, tableParams, shouldReset } }) => {
      draft.respondents = shouldReset ? data.respondents : [...draft.respondents, ...data.respondents];
      draft.pagesAvailable = data.pagesAvailable;
      draft.totalCount = data.totalCount;
      draft.lastPageIndex = data.pageNumber;
      draft.lastTableParams = tableParams;
    },
    [actions.setRespondentToEdit]: (draft, { payload: { data } }) => {
      draft.respondentToEdit = data;
    },
    [actions.fetchRespondentInvitesSuccess]: (draft, { payload: { invites } }) => {
      draft.respondentInvites = invites;
    },
    [actions.addRespondentSuccess]: (draft) => {
      draft.shouldResetTableState = true;
      draft.search = '';
      draft.filter = { ...initialFilters };
    },
    [actions.changeShouldResetRespondentsTableBoolState]: (draft, { payload: { state } }) => {
      draft.shouldResetTableState = state;
    },
    [actions.deleteRespondentsSuccess]: (draft, { payload: { respondentsIds } }) => {
      draft.respondents = draft.respondents.filter((resp) => !respondentsIds.includes(resp.respondentID));
    },
    [actions.editRespondentSuccess]: (draft, { payload: { values } }) => {
      draft.respondentToEdit = { ...draft.respondentToEdit, ...values };
    },
    [reportActions.markReportsAsViewedSuccess]: (draft, { payload: { reportsIds } }) => {
      if (draft.respondentToEdit) {
        draft.respondentToEdit.reports.forEach((report) => {
          if (reportsIds.includes(report.notificationID)) report.reportType = 3;
        });
      }
    },
    [actions.fetchAvailableReportsSuccess]: (draft, { payload: { reportSets, reports } }) => {
      const sets = reportSets.map((set) => ({
        ...set,
        type: 'set',
        isSolutionReportSet: Boolean(set.isSolutionSet),
        reports: set.reportIDArray
          .map((reportId) => reports.find((item) => item.reportID === Number(reportId)))
          .filter((report) => report),
      }));
      draft.availableReports = [...sets];
    },
    [actions.clearAvailableReports]: (draft) => {
      draft.availableReports = [];
    },
    [actions.fetchAssessmentsNormsSuccess]: (draft, { payload: { norms } }) => {
      draft.availableNorms = norms;
    },
    // we need to unselect others when we trigger single row actions dropdown, otherwise our selectedRespondents array (contains only one item at a time when clicking on actions dropdown) won't much respondents with isSelected flag (if we won't unselect - they would add up infinitely)
    [actions.setSelectedRespondents]: (draft, { payload: { respondents } }) => {
      draft.selectedRespondents = respondents;
      // draft.respondents = draft.respondents.map((resp) => {
      //   const isSelected = Boolean(respondents.find((item) => item.respondentID === resp.respondentID));
      //   const previousSelectedState = shouldUnselectOthers ? false : resp.isSelected;
      //   return { ...resp, isSelected: isSelected || previousSelectedState };
      // });
    },
    [actions.onTestComplete]: (draft, { payload: { id, test } }) => {
      draft.respondents = draft.respondents.map((respondent) => {
        if (respondent.id === id) return { ...respondent, tests: [...respondent.tests, test] };
        return respondent;
      });
    },
    [actions.deleteTagsSuccess]: (draft, { payload: { tags } }) => {
      draft.respondentToEdit.tags = draft.respondentToEdit.tags.filter(
        (tag) => !tags.find((item) => item.tagID === tag.tagID),
      );
    },
    [actions.getRespondentsCountSuccess]: (draft, { payload: { data } }) => {
      draft.allRespondentsCount = data;
    },
    [actions.fetchAnswerSheetTestsInfoSuccess]: (draft, { payload: { data } }) => {
      draft.answerSheet = { ...data };
    },
    [actions.updateRespondentRow]: (draft, { payload: { respondent } }) => {
      const respondents = draft?.respondents;

      if (respondents) {
        const foundRespondent = respondents.find(
          (stateRespondent) => stateRespondent.respondentID === respondent.respondentID,
        );

        if (foundRespondent) {
          Object.entries(respondent).forEach(([property, value]) => {
            if (property !== 'respondentID') {
              foundRespondent[property] = value;
            }
          });
        }
      }
    },
  },
  initialState,
);

export default respondentsReducer;
