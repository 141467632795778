import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { object, string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { I18n, Translate } from 'react-redux-i18n';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { dateValueFormatter, dateWithoutTimeComparator, currentLocaleText } from '../../lib/ag-grid';
import {
  setSelectedRespondents,
  deleteRespondents,
  fetchRespondents,
  fetchRespondentsForGroup,
  removeRespondentsFromGroup,
  changeShouldResetRespondentsTableBoolState,
  resetRespondentsTableState,
} from '../../store/respondents/actions';
import { initPaginationState } from '../../store/respondents/reducer';
import { setActiveGroup, fetchGroupsById, fetchAllGroups } from '../../store/groups/actions';
import ConfirmationModal from '../reusable/ConfirmationModal';
import PageWrapper from '../PageWrapper';
import Invite from './CreateInvite';
import AnswerSheetModal from './AnswerSheet';
import AdministerAssessmentModal from './AdministerAssessment';
import RequestReportModal from './RequestReportModal';
import ManageTagsModal from './ManageTagsModal';
import AddToGroup from './AddToGroup';
import AddRespondentModal from './AddRespondentModal';
import ImportRespondents from './ImportRespondents';
import GroupHeader from '../Groups/GroupHeader';
import useModals from '../../hooks/useModals';
import respondentsBulkActions from '../../table/bulkActions/respondents';
import respondentsTableActions from '../../table/tableActions/respondents';
import BulkActionsSidebar from '../BulkActionsSidebar';
import TableTabs from '../reusable/TableTabs';
import * as actions from '../../table/actionsList';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import AddNewGroup from '../Groups/AddNewGroup';
import Avatar from '../../components/reusable/Avatar';
import isChineseEnv from '../../utils/isChineseEnv';
import Button from '../reusable/Buttons/Button';
import DateSelect from '../../components/reusable/FormComponents/DateSelect';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const Respondents = ({ location, groupId }) => {
  const user = useSelector((state) => state.user.user);
  const respondents = useSelector((state) => state.respondents.respondents);
  const selectedRespondents = useSelector((state) => state.respondents.selectedRespondents);
  const totalCount = useSelector((state) => state.respondents.totalCount);
  const shouldResetTableState = useSelector((state) => state.respondents.shouldResetTableState);
  const activeGroupInView = useSelector((state) => state.groups.activeGroupInView);
  const groupsTotalCount = useSelector((state) => state.groups.allGroups);
  const pagination = useSelector((state) => state.respondents.pagination);

  const gridRef = useRef();

  const [isLoading, setLoadingStatus] = useState(true);
  const [shouldResetPage, setShouldReset] = useState(false);

  // Bulk actions related
  const showBulkActionsRow =
    respondents.length !== 0 &&
    selectedRespondents.length !== totalCount &&
    respondents.length === selectedRespondents.length;
  const [isBulkFlowActive, setBulkFlowStatus] = useState(false);

  useEffect(() => {
    if (!showBulkActionsRow) setBulkFlowStatus(false);
  }, [showBulkActionsRow]);

  const dispatch = useDispatch();
  const [renderKey, setRenderKey] = useState(Math.round(Math.random() * 1000));

  useEffect(() => {
    if (shouldResetTableState) {
      setRenderKey(Math.round(Math.random() * 1000));
      dispatch(changeShouldResetRespondentsTableBoolState(false));
    }
  }, [shouldResetTableState]);

  useEffect(() => {
    if (shouldResetPage) {
      setRenderKey(Math.round(Math.random() * 1000));
      setShouldReset(false);
    }
  }, [shouldResetPage]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    return () => dispatch(resetRespondentsTableState());
  }, [location.pathname]);

  // ACTIVE GROUP DATA MANAGEMENT PART
  // component is also used when we visit /groups/${groupId} route to edit/view group
  useEffect(() => {
    if (groupId) dispatch(fetchGroupsById(groupId));
  }, [groupId]);

  // clear active group on onmount if it's present
  useEffect(() => {
    return () => {
      if (activeGroupInView) dispatch(setActiveGroup(null));
    };
  }, [groupId]);

  useEffect(() => {
    dispatch(fetchAllGroups());
  }, []);

  // we need data from different endpoints depending on the route; if we are on /respondents - fetch usual list of all respondents, but if we are on /groups/groupId - we need only to fetch respondents that are part of the group
  const fetchData = useCallback(
    ({ tableData, shouldReset, showSpinner, tableCallback, initPagination }) => {
      if (showSpinner) setLoadingStatus(true);
      const func = groupId ? fetchRespondentsForGroup : fetchRespondents;
      const args = [
        tableData,
        {
          callback: () => {
            setLoadingStatus(false);
            tableCallback && tableCallback();
          },
          shouldReset,
          initPagination,
        },
      ];
      if (groupId) args.unshift(groupId);
      dispatch(func(...args));
    },
    [groupId],
  );

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedRespondents });

  const modals = [
    actions.REQUEST_REPORT,
    actions.ANSWER_SHEET,
    actions.ADMINISTER_ASSESSMENT,
    actions.CREATE_INVITE,
    actions.MANAGE_TAGS,
    actions.ADD_TO_GROUP,
    actions.ADD_RESPONDENT,
    actions.DELETE_RESPONDENT,
    actions.CREATE_GROUP_FROM_SELECTION,
    actions.IMPORT_RESPONDENTS,
    actions.REMOVE_RESPONDENTS_FROM_GROUP,
  ];

  const { modalsState, openModal, closeModal } = useModals(modals);

  const onRespondentDelete = () => {
    dispatch(
      deleteRespondents(
        selectedRespondents.map((item) => item.respondentID),
        () => {
          closeModal(actions.DELETE_RESPONDENT);
          setShouldReset(true);
        },
      ),
    );
  };

  const omRemoveFromGroup = () => {
    dispatch(
      removeRespondentsFromGroup(
        groupId,
        selectedRespondents.map((item) => item.respondentID),
        () => {
          closeModal(actions.REMOVE_RESPONDENTS_FROM_GROUP);
          setShouldReset(true);
        },
      ),
    );
  };

  // needed for table row rendering optimization, attempt to prevent unnecessary rerenders
  const rowEqualityFunc = useCallback(
    (prevItem, nextItem) =>
      prevItem.tags.length === nextItem.tags.length &&
      prevItem.assessmentsDone.length === nextItem.assessmentsDone.length &&
      prevItem.groups.length === nextItem.groups.length &&
      prevItem.firstName === nextItem.firstName &&
      prevItem.familyName === nextItem.familyName &&
      prevItem.email === nextItem.email,
    [],
  );

  // table actions
  const tableHandlers = {
    [actions.ADD_RESPONDENT]: () => openModal(actions.ADD_RESPONDENT),
    [actions.IMPORT_RESPONDENTS]: () => openModal(actions.IMPORT_RESPONDENTS),
  };
  const tableActions = useMemo(() => {
    return respondentsTableActions.map((item) => ({ ...item, handler: tableHandlers[item.name] }));
  }, []);

  // bulk and row actions
  const navigateToEdit = (respondent) => {
    dispatch(push(`/respondents/${respondent.respondentID}`));
    dispatch(setSelectedRespondents([]));
  };
  const bulkActions = useMemo(() => {
    return respondentsBulkActions(Boolean(groupId), user).map((item) => ({
      ...item,
      handler: () =>
        item.name === actions.EDIT_RESPONDENT ? navigateToEdit(selectedRespondents[0]) : openModal(item.name),
      isDisabled:
        typeof item.isDisabled === 'function'
          ? item.isDisabled(selectedRespondents, isBulkFlowActive)
          : item.isDisabled,
    }));
  }, [selectedRespondents, isBulkFlowActive, user]);

  const tabs = useMemo(() => {
    return [
      { name: 'respondents', label: `${I18n.t('List')} (${pagination.loaded})` },
      { name: 'groups', label: `${I18n.t('Groups')} (${groupsTotalCount.length})` },
    ];
  }, [pagination.loaded, groupsTotalCount.length]);

  const onTabClick = (tab) => {
    if (tab.name === 'groups') dispatch(push('/groups'));
  };

  const groupTitle = activeGroupInView ? activeGroupInView.description : '';
  const pageTitle = groupId ? groupTitle : <Translate value="Respondents" />;
  const onBackButtonClick = () => dispatch(push('/groups'));

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    cacheQuickFilter: true,
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      dispatch(setSelectedRespondents(selectedData));
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      headerName: I18n.t('PersonName'),
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 2,
      valueGetter: (params) => {
        const fullName = isChineseEnv
          ? `${params.data.familyName}${params.data.firstName}`
          : `${params.data.firstName} ${params.data.familyName}`;
        return fullName;
      },
      cellRenderer: ({ data }) => {
        return (
          <RespondentsCell style={{ display: 'flex', alignItems: 'center' }}>
            {!isChineseEnv && <Avatar firstName={data.firstName} familyName={data.familyName} />}
            <span>{isChineseEnv ? `${data.familyName}${data.firstName}` : `${data.firstName} ${data.familyName}`}</span>
          </RespondentsCell>
        );
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'email',
      headerName: I18n.t('Email Address'),
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'groups',
      headerName: I18n.t('Groups'),
      filter: 'agSetColumnFilter',
      flex: 2,
      valueGetter: ({ data }) => data.groups.map((group) => group.description),
      cellRenderer: ({ data }) => {
        return <LabelsWrapper compact>{data.groups.map((item) => item.description).join(', ')}</LabelsWrapper>;
      },
      filterParams: {
        values: (params) => {
          const allValues = [];
          params.api.forEachNode((node) => {
            const items = node.data?.groups?.map((item) => item.description) || [];
            if (Array.isArray(items)) {
              allValues.push(...items);
            }
          });
          const uniqueValues = Array.from(new Set(allValues));
          params.success(uniqueValues);
        },
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'tags',
      headerName: I18n.t('Tags'),
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 2,
      valueGetter: ({ data }) => data.tags.map((item) => item.name),
      cellRenderer: ({ data }) => {
        return <LabelsWrapper compact>{data.tags.map((item) => item.name).join(', ')}</LabelsWrapper>;
      },
      filterParams: {
        values: (params) => {
          const allValues = [];
          params.api.forEachNode((node) => {
            const items = node.data?.tags?.map((item) => item.name) || [];
            if (Array.isArray(items)) {
              allValues.push(...items);
            }
          });
          const uniqueValues = Array.from(new Set(allValues));
          params.success(uniqueValues);
        },
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'assessmentsDone',
      headerName: I18n.t('Assessments Done'),
      filter: 'agSetColumnFilter',
      flex: 2,
      valueGetter: ({ data }) => data.assessmentsDone || [],
      cellRenderer: ({ data }) => {
        if (!data.assessmentsDone) return '';
        return <LabelsWrapper>{data.assessmentsDone.join(', ')}</LabelsWrapper>;
      },
      filterParams: {
        values: (params) => {
          const allValues = [];
          params.api.forEachNode((node) => {
            const items = node.data?.assessmentsDone || [];
            if (Array.isArray(items)) {
              allValues.push(...items);
            }
          });
          const uniqueValues = Array.from(new Set(allValues));
          params.success(uniqueValues);
        },
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'creationDate',
      headerName: I18n.t('Creation Date'),
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      sort: 'desc',
      valueGetter: (params) => {
        return new Date(params.data.creationDate);
      },
      valueFormatter: dateValueFormatter,
      filterParams: {
        maxNumConditions: 1,
        comparator: dateWithoutTimeComparator,
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  const handleRowClick = (event) => {
    if (
      event.event?.target?.closest('[col-id="ag-Grid-ControlsColumn"]')?.getAttribute('col-id') !==
      'ag-Grid-ControlsColumn'
    )
      navigateToEdit(event.data);
  };

  useEffect(() => {
    fetchData({
      shouldReset: true,
      showSpinner: true,
      initPagination: initPaginationState,
    });
  }, []);

  useEffect(() => {
    if (!isLoading && gridRef.current) {
      setLoadingStatus(true);
      fetchData({
        shouldReset: true,
        showSpinner: true,
        initPagination: initPaginationState,
        tableCallback: () => {
          if (gridRef.current?.api) {
            gridRef.current.api.setGridOption('rowData', respondents);
            gridRef.current.api.refreshCells();
          }
        },
      });
    }
  }, [renderKey]);

  return (
    <PageWrapper
      title={pageTitle}
      buttons={tableActions}
      backButtonHandler={groupId ? onBackButtonClick : undefined}
      style={{ overflowY: 'auto' }}
    >
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      {!groupId && <TableTabs tabs={tabs} activeTab={tabs[0]} clickHandler={onTabClick} />}
      {groupId && activeGroupInView && <GroupHeader group={activeGroupInView} />}
      {showBulkActionsRow && (
        <BulkActionsRow>
          {/*  TODO translations? */}
          {isBulkFlowActive ? (
            <>
              <span>{I18n.t('All respondents are selected', { respondentsCount: totalCount })}.</span>
              <BulkActionsLink onClick={() => setBulkFlowStatus(false)}>Clear selection</BulkActionsLink>
            </>
          ) : (
            <>
              <span>
                {I18n.t('All respondents on this page are selected', { respondentsCount: selectedRespondents.length })}.
              </span>
              <BulkActionsLink onClick={() => setBulkFlowStatus(true)}>
                {I18n.t('Select all respondents in this result', { respondentsCount: totalCount })}.
              </BulkActionsLink>
            </>
          )}
        </BulkActionsRow>
      )}
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          ref={gridRef}
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : respondents}
          rowSelection={rowSelection}
          onRowClicked={handleRowClick}
          onFilterChanged={handleFilterChanged}
          loading={isLoading ? true : false}
          localeText={currentLocaleText}
        />
      </div>
      {pagination.loaded && !pagination.allLoaded && !isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button size="s" onClick={() => fetchData({ shouldReset: false, showSpinner: true })}>
            Load More
          </Button>
        </div>
      ) : (
        ''
      )}
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_RESPONDENT]}
        title={I18n.t('Delete respondent')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedRespondents.length > 1 ? I18n.t('selected respondents') : I18n.t('this respondent')
        }?`}
        onClose={() => closeModal(actions.DELETE_RESPONDENT)}
        onConfirm={onRespondentDelete}
      />
      <ConfirmationModal
        isVisible={modalsState[actions.REMOVE_RESPONDENTS_FROM_GROUP]}
        title={I18n.t('Delete respondents from group')}
        description={`${I18n.t('Are you sure you want to remove')} ${
          selectedRespondents.length > 1 ? I18n.t('selected respondents') : I18n.t('this respondent')
        } ${I18n.t('from the group?')}`}
        onClose={() => closeModal(actions.REMOVE_RESPONDENTS_FROM_GROUP)}
        onConfirm={omRemoveFromGroup}
      />
      {modalsState[actions.CREATE_INVITE] && (
        <Invite onClose={() => closeModal(actions.CREATE_INVITE)} respondents={selectedRespondents} />
      )}
      {modalsState[actions.ANSWER_SHEET] && (
        <AnswerSheetModal
          onClose={() => closeModal(actions.ANSWER_SHEET)}
          respondents={selectedRespondents}
          onSuccess={() => setShouldReset(true)}
        />
      )}
      {modalsState[actions.ADMINISTER_ASSESSMENT] && (
        <AdministerAssessmentModal
          onClose={() => closeModal(actions.ADMINISTER_ASSESSMENT)}
          respondents={selectedRespondents}
        />
      )}
      {modalsState[actions.REQUEST_REPORT] && (
        <RequestReportModal onClose={() => closeModal(actions.REQUEST_REPORT)} respondents={selectedRespondents} />
      )}

      {modalsState[actions.MANAGE_TAGS] && (
        <ManageTagsModal
          onClose={() => closeModal(actions.MANAGE_TAGS)}
          respondents={selectedRespondents}
          onSuccess={() => setShouldReset(true)}
        />
      )}
      {modalsState[actions.ADD_TO_GROUP] && (
        <AddToGroup onClose={() => closeModal(actions.ADD_TO_GROUP)} onSuccess={() => setShouldReset(true)} />
      )}
      {modalsState[actions.ADD_RESPONDENT] && (
        <AddRespondentModal onClose={() => closeModal(actions.ADD_RESPONDENT)} groupId={groupId} />
      )}
      {modalsState[actions.CREATE_GROUP_FROM_SELECTION] && (
        <AddNewGroup
          onClose={() => closeModal(actions.CREATE_GROUP_FROM_SELECTION)}
          selectedRespondents={selectedRespondents}
          isBulkFlowActive={isBulkFlowActive}
        />
      )}
      {modalsState[actions.IMPORT_RESPONDENTS] && (
        <ImportRespondents
          onClose={() => closeModal(actions.IMPORT_RESPONDENTS)}
          onSuccess={() => setShouldReset(true)}
          groupId={groupId}
        />
      )}
    </PageWrapper>
  );
};

Respondents.propTypes = {
  location: object,
  groupId: string,
};

Respondents.defaultProps = {
  location: {},
  groupId: '',
};

const BulkActionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem 0;
  background-color: ${(props) => props.theme.colors.grey1};
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;

  span {
    color: #4f4f4f;
    margin-right: 0.5rem;
  }
`;

const BulkActionsLink = styled.a`
  color: ${(props) => props.theme.colors.mediumBlue};
  text-decoration: none;

  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.darkBlue};
  }
`;

const RespondentsCell = styled.div`
  min-width: 24rem;
`;

const LabelsWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding: 1rem 0;
  min-width: 50px;
  // max-width: ${(props) => (props.compact ? '12rem' : '20rem')};
`;

export default Respondents;
