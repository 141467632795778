import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';
import styled from 'styled-components';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { dateValueFormatter, dateWithoutTimeComparator, currentLocaleText } from '../../../lib/ag-grid';
import Table from '../../reusable/Tables/Table';
import useModals from '../../../hooks/useModals';
import * as actions from '../../../table/actionsList';
import BioDataTemplateModal from './BioDataTemplateModal';
import accountTabs from './accountTabs';
import PageWrapper from '../../PageWrapper';
import TableTabs from '../../reusable/TableTabs';
import Spinner from '../../reusable/Spinner';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import biodataTemplatesBulkActions from '../../../table/bulkActions/biodateTemplates';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import { deleteBiodataTemplate, updateDefaultBiodataTemplate, fetchUser } from '../../../store/user/actions';
import {
  ethnicityOptions,
  educationOptions,
  occupationOptions,
  sectorOptions,
  industryOptions,
  languageOptions,
} from '../../../constants/respondentSelectOptions';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const CUSTOM_USER_OPTIONS = [
  {
    label: I18n.t('Add Bio Data template'),
    name: actions.CHANGE_BIODATA_OPTIONS,
  },
];

const RespondentBioDataConfiguration = () => {
  const dispatch = useDispatch();

  const [renderKey, setRenderKey] = useState(Math.round(Math.random() * 1000));

  const availableBiodataTemplates = useSelector((state) => state.user.biotemplates);
  const user = useSelector((state) => state.user);

  const [bioTemplateData, setBioTemplateData] = useState({});
  const [bioModalOption, setBioModalOption] = useState('add');
  const [shouldResetPage, setShouldResetPageStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBiodataTemplates, setSelectedBiodataTemplates] = useState([]);

  const allOccupationOptions = occupationOptions.reduce((acc, item) => {
    return [...acc, ...item.options];
  }, []);

  useEffect(() => {
    dispatch(
      fetchUser(() => {
        setRenderKey(Math.round(Math.random() * 1000));
        setIsLoading(false);
      }),
    );

    return () => {};
  }, [shouldResetPage]);

  const goBackToSettings = () => dispatch(push('/settings'));
  const onPageTabClick = (tab) => dispatch(push(tab.path));

  const defaultBiodataTemplate = {
    name: '',
    isDefault: false,
    bioOptions: [
      {
        label: 'Ethnicity',
        selector: 'ethnicity',
        values: ethnicityOptions.map((option) => option.value),
      },
      {
        label: 'Education',
        selector: 'education',
        values: educationOptions.map((option) => option.value),
      },
      {
        label: 'Job Area',
        selector: 'jobArea',
        values: allOccupationOptions.map((option) => option.value),
      },
      {
        label: 'Industry',
        selector: 'industry',
        values: industryOptions.map((option) => option.value),
      },
      {
        label: 'Language',
        selector: 'firstLanguage',
        values: languageOptions.map((option) => option.value),
      },
      {
        label: 'Sector',
        selector: 'sector',
        values: sectorOptions.map((option) => option.value),
      },
    ],
    description: '',
    creation: '',
    userID: 123,
  };

  const modals = [actions.CHANGE_BIODATA_OPTIONS, actions.DELETE_BIODATA_TEMPLATE, actions.UPDATE_DEFAULT_BIO_TEMPLATE];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedBiodataTemplates });

  const tableHandlers = {
    [actions.CHANGE_BIODATA_OPTIONS]: () => {
      setBioTemplateData(defaultBiodataTemplate);
      openModal(actions.CHANGE_BIODATA_OPTIONS);
    },
  };
  const tableActions = useMemo(() => {
    return CUSTOM_USER_OPTIONS.map((item) => ({ ...item, handler: tableHandlers[item.name] }));
  }, []);

  const bulkActionsHandlers = {
    [actions.UPDATE_DEFAULT_BIO_TEMPLATE]: () => openModal(actions.UPDATE_DEFAULT_BIO_TEMPLATE),
    [actions.CHANGE_BIODATA_OPTIONS]: () => openModal(actions.CHANGE_BIODATA_OPTIONS),
    [actions.DELETE_BIODATA_TEMPLATE]: () => openModal(actions.DELETE_BIODATA_TEMPLATE),
  };

  const bulkActions = useMemo(() => {
    return biodataTemplatesBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedBiodataTemplates) : item.isDisabled,
    }));
  }, [selectedBiodataTemplates]);

  const showBioTemplate = (template) => {
    setBioModalOption('edit');
    const { id } = template;
    setBioTemplateData(availableBiodataTemplates.find((item) => item.id === id));
    openModal(actions.CHANGE_BIODATA_OPTIONS);
  };

  const onBiodataTemplateDelete = () => {
    const templateId = selectedBiodataTemplates.map((item) => item.id);
    const bodyParams = {
      userId: user.user.userID,
      biodataFieldConfigurationId: templateId[0],
      testId: '',
      batteryId: '',
    };
    dispatch(
      deleteBiodataTemplate(bodyParams, () => {
        closeModal(actions.DELETE_BIODATA_TEMPLATE);
        setShouldResetPageStatus(true);
        closeSidebar();
      }),
    );
  };

  const onDefaultBiodataTemplateUpdate = () => {
    const selectedTemplate = selectedBiodataTemplates[0];
    const data = {
      userId: selectedTemplate.userId,
      templateId: selectedTemplate.id,
    };
    dispatch(
      updateDefaultBiodataTemplate(data, () => {
        closeModal(actions.UPDATE_DEFAULT_BIO_TEMPLATE);
        setShouldResetPageStatus(true);
        closeSidebar();
      }),
    );
    dispatch(fetchUser(), setIsLoading(true));
  };

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onRowSelected: (event) => {
      const found = selectedBiodataTemplates.findIndex((entry) => entry === event.node.data);
      if (event.node.selected) {
        if (found === -1) setSelectedBiodataTemplates(selectedBiodataTemplates.concat(event.node.data));
      } else {
        if (found !== -1)
          setSelectedBiodataTemplates([
            ...selectedBiodataTemplates.slice(0, found),
            ...selectedBiodataTemplates.slice(found + 1),
          ]);
      }
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      field: 'name',
      headerName: I18n.t('Template name'),
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'description',
      headerName: I18n.t('Description'),
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'creation',
      headerName: I18n.t('Creation Date'),
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      valueGetter: (params) => {
        return new Date(params.data.creation);
      },
      valueFormatter: dateValueFormatter,
      filterParams: {
        maxNumConditions: 1,
        comparator: dateWithoutTimeComparator,
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleRowClick = (event) => showBioTemplate(event.data);

  return (
    <PageWrapper
      key={renderKey}
      title={I18n.t('Respondents')}
      backButtonHandler={goBackToSettings}
      buttons={tableActions}
    >
      <Spinner isLoading={isLoading} full text={`${I18n.t('Loading biodata templates')}...`} />
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <TableTabs tabs={accountTabs} activeTab={accountTabs[0]} clickHandler={onPageTabClick} />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={availableBiodataTemplates}
          rowSelection={rowSelection}
          onRowClicked={handleRowClick}
          localeText={currentLocaleText}
        />
      </div>
      {modalsState[actions.CHANGE_BIODATA_OPTIONS] && (
        <BioDataTemplateModal
          isCreation={bioModalOption === 'add'}
          template={bioModalOption === 'add' ? defaultBiodataTemplate : bioTemplateData}
          onClose={() => closeModal(actions.CHANGE_BIODATA_OPTIONS)}
          resetPageStatus={() => setShouldResetPageStatus(true)}
          onSuccess={() => setShouldResetPageStatus(true)}
        />
      )}
      <ConfirmationModal
        isVisible={modalsState[actions.UPDATE_DEFAULT_BIO_TEMPLATE]}
        title={I18n.t('Update default biodata template')}
        description={`${I18n.t('Are you sure you want to set this template as default?')}`}
        onClose={() => closeModal(actions.UPDATE_DEFAULT_BIO_TEMPLATE)}
        onConfirm={() => onDefaultBiodataTemplateUpdate()}
        caution
      />
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_BIODATA_TEMPLATE]}
        title={I18n.t('Delete biodata templates')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedBiodataTemplates.length > 1
            ? I18n.t('the selected biodata templates?')
            : I18n.t('this biodata template?')
        }`}
        onClose={() => closeModal(actions.DELETE_BIODATA_TEMPLATE)}
        onConfirm={() => onBiodataTemplateDelete()}
      />
    </PageWrapper>
  );
};

const StyledTable = styled(Table)`
  tr:hover {
    & > div div {
      background: rgba(241, 241, 241, 0.1);
    }
  }
`;

RespondentBioDataConfiguration.propTypes = {};

export default RespondentBioDataConfiguration;
