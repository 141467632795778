import React, { useState, useMemo, useEffect } from 'react';
import { string, func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import copy from 'copy-to-clipboard';
import { I18n } from 'react-redux-i18n';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { dateValueFormatter, dateWithoutTimeComparator, currentLocaleText } from '../../lib/ag-grid';
import ConfirmationModal from '../reusable/ConfirmationModal';
import BulkActionsSidebar from '../BulkActionsSidebar';
import Checkbox from '../reusable/FormComponents/Checkbox';
import invitesBulkActions from '../../table/bulkActions/invites';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import * as actions from '../../table/actionsList';
import { createCopiedNotification } from '../Invites/Invites';
import { fetchRespondentInvites } from '../../store/respondents/actions';
import { updateExpiryAssessmentInvites } from '../../store/invites/actions';
import useModals from '../../hooks/useModals';
import ResetExpiryDate from '../Invites/ResetExpiryDate';
import inviteStatuses from '../../constants/inviteStatuses';
import inviteTypes from '../../constants/inviteTypes';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const RespondentInvites = ({ deleteInvites, respondentId, fetchRespondent }) => {
  const user = useSelector((state) => state.user.user);
  const invites = useSelector((state) => state.respondents.respondentInvites);

  const dispatch = useDispatch();

  const [expiryDate, setExpiryDate] = useState(new Date());
  const [showExpired, setShowExpiredState] = useState(false);
  const toggleExpired = () => setShowExpiredState((prev) => !prev);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    dispatch(
      fetchRespondentInvites(respondentId, showExpired, () => {
        setLoading(false);
      }),
    );
  }, [showExpired]);

  const [selectedInvites, setSelectedInvites] = useState([]);

  const [inviteToDelete, setInviteToDelete] = useState(null);

  const [isConfirmationModalVisible, setConfirmationModalVisibilityStatus] = useState(false);

  const closeConfirmationModal = () => {
    if (inviteToDelete) setInviteToDelete(null);
    setConfirmationModalVisibilityStatus(false);
  };
  const openConfirmationModal = () => setConfirmationModalVisibilityStatus(true);

  const onDeleteConfirm = () => {
    const data = inviteToDelete
      ? [{ inviteID: inviteToDelete.inviteID, inviteType: inviteToDelete.inviteType }]
      : selectedInvites.map((item) => ({ inviteID: item.inviteID, inviteType: item.inviteType }));
    deleteInvites(data, () => {
      closeConfirmationModal();
      fetchRespondent();
    });
  };

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedInvites });

  const modals = [actions.RESET_DEADLINE];
  const { modalsState, openModal, closeModal } = useModals(modals);

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.RESET_DEADLINE]: () => openModal(actions.RESET_DEADLINE),
    [actions.COPY_LINK]: () => {
      copy(selectedInvites[0].downloadLink);
      createCopiedNotification();
    },
    [actions.DELETE_INVITE]: openConfirmationModal,
  };
  const bulkActions = useMemo(() => {
    return invitesBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedInvites) : item.isDisabled,
    }));
  }, [selectedInvites, user]);

  const showTable = isLoading || invites.length;

  const onValueChange = (value) => {
    setExpiryDate(value);
  };

  useEffect(() => {
    setExpiryDate(selectedInvites[0] ? new Date(selectedInvites[0].expiryDate) : new Date());

    return () => {};
  }, [selectedInvites.length]);

  const handleExpiryDateUpdates = (type, invites) => {
    const data = {
      inviteType: type,
      inviteIDs: invites.map((invite) => invite.inviteID),
      expiryDate: expiryDate.toISOString(),
    };
    dispatch(updateExpiryAssessmentInvites(data), closeModal(actions.RESET_DEADLINE));
    setTimeout(() => {
      toggleExpired();
    }, 500);

    setSelectedInvites([]);
  };

  const onExpiryDateUpdates = () => {
    const assessmentInviteType = 0;
    const assessmentInvites = selectedInvites.filter((invitation) => invitation.inviteType === assessmentInviteType);

    if (assessmentInvites.length > 0) handleExpiryDateUpdates(assessmentInviteType, assessmentInvites);
  };

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      dispatch(setSelectedInvites(selectedData));
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      field: 'assessment',
      headerName: I18n.t('Assessment'),
      celldatatype: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'langID',
      headerName: I18n.t('Language'),
      celldatatype: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'inviteType',
      headerName: I18n.t('Type'),
      celldatatype: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      valueGetter: ({ data }) => I18n.t(inviteTypes[data.inviteType]),
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'invitationStatus',
      headerName: I18n.t('Invite status'),
      celldatatype: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      valueGetter: ({ data }) => I18n.t(inviteStatuses[data.invitationStatus]),
      filterParams: {
        closeOnApply: true,
        buttons: ['apply', 'reset'],
      },
    },
    {
      field: 'creationDate',
      headerName: I18n.t('Invitation Date'),
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      sort: 'desc',
      valueGetter: (params) => {
        return new Date(params.data.creationDate);
      },
      valueFormatter: dateValueFormatter,
      filterParams: {
        maxNumConditions: 1,
        comparator: dateWithoutTimeComparator,
        closeOnApply: true,
        buttons: ['apply', 'reset'],
      },
    },
    {
      field: 'expiryDate',
      headerName: I18n.t('Expiry Date'),
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      valueGetter: (params) => {
        return new Date(params.data.expiryDate);
      },
      valueFormatter: dateValueFormatter,
      filterParams: {
        maxNumConditions: 1,
        comparator: dateWithoutTimeComparator,
        closeOnApply: true,
        buttons: ['apply', 'reset'],
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  return (
    <RespondentInvitesContainer alignCenter={Boolean(!showTable)}>
      <ShowExpiredContainer onClick={toggleExpired}>
        <Checkbox isChecked={showExpired} />
        <span>{I18n.t('Show expired')}</span>
      </ShowExpiredContainer>
      <BulkActionsSidebar actions={bulkActions} onClose={closeSidebar} isOpen={isSidebarOpen} />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : invites}
          rowSelection={rowSelection}
          onFilterChanged={handleFilterChanged}
          localeText={currentLocaleText}
        />
      </div>
      <ConfirmationModal
        title={I18n.t('Invite Deletion')}
        onClose={closeConfirmationModal}
        isVisible={isConfirmationModalVisible}
        description={`Are you sure you want to delete ${selectedInvites.length > 0 ? 'selected' : 'chosen'} invite(s)?`} // TODO discuss with Cloud
        onConfirm={onDeleteConfirm}
        caution
      />
      {modalsState[actions.RESET_DEADLINE] && (
        <ResetExpiryDate
          onClose={() => closeModal(actions.RESET_DEADLINE)}
          onConfirm={onExpiryDateUpdates}
          expiryDate={expiryDate}
          onValueChange={onValueChange}
        />
      )}
    </RespondentInvitesContainer>
  );
};

RespondentInvites.propTypes = {
  respondentId: string.isRequired,
  deleteInvites: func.isRequired,
  fetchRespondent: func.isRequired,
};

const RespondentInvitesContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-bottom: 8rem;
  margin-top: 10px;

  ${(props) =>
    props.alignCenter &&
    css`
      justify-content: center;
      align-items: center;
    `}
`;

const ShowExpiredContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: -5.2rem;
  right: 0;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;

  span {
    font-size: ${(props) => props.theme.fontSizes.small};
    font-weight: 500;
    margin-left: 0.5rem;
  }
`;

export default RespondentInvites;
