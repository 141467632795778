import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { currentLocaleText } from '../../lib/ag-grid';
import ConfirmationModal from '../reusable/ConfirmationModal';
import competencyGroupsBulkActions from '../../table/bulkActions/competencyGroups';
import BulkActionsSidebar from '../BulkActionsSidebar';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import PageWrapper from '../PageWrapper';
import {
  fetchCompetencyGroupsAction,
  restCompetencyGroups,
  deleteAssessments,
  duplicateCompetency,
} from '../../store/settings/competency-groups/actions';
import competencyGroupsSelectors from '../../store/settings/competency-groups/selectors';
import SettingsTabs from './components/SettingsTabs/SettingsTabs';
import customGroupsTableActions from '../../table/tableActions/customGroups';
import AddCompetencyGroup from '../AddCompetencyGroup/AddCompetencyGroup';
import * as actions from '../../table/actionsList';

import useModals from '../../hooks/useModals';
import createToastNotification from '../../utils/createToastNotification';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const CompetencyGroups = () => {
  const dispatch = useDispatch();

  const [isModal, setIsModal] = useState(false);
  const [selected, setSelected] = useState([]);

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selected });

  const modals = [actions.DELETE_COMPETENCY_GROUPS, actions.DUPLICATE_COMPETENCY];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const groups = useSelector(competencyGroupsSelectors.selectGroups);
  const isLoading = useSelector(competencyGroupsSelectors.selectIsLoading);

  useEffect(() => {
    dispatch(fetchCompetencyGroupsAction());

    return () => {
      dispatch(restCompetencyGroups());
    };
  }, []);

  const memoizedNavigateToGroupDetails = useCallback((data) => {
    dispatch(push(`/settings/360-assessments/${data.competencyGroupID}`));
  }, []);

  const tableActions = useMemo(() => {
    return customGroupsTableActions.map((item) => ({
      ...item,
      label: I18n.t(item.label),
      handler: () => setIsModal(true),
    }));
  }, []);

  const bulkActionsHandlers = {
    [actions.DUPLICATE_COMPETENCY]: () => openModal(actions.DUPLICATE_COMPETENCY),
    [actions.DELETE_COMPETENCY_GROUPS]: () => {
      const activeAssessments = selected.filter((item) => item.projectsCount > 0);
      if (selected.length === activeAssessments.length) {
        createToastNotification({
          title: I18n.t('Deletion blocked'),
          type: 'error',
          message: I18n.t('surveyDeletionCancelToast'),
        });
        return;
      }
      openModal(actions.DELETE_COMPETENCY_GROUPS);
    },
  };

  const bulkActions = useMemo(() => {
    return competencyGroupsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selected) : item.isDisabled,
    }));
  }, [selected]);

  const onAssessmentsDelete = () => {
    const inactiveAssessments = selected.filter((item) => item.projectsCount === 0);
    dispatch(
      deleteAssessments(
        inactiveAssessments.map((item) => item.competencyGroupID),
        () => {
          closeModal(actions.DELETE_COMPETENCY_GROUPS);
        },
      ),
    );
  };
  const onAssessmentsDuplicate = () => {
    const newName = `${I18n.t('Copy of')} ${selected[0].name}`;
    dispatch(
      duplicateCompetency(selected[0].competencyGroupID, newName, (err, newCompetencyId) => {
        if (err) closeModal(actions.DUPLICATE_COMPETENCY);
        else memoizedNavigateToGroupDetails({ competencyGroupID: newCompetencyId });
      }),
    );
  };

  const onAddNewGroup = () => {
    dispatch(fetchCompetencyGroupsAction());
    setIsModal(false);
  };

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      setSelected(selectedData);
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      field: 'name',
      headerName: I18n.t('Template name'),
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 2,
      cellRenderer: (params) => {
        const { name, projectsCount } = params.data;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{name}</span>
            {projectsCount > 0 && <LockIcon style={{ marginLeft: '5px', fontSize: '18px', color: 'red' }} />}
          </div>
        );
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'responseScale',
      headerName: I18n.t('Response scale'),
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'categoriesCount',
      headerName: I18n.t('Categories'),
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'competenciesCount',
      headerName: I18n.t('Competencies'),
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'itemsCount',
      headerName: I18n.t('Number of items'),
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'estimatedTime',
      headerName: I18n.t('Estimated time'),
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  const handleRowClick = (event) => {
    if (
      event.event?.target?.closest('[col-id="ag-Grid-ControlsColumn"]')?.getAttribute('col-id') !==
      'ag-Grid-ControlsColumn'
    )
      memoizedNavigateToGroupDetails(event.data);
  };

  return (
    <PageWrapper
      title={I18n.t('360 settings')}
      backButtonHandler={() => dispatch(push(`/settings`))}
      buttons={tableActions}
      style={{ overflowY: 'auto' }}
    >
      <SettingsTabs />
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : groups}
          rowSelection={rowSelection}
          onFilterChanged={handleFilterChanged}
          onRowClicked={handleRowClick}
          localeText={currentLocaleText}
        />
      </div>
      {isModal && <AddCompetencyGroup onClose={onAddNewGroup} onCancel={() => setIsModal(false)} />}

      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_COMPETENCY_GROUPS]}
        title={I18n.t('Delete assessment')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selected.length > 1 ? I18n.t('selected assessments') : I18n.t('this assessment')
        }?`}
        onClose={() => closeModal(actions.DELETE_COMPETENCY_GROUPS)}
        onConfirm={onAssessmentsDelete}
        caution
      />
      <ConfirmationModal
        isVisible={modalsState[actions.DUPLICATE_COMPETENCY]}
        title={I18n.t('Duplicate 360 survey')}
        description={`${I18n.t('Are you sure you want to duplicate')} ${selected[0] ? selected[0].name : ''} ${I18n.t(
          '360 survey',
        )}`}
        onClose={() => closeModal(actions.DUPLICATE_COMPETENCY)}
        onConfirm={onAssessmentsDuplicate}
        caution
      />
    </PageWrapper>
  );
};

export default CompetencyGroups;
