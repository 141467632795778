const translations = {
  About: '关于',
  Launch: '登录',
  Tutorial: '使用指南',
  SignIn: '登录',

  // Login Page (Login.js)
  Email: '电子邮件',
  Submit: '保存',
  LoginNext: '输入用户名并继续',
  LoginSubmit: '继续',
  SwitchUser: '切换用户',
  ForgotPassword: '忘记密码',
  Password: '密码',
  'Email is required': '请输入电子邮件',
  'Password is required': '请输入密码',
  'Should be a proper email address': '必须是一个有效的的电子邮件',

  // (AddCompetencyCategory.js)
  Save: '保存',
  'Enter Name': '输入名称',
  'Add competency category': '添加胜任力类别',
  'Add description for the category': '添加描述',

  // (CompetencyGroupNavbar.js)
  'Main info': '基本信息',

  // (AddCompetencyGroup.js)
  Add: '添加',
  Next: '下一步',
  'Name is required': '名称为必填项',
  'Add 360 assessment': '添加360度评估',
  'Custom competencies': '自定义胜任力',
  'Inbuilt competencies': '标准胜任力',

  // (AddCompetencyItem.js)
  Edit: '编辑',
  'Competency description': '胜任力描述',

  // (AddCompetencyQuestion.js)
  'Add question': '添加问题',

  // (AddCompetencyQuestionForm.js)
  'Item text': '输入问题',
  'Free field': '开放题',
  'Multiple choice': '选择题',
  'Add another language': '添加另一种语言',

  // (AddCreditsFailureModal.js)
  'Credits addition error': '信用点添加失败',
  'Try again': '请再试一次',

  //  (AddCreditsSuccessModal.js)
  Done: '完成',
  'Adding credits': '添加信用点',
  'Credits successfully added!': '信用点添加成功！',
  'Now your credit balance is:': '当前信用点剩余：',

  // (AddCredits.js)
  Cancel: '取消',
  'Add credits': '添加信用点',
  'Your credits balance': '信用点剩余：',
  'How many credits do you want to add?': '您要添加多少信用点？',
  'Purchase ID': '授权码',
  'Error occurred during an attempt to add credits to your account': '添加信用点时出现错误',
  'Credits amount': '数量',

  // Edit Respondent (Index.js)
  Groups: '组',
  Invites: '已发送邀请',
  invites: '已发送邀请',
  Reports: '报告',
  Assessments: '已完成测评',
  IAssessments: '测评',
  SAssessments: '测评工具设置',
  'Respondent Information': '受测者信息',

  // Edit Respondent (AddTagModal.js)
  'Add tags': '添加标签',
  'Select tags': '选择标签',

  // Edit Respondent (Information.js)
  Sex: '性别',
  BioTitle: '职位名称',
  Title: '职位名称',
  Sector: '职级',
  Company: '公司名称',
  Industry: '行业',
  Education: '教育程度',
  Reference: '参考信息',
  Ethnicity: '籍贯',
  'Job area': '职能',
  jobArea: '职能',
  jobarea: '职能',
  'First Name': '姓名（必填）',
  'Family Name': '姓',
  'Family name': '姓',
  'Save changes': '保存更改',
  'Date of Birth': '出生日期',
  'First Language': '母语',
  'Additional Info': '附加信息',
  'Main Information': '主要信息',

  // Edit Respondent (RespondentGroups.js)
  group: '组',
  Group: '组',
  'Remove from groups': '从组中移除受测者',
  'Are you sure you want to remove respondent from selected group(s)': '您确定要从组中移除所选的受测者吗？',

  // Edit Respondent (RespondentInvites.js)
  invite: '测评邀请',
  'Invite Deletion': '删除测评邀请',

  // Edit Respondent (RespondentReports.js)
  'Report deletion': '报告删除',
  'Are you sure you want to delete selected report?': '您确定要删除所选报告吗？',

  // Edit Respondent (RespondentTests.js)
  'Test deletion': '删除测评',
  'Are you sure you want to delete selected test?': '您确定要删除所选测评吗？',

  // Edit Respondent (TagsManagement.js)
  Tags: '标签',
  '+ Add tag': '+ 添加标签',

  // Groups (AddNewGroup.js)
  Create: '创建',
  'Group name': '组名称',
  'Add new group': '添加新组',
  'Group description': '组描述',

  // Groups (GroupHeader.js)
  Type: '类型',
  'Creation date': '创建日期',
  'Members count': '组成员',
  member: '成员',

  // Groups (Groups.js)
  List: '受测者名单',
  Respondents: '受测者',
  respondents: '受测者',
  SRespondents: '受测者个人信息管理',
  'Delete group': '删除组',
  'Are you sure you want to delete this group(s)': '您确定要删除组吗？',

  // Manage active links
  'Manage active links': '管理活动链接',
  'Manage active group links': '管理活动组链接',

  // Groups request report
  'Select common assessment / battery': '请选择测评答案以生成报告',
  reports: '报告列表',

  // Groups generate open group link
  link: '链接',
  Generate: '生成',
  'The link has been successfully generated': '开放组链接已生成',

  // Invites (Invites.js)
  'Delete invite': '删除测评邀请',
  'selected invites?': '所选的测评邀请吗？',
  'this invite?': '这个测评邀请吗？',

  // LaunchScreen (LaunchScreen.js)
  Language: '语言',
  language: '语言',

  // Notifications (Notifications.js)
  Results: '结果',
  notification: '通知',
  Notifications: '通知',

  // Projects (AddProjectsModal.js)
  Min: '最少评估人数',
  Info: '信息',
  Raters: '评估者',
  Deadline: '截止日期',
  'Peer Alias': '同事',
  Description: '描述',
  'Project Name': '项目名称',
  'Project name': '项目名称',
  'All standard': '标准版',
  'Edit Project': '编辑项目',
  'Manager Alias': '上级',
  'Project Alias': '评估者信息',
  'Direct Report Alias': '下属',
  'Choose Project Type': '选择项目类型',
  '360 assessment has to be fully supported in at least 1 language': '需要为360度评估项目的所有问题配置至少同一种语言',
  '360 Survey': '360度评估',
  '360 Surveys': '360度评估模型',
  'Other Alias': '其他人',
  deadlineWarning: '报告生成日期',
  createAssessmentBattery: '如需要选择多个测评工具，请在设置中创建自定义成套测验，然后从类别收藏夹中选取',
  manualInviteDesc: '邀请可以从项目页面上的受测者列表手动发送',
  autoInviteDesc: '每次您向项目添加新受测者时，都会发送邀请',
  scheduledInviteDesc: '邀请将在选定日期发. 如果您在此之后添加新受测者，则在添加邀请时会自动发送',
  daysLeft: '%{days} 剩余天数',

  // Projects (projectList.js)
  'Add Assessment Project': '添加测评项目',
  'Add assessment project': '添加测评项目',
  'Add 360 Appraisal Project': '添加360度评估项目',
  'Manage your assessment projects here Plan and implement the right assessment approach and tests Effectively and consistently administrate, communicate and manage the whole assessment process using this user friendly tool':
    '在这里管理测评项目。选择并部署合适的测评方式和测评工具。通过这个简单易用的功能来高效地管理整个测评过程。',

  'Manage your entire 360 appraisal process here Easy to use, allowing appraisals to be set up in minutes and results generated instantly Choose from Psytech existing competency framework or fully customise your 360 project':
    '在这里管理360度评估项目。通过这个简单易用的管理工具来轻松创建评估项目并生成报告。可以使用系统内嵌的标准胜任力模型，也可以根据需要来自定义胜任力模型。',

  // Projects (Projects.js)
  All: '所有项目',
  Project: '项目',
  Projects: '项目',
  'this project?': '这个项目？',

  '360 Appraisal': '360度评估',
  'Delete project': '删除项目',
  'selected projects?': '所选的项目吗？',
  'Are you sure you want to delete': '确定要删除',

  // Raters (Raters.js)
  rater: '评估者',
  Rater: '评估者',
  'Delete raters': '删除评估者',
  'Loading raters': '加载中……',
  'selected raters?': '所选的评估者？',
  'this rater?': '这个评估者？',
  Session: '项目组',
  'Session for': '360度评估项目组',

  // Raters (AddRatersModal.js)
  Role: '角色',
  other: '其他',
  Other: '其他',
  Peers: '同事',
  Confirm: '确认',
  Managers: '上级',
  'No email': '没有电子邮件信息',
  'Add raters': '添加评估者',
  'Direct reports': '下属',
  'Search existing records': '在受测者列表中搜索',
  'Make sure selected record has a valid email address added': '请确保所选记录已添加有效的电子邮件信息',

  // Raters Invites (columns.js)
  Name: '名称',
  PersonName: '姓名',
  'Expiry Date': '到期日期',
  'Invite status': '邀请状态',
  'Email Address': '电子邮件',
  'Hide expired': '隐藏过期邀请',

  // Raters Invites (Invites.js)
  'chosen invite(s)?': '所选的邀请',
  'selected invite(s)?': '所选的邀请',
  'Loading invites': '加载中……',
  missingEmailError: '受测者的电子邮件无效，请检查。',

  // Raters Reports (Reports.js)
  Pending: '生成中',
  Unread: '未查看',
  Viewed: '已查看',
  Error: '错误：',
  'Support ticket raised': '已提交支持请求信息',
  'Loading reports': '加载中……',

  // Raters Results (Results.js)
  result: '结果',

  // Reports Reports (Reports.js)
  'Are you sure you want to delete selected report(s)?': '您确定要删除所选报告吗？',

  // RequestReport360 (RequestReport360.js)
  'Report Request for': '请求报告',
  'Report Request': '报告请求',
  credits: '信用点数：',
  'Total Credits:': '信用点总数：',
  'Total Credits': '信用点总数',
  Request: '请求',
  Self: '被评估者',

  // Respondents AddRespondentModal (AddRespondentForm.js)
  'Company Name': '公司名称',
  'Job Area': '职能',
  Remove: '从该组移出',

  // Respondents AddRespondentModal (AddRespondentModal.js)
  'Add respondent': '添加受测者',
  'Main Details': '主要信息',
  'Extra Details': '附加信息',
  'Group Membership': '组信息',

  // Respondents AddRespondentModal (GroupsTab.js)
  'Select groups': '选择组',

  // Respondents AdministerAssessment (index.js)
  Start: '开始',
  Administer: '测评：',
  to: '受测者：',
  questionnaires: '问卷',
  Questionnaires: '问卷',
  reasoning: '推理能力测验',
  Reasoning: '推理能力测验',
  solutions: '解决方案',
  Solutions: '解决方案',
  batteries: '成套测验',
  Batteries: '成套测验',
  favourites: '自定义',
  Favourites: '自定义',
  // Respondents (AnswersStep.js)
  'Test:': '测评：',

  // Respondents AnswerSheet (index.js)
  Previous: '上一步',
  'Administer answer sheet for': '答题纸输入：',
  answers: '答题纸',
  Respondent: '受测者',

  // Respondents (AssessmentStep.js)
  'Welcome text': '欢迎辞',
  'Expiry date': '到期日期',

  // Invite (index.js)
  'Invite respondents': '邀请受测者',
  Invite: '邀请：',
  'Create invite and go next': '创建测评邀请并继续',
  Finish: '完成',
  'You are sending an invitation for:': '正在发送测评：',
  in: ' ',
  invitation: '邀请',
  OR: '或',

  // Invite (InvitationStep.js)
  Immediately: '即刻发出',
  Later: '稍后发出',
  'Email address is missing or not valid in respondent record': '受测者记录中缺少电子邮件地址或无效',
  'Copy link': '复制测评链接',
  Copied: '已复制',
  'Copy and share generated link': '复制并分享测评链接',
  'Send invitation link via email to:': '通过电子邮件为受测者发送邀请链接',
  'Email template': '电子邮件模板',
  'When do you want to send an invite?': '想要何时发送测评邀请？',
  'Email invite': '邮件邀请',
  'Email sent': '邮件已发送',

  // RequestReportModal (index.js)
  'Request report': '请求报告',
  'Request report for': '请求报告对象：',
  for: '对象：',
  'Request Reports': '请求报告',
  'Common tests: ': '相同测评',
  'At least 1 assessment must be common': '必须至少有1项相同测评',
  'Selected respondents': '所选受测者',
  tests: '测评：',
  'Assessment/Battery': '测评工具',
  emptyLanguageReports: '抱歉，找不到任何适用于此语言的报告',
  'Total credits:': '所需信用点:',
  'Total credits': '所需信用点',

  // RequestReportModal (ReportsList.js)
  'Available Reports': '可选测评报告：',
  Norm: '常模：',
  Options: '选项：',
  'Ideal Profile': '理想剖析图：',

  // Respondents (AddToGroup.js)
  'Add to group': '添加至组',
  'Add respondent(s) to group': '将受测者添加至组',

  // Respondents (ManageTagsModal.js)
  'Manage respondents tags': '管理受测者标签',
  'Select from existing tags or add new': '从现有标签中选择或添加新标签',
  'Add tag to selected respondent(s)': '添加标签',
  'Remove tag from selected respondent(s)': '删除标签',

  // Respondents  (RespondentDropdown.js)
  'Add new respondent': '添加单个受测者',
  'Import respondents from file': '从文件批量导入受测者',
  'Edit respondent': '编辑受测者',
  'Delete respondent': '删除受测者',

  // Respondents  (Respondents.js)
  'Delete respondents from group': '从组中删除受测者',
  'Loading respondents': '加载中……',
  'Are you sure you want to remove': '确定要将',
  'from the group?': '从当前组中移除吗？',
  'All respondents are selected': '%{respondentsCount} 所有受测者均被选中',
  'Clear selection': '清除选择',
  'All respondents on this page are selected': '%{respondentsCount} 此页面上的所有受测者均被选中',
  'Select all respondents in this result': '%{respondentsCount} 选择此结果中的所有受测者',

  // Reusable Accordion (Accordion.js)
  INBUILT: '标准胜任力',

  // Reusable Accordion (CompetencyGroupsAccordion.js)
  'Collapse all': '收起全部',
  'Expand all': '展开全部',

  // Reusable  Filter (DateInputGroup.js)
  'Date range': '日期范围',

  // Reusable  Filter (Filter.js)
  Filter: '筛选',
  'Clear all': '清除全部',
  Apply: '应用',

  // Reusable Filter (TextInputGroup.js)
  'Filter type': '筛选类型',

  // Reusable Filter (Search.js)
  Search: '搜索',

  // Reusable Badges (CustomBadge.js)
  CUSTOM: '自定义',

  // Reusable TextEditor (Editor.js)
  'Insert variable': '插入变量',

  // Reusable  (ConfirmationModal.js)
  No: '否',
  Yes: '是',

  // Reusable  (DatePicker.js)
  'Select the date': '选择日期',

  // Reusable  (DateTimeSelect.js)
  'Time:': '时间：',
  Time: '时间',

  // Reusable  (ErrorMessage.js)
  'Oops, something went wrong': '载入中......',
  'Please try again': '如长时间未相应，请重新刷新页面',
  Ok: '确认',

  // Reusable (Quantity.js)
  Quantity: '数量',

  // Reusable (StatusCell.js)
  'In Progress': '进行中',
  'In progress': '进行中',
  'On Hold': '暂停',
  Canceled: '已取消',
  Completed: '已完成',
  completed: '已完成',

  // Session SessionInvite (index.js)
  'Send invite to raters': '发送邀请给评估者',
  'Send invite to raters who have not yet completed': '发送邀请给尚未完成的评估者',
  'Send invite to raters who have not yet been invited': '发送邀请给尚未邀请的评估者',
  'Send invite to raters who have already been invited but haven&apos;t yet completed':
    '重新发送邀请给已邀请但尚未完成的评估者',
  'Send invite to self with a request to specify their own raters': '邀请本人自行指定评估者',
  'Welcome text for Self Raters': '为本人指定测验欢迎辞',
  'Email template for Self Raters': '为本人指定电子邮件邀请模板',
  'Welcome text for Other Raters': '为其他角色指定测验欢迎辞',
  'Email template for Other Raters': '为其他角色指定电子邮件邀请模板',
  Send: '发送',

  // Session SessionInvite (SessionInvitationStep.js)
  'Email body': '电子邮件正文',
  'Email subject': '电子邮件主题',
  'Invitation date': '邀请日期',
  'Email body for Self Raters': '给自评者的电子邮件正文',
  'Email body for Other Raters': '给他评者的电子邮件正文',
  'Email subject for Other Raters': '给他评者的电子邮件主题',
  'Email subject for Self Raters': '给自评者的电子邮件主题',

  // Session (AddSessionModal.js)
  'Sorry, session with selected respondent already exists.': '所选的被评估者已在本评估组中。',
  'Add session': '添加评估组',
  'Session SELF': '被评估者',
  'Search existing record to select session SELF': '从受测者列表中选择被评估者',

  // Session (SessionHeader.js)
  'All Standard': '标准版',
  '360 Assessment:': '360度评估',
  'Type:': '类型：',
  'Status:': '状态：',
  'Deadline:': '截止日期：',

  // Session (Sessions.js)
  Sessions: '评估组',
  session: '评估组',
  'Loading sessions': '加载中……',
  'Delete session': '删除评估组',
  'Delete sessions': '删除评估组',
  'this session?': '这个评估组吗？',
  'Are you sure you want to delete this project': '确定要删除这个项目吗？',
  'Sessions list': '项目组列表',

  // Session header (SessionHeader.js)
  'days left': '天剩余',
  'Send invites': '发送邀请',
  'Outstanding ratings': '未完成评估',
  'Send reminder': '发送提醒',
  'Sessions completed': '已完成项目组',
  'Send invite': '发送邀请',

  // Settings AddResponseScale (AddResponseScale.js)
  'Loading...': '加载中……',

  // Settings (AddScaleForm.js)
  'Add response scale': '添加应答选项',
  'Edit response scale': '编辑应答选项',

  // Settings Components   (EditTitle.js)
  'Edit Name': '编辑名称',
  'Edit 360 assessment': '编辑360度评估',

  // Settings Components  (QuestionItem.js)
  Question: '问题：',
  'No translation available for': '',

  // Settings Components  (ReviewCompetencies.js)
  Competencies: '胜任力',
  CONFIRM: '确认',

  // Settings utils (ResponseScale.util.js)
  'Name is the required field': '名称为必填',
  'All response scales should be filled': '所有评分选项为必填',

  // Settings (CustomCompetencies.js)
  'Are you sure you want to remove selected': '确定要移除所选',
  Collapse: '收起列表',
  Expand: '打开列表',
  '360 settings': '360度评估设置',

  // Settings (Settings.js)
  Settings: '设置',

  // (BulkActionsSidebar.js)
  'Bulk actions': '菜单',
  Actions: '菜单',

  // 360 surveys
  'Add new 360 survey': '新建360度评估模型',
  'Add 360 survey': '新建360度评估模型',
  'Edit 360 survey': '编辑360度评估模型',
  'Duplicate 360 survey': '复制360度评估模型',
  'Delete selected 360 survey(s)': '删除360度评估模型',
  Sorting: '排序方式',
  // Sidebar.js
  'Are you sure you want to log out?': '确定要退出登录？',
  'Credits balance:': '信用点余额',

  // Settings  (Settings.const.js)
  'Assessments and batteries': '测评和成套测评',
  'Ideal Profiles': '理想剖析图',
  Norms: '常模',
  '360 Assessments': '360度评估',
  Templates: '模板',
  'Email templates': '电子邮件模板',
  Account: '账户',
  'General info': '基本信息',
  'Credits History': '信用点记录',
  'Reports Usage': '报告生成情况',
  'Assessments Stats': '测评使用情况',
  'Response Scales': '应答选项',
  Tutorials: '在线教程',
  'Watch tutorials': '观看教程',

  // Settings (AssessmentsAndBatteries.js)
  assessment: '测评',
  Assessment: '测评',
  'this battery?': '这个成套测评？',
  'Delete battery': '删除成套测评',
  'Delete batteries': '删除成套测评',
  'Assessment settings': '测评设置',
  'selected batteries?': '所选成套测评？',
  // Settings (AddNormMode.js)
  'Add New Norm': '添加新常模',
  'Assessment on which the norm profile is based': '常模所使用的测评工具',
  Frequency: '从组中提取',
  SD: '标准差',
  'Sample Size': '样本量',
  'Sample Size:': '样本量:',
  'Sample size must be greater than or equal to 1': '样本量必须大于或等于1',
  'Select group': '请选择组',
  'Norm name': '常模名称',
  'Assessment on which the norm is based': '请指定测评工具',

  // Settings (CompetencyGroups.js)
  'Copy of': '副本',
  'Competency Group': '胜任力组',
  'Delete assessment': '删除测评',
  'selected assessments': '所选测评',
  'this assessment': '这个测评',
  'Duplicate assessment': '复制测评',
  'Are you sure you want to duplicate': '确定要复制',
  'Loading competencies': '加载中',

  // Settings (CompetencyGroupsDetails.js)
  Structure: '结构',
  'Response scale': '应答选项',
  'Loading Competency Details': '加载中……',
  'Categories:': '胜任力类别',
  'Competencies:': '胜任力',
  'Numbers of items': '问题数',
  'Multiple choice:': '选择题',
  'Free text:': '开放题',
  'Estimated time': '预估时间',
  minutes: '分钟',
  'Available languages': '支持语言',
  'Make sure at least one language is fully supported in assessment': '请确保这个测评下的所有项目支持至少一种语言',

  Questions: '问题',
  'Competency deletion': '删除胜任力',
  'competencies?': '胜任力吗？',
  'competency?': '胜任力吗？',
  'Assessment deletion': '删除测评',
  'Are you sure you want to deleted selected the assessment?': '确定要删除所选测评吗？',

  // Settings Templates (WelcomeText.js)
  'Welcome texts': '欢迎辞',
  'selected welcome texts?': '所选欢迎辞吗？',
  'this welcome text?': '这个欢迎辞吗？',

  // Settings Templates (WelcomeTextScreen.js)
  'Add new welcome text': '添加新欢迎辞',
  'Template name': '模板名称',
  'welcome text': '欢迎辞',
  'Delete welcome texts': '删除欢迎辞',

  // Settings Templates (templatesTabs.js)
  'Assessment welcome texts': '测评欢迎辞',
  template: '模板',
  'Delete templates': '删除模板',
  'selected templates?': '所选模板吗？',
  'this template?': '这个模板吗？',

  // Settings Templates (TemplatesScreen.js)
  'Field is required': '必填项',
  'Template subject': '模板主题',

  // Settings ResponseScales (ResponseScales.js)
  'Response Scale': '应答选项',
  'Delete scale(s)': '删除选项',
  'Are you sure you want to delete this scale(s)?': '确定要删除这个选项吗？',
  Responses: '应答选项',
  Languages: '语言',
  'Score right to left': '计分方式为从右（高）到左（低）',
  indexedResponse: '选项%{index}',

  // Settings ResponseScales (bulkActions.js)
  'Delete selected response scale(s)': '删除所选评分选项',
  // Settings Norms (NormsHeader.js)
  'Norm Type:': '常模类型：',
  'Creation Date:': '创建日期：',

  // Settings Norms (NormsScales.js)
  'Norm Table': '常模对照表',
  'Frequency Histograms': '直方图',
  'Bio Data': '详细信息',
  Stenine: '标准九分',
  Stentable: '标准十分',

  // Settings Norms (Norms.js)
  'Add new norm': '添加新常模',
  'Delete norm(s)': '删除常模',
  'Assessment and Batteries': '测评和成套测评',
  'selected norms?': '所选常模吗？',
  'this norm?': '这个常模吗？',
  'Creation Date': '创建日期',

  // Settings Norms (HistogramView.js)
  FREQUENCY: '频次',
  'RAW SCORE': '原始分',
  'Raw Score': '原始分',

  // Settings ManageBattery (index.js)
  'Battery name': '成套测评名称',
  'Search existing assessments': '搜索成套测评',
  'Add battery': '添加成套测评',

  // Settings IdealProfile (IdealProfileScales.js)
  'Ideal profile type:': '理想剖析图类型：',
  'Unsaved data': '未保存的数据：',
  'The current ideal profile has not been saved. Would you like to save it?': '当前理想剖析图尚未保存，是否需要保存？',
  addIdealProfileGroups: '组',

  // Sidebar.js
  Logout: '退出登录',

  // Settings IdealProfile (IdealProfile.js)
  'Delete ideal profiles': '删除理想剖析图',
  'selected ideal profiles?': '所选理想剖析图吗？',
  'this ideal profile?': '这个理想剖析图吗？',

  // Settings AddIdealProfile.js
  'Add ideal profile': '添加理想剖析图',
  'Ideal profile name': '理想剖析图名称',
  'I will manually select the values': '手动配置剖析图值',
  'I will use respondent data to determine the values': '基于受测者数据配置剖析图值',
  Test: '测评',
  // (advancedFilterOptions.js)
  'Is equal to': '等于',
  'Is not equal to': '不等于',
  'Starts with': '为开头',
  Contains: '包含',
  contains: '包含',
  'Does not contain': '不包含',
  'Ends with': '为结尾',
  'Lesser than': '小于',
  'Greater than': '大于',
  '1 day': '1天内',
  '3 days': '3天内',
  '1 week': '1周内',
  '2 weeks': '2周内',
  '1 month': '1个月内',
  '1 year': '1年内',

  // groupsTypes.js
  Unsupervised: '无人监督模式',
  Supervised: '有人监督模式',
  // idealProfileTypes.js
  'Manually configured': '手动配置',
  'Based on respondent data': '基于受测者数据配置',

  // inviteCreationCategories.js
  'Personality tests': '人格',
  'Interests Values Attitudes': '兴趣/价值观/态度',
  Value: '数值',
  'Preferences/Styles': '偏好/风格',
  'Custom battery': '自定义成套测评',
  'General Solutions': '通用解决方案',
  'Screening Solutions': '筛选解决方案',
  Solution: '解决方案',
  Battery: '成套测评',
  'Every 4 days': '每4天',
  'Every 3 days': '每3天',
  'Every 2 days': '每2天',
  'Every day': '每天 ',
  "Don't send": '不发送',
  Immediate: '即刻发送',

  // inviteStatuses.js
  'Not sent': '未发送',
  Scheduled: '已预约',
  Sent: '已发送',
  Failed: '发送失败',
  'Failed status 2': '投递失败',

  // raterRoles.js
  Peer: '同事',
  'Direct report': '下属',

  // respondentSelectOptions.js
  'No Qualification': '无教育程度信息',
  'Secondary School': '初中及以下',
  'Industry or Trade Training': '中专技校',
  'Professional Qualification': '高中',
  'Tertiary Institute Diploma/Certificate': '大专',
  'University Bachelor Degree': '本科',
  'Postgraduate Diploma/Certificate': '研究生文凭/证书',
  'Bachelor Honours': '荣誉学士',
  'Masters Degree': '硕士',
  'Doctorate Degree': '博士',
  Aborigine: '北京',
  African: '天津',
  'African American': '上海',
  Afrikaner: '重庆',
  Afrikaans: '',
  Albanian: '安徽',
  Algerian: '福建',
  American: '甘肃',
  Argentinian: '广东',
  'Australian Aborigine': '广西',
  Arab: '贵州',
  Asian: '海南',
  Austrian: '河北',
  Australian: '河南',
  Bangladeshi: '黑龙江',
  Balinese: '湖北',
  'Black British': '湖南',
  'Black Canadian': '吉林',
  Belgian: '江苏',
  'Black Carribean': '江西',
  'Black Indian': '辽宁',
  'Black African': '内蒙古',
  Bolivian: '宁夏',
  Bengali: '青海',
  'Black Other': '山东',
  Bosnian: '山西',
  Brazilian: '陕西',
  British: '四川',
  Belarusian: '西藏',
  Bulgarian: '新疆',
  Burmese: '云南',
  Canadian: '浙江',
  Castilian: '澳门',
  Catalan: '香港',
  Celtic: '台湾',
  Chinese: '其它亚洲国家和地区',
  'Channel Islander': '欧洲',
  Cossack: '北美洲',
  Chilean: '南美洲',
  Cambodian: '大洋洲',
  Chechen: '非洲',
  Colombian: '哥伦比亚',
  'Costa Rican': '哥斯达黎加',
  Croatian: '克罗地亚',
  Corsican: '科西嘉',
  Cantonese: '广东',
  Cuban: '古巴',
  Cypriot: '塞浦路斯',
  'Cypriot Greek': '塞浦路斯希腊人',
  Czech: '捷克',
  Danish: '丹麦',
  Dutch: '荷兰',
  Eurasian: '欧亚',
  Ecuadorian: '厄瓜多尔',
  Egyptian: '埃及',
  English: '英语',
  Estonian: '爱沙尼亚',
  Ethiopian: '埃塞俄比亚',
  European: '欧洲',
  Fijian: '斐济',
  Filipino: '菲律宾',
  Flemish: '弗拉芒',
  Finnish: '芬兰',
  French: '法国',
  Gaelic: '盖尔',
  Georgian: '格鲁吉亚',
  German: '德国',
  Greek: '希腊',
  Hungarian: '匈牙利',
  Hispanic: '西班牙裔',
  'Hong Kong Chinese': '中国（香港地区）',
  Icelandic: '冰岛',
  Indonesian: '印尼',
  Irani: '伊朗',
  Indian: '印度',
  'Iranian/Persian': '伊朗/波斯',
  Iraqi: '伊拉克',
  Irish: '爱尔兰',
  'Iranian Arab': '伊朗阿拉伯 ',
  Iranian: '伊朗',
  Italian: '意大利',
  Jamaican: '牙买加',
  Jewish: '犹太',
  'Jewish/Israeli': '犹太/以色列',
  Jordanian: '约旦',
  Japanese: '日本',
  Kenyan: '肯尼亚',
  Kiwi: '新西兰',
  Kongo: '刚果',
  Korean: '韩国',
  Latin: '拉丁',
  Lebanese: '黎巴嫩',
  Lithuanian: '立陶宛',
  Libyan: '利比亚',
  'Latin American': '拉美',
  Latino: '拉丁裔',
  Luxembourger: '卢森堡',
  Latvian: '拉脱维亚',
  Macedonian: '马其顿',
  'Middle Eastern': '中东',
  Malaitian: '马拉地',
  Maori: '毛利',
  Monacan: '摩纳哥',
  Moroccan: '摩洛哥',
  Maltese: '马耳他',
  Mexican: '墨西哥',
  Malay: '马来',
  'Mixed Race': '混血',
  'New Zealand European': '新西兰欧洲',
  Nigerian: '尼日利亚',
  Norwegian: '挪威',
  Nepalese: '尼泊尔',
  'New Zealander': '新西兰',
  'Other Ethnicity': '其他族裔',
  Paraguayan: '巴拉圭',
  'Pacific Islander': '太平洋岛',
  Persian: '波斯',
  Peruvian: '秘鲁',
  'Persian/Iranian': '波斯/伊朗',
  Pakistani: '巴基斯坦',
  Palestinian: '巴勒斯坦',
  Polish: '波兰',
  'Papua New Guinean': '巴布亚新几内亚',
  'Puerto Rican': '波多黎哥',
  Portuguese: '葡萄牙',
  Polynesian: '波利尼西亚 ',
  Romanian: '罗马尼亚',
  Russian: '俄罗斯',
  Samoan: '萨摩亚',
  'South African Coloured': '南非混血',
  Scottish: '苏格兰',
  Swede: '瑞典人',
  'South African European': '南非欧洲',
  Serbian: '塞尔维亚',
  Swedish: '瑞典',
  'South American Indian': '南美印第安',
  Sicilian: '西西里',
  Slovak: '斯洛伐克',
  Slav: '斯拉夫',
  Spanish: '西班牙',
  'Sri Lankan': '斯里兰卡',
  'Southeast Asian': '东南亚',
  Swiss: '瑞士',
  Swahili: '斯瓦希里',
  Swazi: '斯瓦齐',
  Taiwanese: '中国（台湾地区）',
  Thai: '泰国',
  Turkish: '土耳其',
  Tamil: '泰米尔',
  Tongan: '汤加',
  Rwandan: '卢旺达',
  Tunisian: '突尼斯',
  Udi: '乌迪',
  Ugandan: '乌干达',
  Ukrainian: '乌克兰',
  Uruguayan: '乌拉圭',
  Vietnamese: '越南',
  Welsh: '威尔士',
  White: '白人',
  'West Indian': '西印度',
  Zimbabwean: '津巴布韦',
  Zulu: '祖鲁人',
  Manager: '管理层',
  '- Chief executive': '- 首席执行官',
  '- Administrative or commercial manager': '- 行政或商务经理',
  '- Production or specialized services manager': '- 生产或专业服务经理',
  '- Hospitality, retail or other services manager': '- 酒店，零售或其他服务经理',
  '- Senior manager': '- 高级经理',
  '- Middle manager': '- 中级经理',
  '- Junior manager/supervisor': '- 初级经理/主管',
  '- Other': '- 其他',
  Professional: '专业人员',
  '- Science or engineering professional': '- 科学/工程',
  '- Health professional': '- 医疗卫生专业人员',
  '- Teaching professional': '- 教育/教学专业人员',
  '- Business or administration professional': '- 商业/管理专业人员',
  '- Information and communications technology professional': '- 信息和通信技术专业人员',
  '- Legal, social or cultural professional': '- 法律/社会/文化专业人员',
  'Technician or associate professional': '技术人员或专业人员助理',
  '- Science or engineering associate professional': '- 科学/工程技术人员',
  '- Health associate professional': '- 医疗卫生技术人员',
  '- Business or administration associate professional': '- 商业/管理技术人员',
  '- Legal, social, cultural or related associate professional': '- 法律/社会/文化技术人员',
  '- Information and communications technician': '- 信息和通信技术技术人员',
  'Clerical support worker': '支持人员',
  '- General or keyboard clerk': '- 一般支持人员',
  '- Customer services clerk': '- 客服支持人员',
  '- Numerical or material recording clerk': '- 记录人员',
  'Service or sales worker': '服务及销售人员',
  '- Personal service worker': '- 个人服务人员',
  '- Sales worker': '- 销售人员',
  '- Personal care worker': '- 个人护理人员',
  '- Protective services worker': '- 安保人员',
  'Skilled agricultural, forestry or fishery worker': '农林渔业从业人员',
  '- Market-oriented skilled agricultural worker': '- 农业从业人员（市场导向）',
  '- Market-oriented skilled forestry, fishing or hunting worker': '- 林渔业从业人员（市场导向）',
  '- Subsistence farmer, fisher, hunter or gatherer': '- 务农、畜牧、捕鱼业者',
  'Craft or related trades worker': '工艺及有关人员',
  '- Building or related trades worker, excluding electrician': '- 建筑有关人员',
  '- Metal, machinery or related trades worker': '- 金属和机械有关人员',
  '- Handicraft or printing worker': '- 手工艺和印刷有关人员',
  '- Electrical or electronic trades worker': '- 电子和电器有关人员',
  '- Food processing, wood working, garment or other craft or related trades':
    '- 食品加工/木材/服饰和其他手工艺有关人员',
  'Plant or machine operator, or assembler': '机械机床操作员和装配工',
  '- Stationary plant or machine operator': '- 机械机床操作员',
  '- Assembler': '- 组装员',
  '- Driver or mobile plant operator': '- 驾驶和移动机械操作员',
  'Elementary occupation': '非技术人员',
  '- Cleaner or helper': '- 清洁有关人员',
  '- Agricultural, forestry or fishery labourer': '- 农林渔业劳动者',
  '- Labourer in mining, construction, manufacturing or transport': '- 采矿/建筑/制造/运输有关人员',
  '- Food preparation assistant': '- 食品准备有关人员',
  '- Street or related sales or service worker': '- 街头工作有关人员',
  '- Refuse worker or other elementary worker': '- 垃圾处理有关人员',
  'Armed forces occupation': '军人',
  '- Commissioned armed forces officer': '- 部队军官',
  '- Non-commissioned armed forces officer': '- 非士官',
  '- Armed forces occupation, other rank': '- 部队其他职位',
  'Local Government/Authority': '高级经理',
  'Private, Corporate ': '中级经理',
  'Public service, Fed/State Govt': '初级经理或主管',
  'State Owned Corporation': '普通员工',
  Unknown: '未知',
  'Administrative and Support Service Activities': '卫生、社会保障和社会福利业',
  'Agriculture, Forestry and Fishing': '农、林、牧、渔业',
  'Arts, Entertainment and Recreation': '文化、体育和娱乐业',
  'Banking & Finance': '金融业',
  'Business Services': '租赁和商务服务业',
  'Architecture and Construction': '建筑业',
  'Education and Training': '教育和培训',
  'Utilities (Gas, Water, Electric, etc)': '公共事业（天然气、水、电等）',
  Engineering: '工程制造',
  'Hospitality and Tourism': '酒店和旅游',
  'Human Resources': '人力资源',
  'Health Sector': '健康和卫生',
  'Information and Technology': '信息技术',
  Insurance: '保险',
  'Law Enforcement': '执法部门',
  Manufacturing: '制造业',
  'Mining and Quarrying': '采矿和采石',
  'Military & Defence': '军事和国防',
  'Oil and Gas/Energy Exploration': '石油、天然气和能源勘探',
  'Pharmaceutical Industry': '制药',
  'Public Administration': '公共管理和行政',
  'Real Estate Activities': '房地产业',
  'Scientific and Technical Activities': '科学研究、技术开发业',
  Security: '安保',
  'Social Work': '水利、环境和公共设施管理业',
  Telecommunications: '通讯业',
  'Transportation and Storage': '交通运输、仓储和邮政业',
  Media: '媒体、传媒',
  'Wholesale and Retail Trade': '批发和零售业',
  Arabic: '阿拉伯语',
  'Brazilian Portuguese': '巴西葡萄牙语',
  'Chinese-Simplified': '中文（简体）',
  'Chinese-Traditional': '中文（繁体）',
  Hebrew: '希伯来语',
  'Hindi-Urdu': '印地语-乌尔都语',
  isiNdebele: '',
  isiXhosa: '',
  isiZulu: '',
  'Latin American Spanish': '拉丁美洲西班牙语',
  Malayalam: '马拉雅拉姆语',
  Punjabi: '旁遮普语',
  Sepedi: '塞佩迪语 ',
  'Southern Thai': '南泰语',
  Sesotho: '塞索托语',
  Setswana: '塞茨瓦纳语',
  siSwati: '斯瓦特语',
  'Tagalog (Filipino)': '塔加洛语（菲律宾语',
  Turkmen: '土库曼语',
  Tshivenda: '茨维瑞达语',
  Xitsonga: '恩教沙语',
  Male: '男',
  Female: '女',
  'Not Specified': '未说明',

  // errorMessages.js
  'Email address is not valid': '无效的电子邮件',

  // table bulkActions assessments.js
  'Edit battery': '编辑成套测验',
  // table singleRowActions competency.js
  'Add competency': '添加胜任力',
  'Edit competency category': '编辑胜任力大类',
  'Delete competency category': '删除胜任力大类',
  'Edit competency': '编辑胜任力',
  'Delete competency': '删除胜任力',
  'Edit question': '编辑问题',
  'Delete question': '删除问题',

  // table singleRowActions competencyGroup.js
  'Delete competency group': '删除胜任力组',
  'Go to competency groups details': '查看胜任力组信息',

  // table singleRowActions  group.js
  'Invite respondents to questionnaire': '邀请受测者完成测评',
  'Administer answer sheet': '答题纸',
  'Administer managed group': '开放组',
  'Create an ideal profile based on selected group': '根据所选组创建理想剖析图',
  'Create a Norm based on selected group': '根据所选组创建常模',
  'View or edit': '查看或编辑',
  Delete: '删除',

  // table singleRowActions invites.js
  Remind: '提示：',

  // table singleRowActions projects.js
  'Add to favorites': '添加到收藏夹',
  View: '视图',
  'Delete Project': '删除项目',

  // table singleRowActions raters.js
  'Delete rater': '删除评分者',

  // table singleRowActions reports.js
  Open: '打开',
  Download: '下载',

  // table singleRowActions respondents.js
  'Administer questionnaire': '监督测验',
  'Invite to questionnaire': '远程邀请测验',
  'Add tag': '添加标签',

  // table singleRowActions session.js
  'Invite raters who have not yet complete': '向未完成的评估者发送邀请',
  'Invite who have not yet been invited': '向未邀请的评估者发送邀请',
  'Send reminder to raters who have already been invited but have not yet completed': '向未完成的评估者发送提醒',
  'Invite to specify own rater': '邀请被评估人自行指定评估者',
  'Send invitation to self': '向被评估人发送邀请',
  'Edit session': '编辑评估组',

  // table bulkActions assessments.js
  'Add custom battery': '添加成套测评',
  'Edit assessment': '编辑成套测评',
  'Delete Assessment(s)': '删除成套测评',

  // table bulkActions groups.js
  'Create a Norm based on the selected group(s)': '从所选组创建常模',
  createIdealProfileBasedOnSselectedGroup: '从所选组创建理想剖析图',
  'Create an Ideal profile based on the selected group(s)': '从所选组创建理想剖析图',
  'Delete selected group(s)': '删除所选组',
  'Generate an open group link': '创建开放组链接',

  // table bulkActions idealProfiles.js
  'Edit ideal profile': '编辑理想剖析图',

  // table bulkActions invites.js
  'Copy invite link to clipboard': '将邀请链接复制到剪贴板',
  'Delete invites': '删除测评邀请',

  // table bulkActions projects.js
  'Edit project details': '编辑项目信息',
  'Delete projects(s)': '删除项目',
  // table bulkActions raters.js
  'Send invite to selected rater(s)': '向所选评估者发送邀请',
  'Remove selected rater(s) from session': '从评估组中删除所选评估者',

  // table bulkActions reports.js
  'Delete selected report(s)': '删除所选报告',
  'Download selected report(s)': '下载所选报告',
  'View selected report(s)': '查看所选报告',

  // table bulkActions respondentGroups.js
  'Remove respondent from selected group(s)': '从所选组中移除受测者',
  // table bulkActions respondents.js
  'Administer assessment': '监督测验',
  'Administer assessment for': '监督测验',
  'Invite respondent(s) to assessment': '邀请受测者参与评估',
  'Request report(s)': '请求报告',
  'Go to respondent details': '查看受测者信息',
  'Manage tags for selected respondent(s)': '管理选定受测者的标签',
  'Add new group from selection': '添加至新组',
  'Add to existing group': '添加到现有组',
  'Delete selected respondents from group': '从组中删除受测者',
  'Delete selected respondents': '删除所选受测者',
  'this respondent': '所选的受测者',
  'selected respondents': '所选的受测者',

  // table bulkActions respondentsTest.js
  'Delete selected result(s)': '删除所选结果',

  // table bulkActions sessions.js
  'Invite to specify own raters': '邀请被评估者自行指定评估人',
  'Delete Session(s)': '删除评估组',

  // table bulkActions templates.js
  'Go to template details': '查看模板信息',
  'Delete selected template(s)': '删除所选模板',
  // table bulkActions welcomeText.js
  'Go to welcome text details': '查看欢迎辞',
  'Delete selected welcome text(s)': '删除所选欢迎辞',
  // table entitiesDropdownActions groupDetails.js
  'Edit title': '编辑标题',
  'Duplicate 360 assessment': '复制360度评估',
  'Delete 360 assessment': '删除360度评估',
  // table entitiesDropdownActions sessions.js
  'Invite raters who have not yet completed': '邀请未完成的评估者',

  // table tableActions competencyGroupsDetails.js
  'Review competencies': '查看胜任力',

  // table tableActions customGroups.js
  'Add new 360 assessment': '添加新360度评估',

  // table tableActions raters.js
  'Add rater': '添加评估者',
  // table tableActions respondents.js
  'Import respondents': '批量添加受测者',

  // table tableActions templates.js
  'Add new template': '添加新模板',
  'Edit template': '编辑模板',
  'Edit welcome text': '编辑欢迎辞',
  // QuestionOrder.js
  Default: '默认排序',
  Custom: '自定义排序',
  Random: '随机排序',
  // tables columns sessions.js
  Invited: '已邀请',
  'Invite Not Sent': '邀请未发送',
  'Invite not sent': '邀请未发送',

  'Show expired': '显示已过期邀请',
  'Sorry we could not find any': '找不到任何 %{entityName}',
  'Sorry, we could not find any respondents': '暂无组成员',
  'Sorry, we could not find any reports': '暂无记录',
  'Sorry, we could not find any results': '暂无记录',
  'Sorry, we could not find any invites': '暂无记录',
  'Sorry, we could not find any records': '暂无记录',
  'Sorry, we could not find any projects': '暂无记录',
  'Sorry, we could not find any tests': '暂无记录',
  'Sorry, we could not find any groups': '暂无记录',
  'Sorry, we could not find any notifications': '暂无记录',
  'Sorry, we could not find any raters': '暂无记录',
  'Sorry, we could not find any templates': '暂无记录',
  'Sorry, we could not find any sessions': '暂无记录',
  'Sorry, we could not find any welcome texts': '暂无记录',
  'Sorry, we could not find any competency groups': '暂无记录',
  'Sorry, we could not find any norms': '暂无记录',
  'Sorry, we could not find any response scales': '暂无记录',
  'Number of items': '问题数量',
  Categories: '胜任力类别',
  'User name': '用户名',
  'Update amount': '数量',
  Members: '成员',
  members: '成员',
  'Invitation Date': '邀请日期',
  'Respondent Name': '受测者姓名',
  Report: '报告',
  'Date submitted': '提交日期',
  Status: '状态',
  'Not invited': '未邀请',
  'Not completed': '未完成',
  'Completed Date': '完成日期',
  'Direct Report': '下属',
  Progress: '完成进度',
  'Assessments Done': '完成的测评',
  'Add new project': '添加新项目',
  Date: '日期',
  'Loading entities': '加载中……',
  Created: '创建',
  'No options': '暂无记录',
  createOption: '新建标签',
  '**Default': '**默认',
  '**Default text': '**默认（无欢迎辞）',
  // Settings Account ChangePassword.js

  'Passwords must match': '新旧密码必须保持一致',
  'Change password': '更改密码',
  'Confirm new password': '确认新密码',
  'New password': '新密码',
  Change: '确认更改',
  'Pins must match': '新旧PIN码必须保持一致',
  'Change pin': '更改PIN码',
  'Confirm new pin': '确认新PIN码',
  'New pin': '新PIN码',
  'Total:': '已添加信用点总数：',
  'Loading credits history...': '加载中……',
  'From date': '开始日期',
  'To date': '截止日期',
  Pin: 'PIN码',
  'Receive email notifications': '启用系统提醒邮件',
  'Complete Status': '完成状态',
  'Invite Status': '邀请状态',
  'Not Invited': '未邀请',
  'Sessions count': '评估组数量',

  // ImportSessions
  'Import sessions': '批量添加评估组',
  prepare: '准备导入文件',
  preview: '预览',
  import: '导入',
  Import: '导入',
  'Prepare a csv file yourself or': '可以自行创建csv文档，或者',
  'download the sample import file': '点此链接下载样例csv文档',
  'Make sure the data order is set out correctly': '请确保csv文档格式正确（可参考样例文档）',
  'Upload the file': '上传文件',
  'Drag and drop file here or browse the file': '请将准备好的csv文档拖到此处，或点击下面的按钮手动选择文件',
  'Upload the csv file to import respondents': '上传csv文档批量添加受测者',
  'The first line contains field headers': '数据包含标题',
  'Choose file': '选择文件',

  // AccountTabs
  general: '基本设置',
  'credit updates': '信用点统计',
  'Reports Stats': '报告使用统计',
  'reports stats': '报告使用统计',
  'assessments stats': '测评工具使用统计',

  // ReportUsage
  total: '合计: %{total}',
  'Report name': '报告',
  'Usage count': '数量',
  'Assessment name': '测评工具',
  Total: '总计',
  'Loading requested reports': '加载中',
  'Loading used assessments': '加载中',

  // Toasts & system notifications
  'Tag(s) have been deleted': '标签已删除',
  'Loading items': '加载中',
  'Saving results': '保存中',
  'Ideal profile has been created': '理想剖析图已创建',
  'Copy of 360 survey has been created': '360度评估模型已复制',
  groupCreationToast: '新组已添加',
  'Tag(s) have been updated': '标签已更新',
  'Welcome text has been saved': '欢迎辞模板已保存',
  'Welcome text has been updated': '欢迎辞模板已更新',

  // Toasts
  respondentsDeletionToast: '受测者已删除',
  resultsSuccessToast: '%{respondentInfo}数据已保存',
  surveyDeletionCancelToast: '已被360度评估项目调用的评估无法删除',
  creditBalanceUpdateToast: '信用点已添加',
  correctDataToast: '请确保已正确添加所有数据，',
  downloadLimitToast: '不能同时下载超过 50 个报告',
  categoryUpdateToast: '类别已更新',
  newCategoryToast: '添加了新类别',
  createdRequestToast: '新报告请求',
  respondentCreationToast: '已添加受测者',
  respondentUpdateToast: '受测者已更新',
  clipboardCopyToast: '已成功复制到剪贴板',
  addedAssessmentToast: '已添加评估',
  deletedAssessmentToast: '评估已被删除',
  completedAssessmentToast: '%{respondentName} 完成 %{assessment}',
  importRespondentsErrorToast: '导入受测者过程中出现问题',
  importSessionsErrorToast: '导入会话时出错',
  competencyTitleUpdateToast: '标题已更新',
  competencyUpdateToast: '胜任力已更新',
  newCompetencyToast: '已添加新胜任力',
  questionUpdateToast: '问题已更新',
  questionAdditionToast: '问题已添加',
  groupLinkErrorToast: '生成组链接时出错，请重试',
  reportsDeletionToast: '报告已被删除',
  resultsSavingErrorToast: '保存结果时出错 %{respondentName} %{assessment}',
  reportRequestCreationToast: '已创建报告请求',
  assessmentDataFailToast: '无法加载评估数据',
  scaleUpdatedToast: '应答量表已更新',
  scaleCreatedToast: '应答量表已保存',
  scaleDeletionToast: '应答量表已被删除',
  surveyUpdatedToast: '360度全景调查已更新',
  normsDeletionToast: '常模已被删除',
  'Deletion blocked': '删除已阻止',
  groupCreationErrorToast: '组创建期间出错',
  groupsDeletionToast: '组已被删除',
  reportsGenerationToast: '已成功请求报告',
  invitesDeletionToast: '邀请已删除',
  normCreationToast: '常模已被创建',
  normCreationErrorToast: '无法创建常模，请重试',
  projectDeletionToast: '项目已被删除',
  projectsDeletionToast: '项目已被删除',
  projectCreationToast: '项目已创建',
  projectUpdateToast: '项目已更新',
  ratersDeletionToast: '评估者已被删除',
  categoryDeletionToast: '胜任力能力类别已删除',
  resultsDeletionToast: '结果已被删除',
  tagsAdditionToast: '已添加标记',
  addedToGroupToast: '已成功添加到组',
  questionnaireSessionErrorToast: '无法创建问卷，请稍后重试',
  sessionAdditionToast: '已添加会话',
  ratersAdditionToast: '已添加会话评估者',
  competencyDeletionToast: '能力已被删除',
  questionDeletionToast: '问题已被删除',
  assessmentsDeletionToast: '评估已被删除',
  profilesDeletionToast: '理想剖析图已被删除',
  profileUpdateToast: '理想剖析图已更新',
  templatesDeletionToast: '模板已被删除',
  templateAdditionToast: '模板已保存',
  templateUpdateToast: '模板已更新',
  welcomeTextsDeletionToast: '欢迎模板已被删除',
  batteryAdditionToast: '成套测验已创建',
  batteryUpdateToast: '成套测验已更新',
  batteriesDeletionToast: '成套测验已删除',
  batteryDeletionToast: '成套测验已被删除',
  passwordUpdateSuccessToast: '密码已成功更新',
  pinUpdateSuccessToast: 'PIN已成功更新',

  // General
  Continue: '继续',

  'Activity Log': '活动日志',
  'Credit updates': '信用点购买记录',
  'Credit Updates': '信用点购买记录',
  General: '常规',
  here: '这里',
  History: '历史记录',
  New: '新增',
  'no messages': '暂无消息',
  'no new messages': '暂无新消息',
  'Norm Name': '常模名称',
  reportFormatUpdateFailureToast: '报告格式未更新',
  reportFormatUpdateSuccessToast: '报告格式已成功更新',
  'reports requested': '已生成的报告',
  'Send invitation link via email for respondents': '通过电子邮件向%{respondentsCount} 位受测者发送邀请链接',
  'tests completed': '测评完成',
  'the previous notification area has been moved': '上一个通知区域已移动',
  'Selected respondent(s) can’t be invited, since one or several of them have already been invited':
    '无法邀请选定的受测者，因为其中一个或多个受测者已被邀请',
  'Copy invite link': '复制邀请链接',
  'Invite link can be copied only for not completed projects': '只能复制项目内尚未完成的邀请链接',
  'Invite link can be copied for 1 selected respondent at a time': '一次可以为1个选定的受测者复制邀请链接',
  'Invite link is not available, please send or download invites in the csv first':
    '邀请链接不可用，请先在csv中发送或下载邀请',
  'Send reminders': '发送提醒',
  'One or several selected respondents haven’t been invited yet': '一个或多个选定的受测者尚未被邀请',
  'Download reports': '下载报告',
  'Download report option can be available for 1 selected respondent at a time':
    '一次可以为1个选定的受测者提供下载报告选项',
  'One or several selected respondents don’t have available reports': '一个或多个选定的受测者没有可用的报告',
  'Go to respondent profile': '转到受测者资料',
  'Go to respondent profile option can be available for 1 selected respondent at a time':
    '“转到受测者个人资料”选项一次仅在选中1个受测者时有效',
  'Remove respondent(s)': '删除受测者',
  "Selected respondents can't be deleted since one or several of them already passed the assessment":
    '无法删除选定的受测者，因为其中一个或多个已完成评估',
  'Administer Managed Group Session': '启用有人监督开放组',
  'Generate Open Group Link': '生成无人监督开放组链接',
  'Create Norm from a Group': '从组创建常模',
  'Create an Ideal Profile from a Group': '从组创建理想剖析图',
  'Invite Respondents for Assessment': '邀请受测者',
  'Delete selected Group': '删除所选组',
  Authorize: '授权',
  'Project rules': '项目说明',
  'Invites sending': '邀请',
  'Select assessment, language and email template you want to use': '选择要使用的测评工具、对应语言和邮件模板',
  'Choose whether invites should go out on the scheduled date, get sent once respondent gets added to the project or manually with pre-selected test and template':
    '选择是否应在预定日期发出邀请，在将受测者添加到项目后发送邀请，或者使用预先选择的测评工具和模板手动发送邀请',
  'Reports generation': '报告',
  'Reports selected during project creation will be automatically generated after respondent submits their results':
    '在项目创建过程中所选择的报告将在受测者提交测评结果后自动生成',
  'Direct feedback report may be activated, if available for the selected test too (check for details with your local distributor)':
    '如果所选测评工具有可用的个人反馈报告，则会自动生成并发送（请向供应商了解详细信息）',
  'Credits usage': '信用点',
  'To ensure you have enough credits to get reports generated automatically, we are freezing the required cost per respondent once they get added to the project':
    '为了确保有足够的信用点自动生成报告，一旦将受测者添加到项目中，系统将提前冻结为他们生成报告所需的信用点数量',
  Back: '返回',
  'Country of origin': '适用国家/地区',
  Mixed: '多国',
  Scale: '量表',
  Mean: '平均分',
  Histogram: '直方图',
  age: '年龄',
  gender: '性别',
  ethnicity: '种族',
  education: '教育程度',
  occupation: '职业',
  industry: '行业',
  firstLanguage: '母语',
  'first language': '母语',
  sector: '职级',
  Sten: '标准十分',
  Stanine: '标准九分',
  'Less than': '小于',
  'Unknown label': '未分类',
  'Send immediate feedback report to respondent': '立即向受测者发送反馈报告',
  'Send immediate feedback info':
    '评估完成后，立即通过电子邮件将所选的个人反馈报告发送给受测者。请核实如何收取信用点。',
  'General information': '一般信息',
  summary: '说明',
  Summary: '说明',
  Inactive: '不活跃',
  Category: '类别',
  'Expire after': '过期时间为',
  'How to send invites': '如何发送邀请',
  Weekly: '每周',
  Daily: '每天',
  Monthly: '每月',
  'Do not remind': '不提醒',
  days: '天',
  expired: '已到期',
  'End project': '结束项目',
  'Direct feedback report': '反馈报告',
  Manually: '手动',
  Automatically: '自动',
  Schedule: '计划发送',
  Select: '选择',
  'Invite template': '邀请模板',
  'Total cost': '总消耗量',
  'Cost per respondent': '每位受测者需要消耗的信用点',
  'Current credits balance': '当前信用点余额',
  'Create project': '创建项目',
  'I confirm that I have read this summary and I want to proceed': '我确认我已阅读此说明且同意继续',
  Reminder: '提醒',
  'Estimated project cost': '预计项目总消耗量',
  'Project cost': '项目总消耗量',
  'Invite expires on': '邀请到期日期',
  'Invites are sent manually': '手动发送邀请',
  'Use button on the right to manually send invites': '使用右侧的按钮手动发送邀请',
  'Invites are sent automatically': '自动发送邀请',
  'Invites are automatically sent when you add respondents to the project':
    '将受测者添加到本项目时，会自动为其发送邀请',
  'Invites are scheduled': '邀请已设置为计划发送',
  'Invites will be automatically sent on selected date': '邀请将在选定日期自动发送',
  'Project settings': '项目设置',
  invited: '邀请',
  'invites sent': '已发送邀请',
  'Invites will be sent on': '邀请函将发送至',
  'Invites were sent on': '邀请已发送至',
  'Respondents added after scheduled date will be invited automatically': '在预定日期之后添加的受测者将被自动邀请',
  'Invite via email': '通过电子邮件邀请',
  'Download invite links in a csv file': '导出为csv文件',
  'Reports are generated after completing the assessment': '评估完成后生成报告',
  'Add respondents': '添加受测者',
  'There are no respondents added': '没有添加受测者',
  "Sorry, we couldn't find any respondents": '找不到任何受测者',
  'You may start adding respondents via add or import respondents actions': '通过添加或批量导入添加受测者',
  'Add respondents groups or respondents': '添加受测者组或受测者',
  'Select group or respondents to be added to this project': '选择要添加到此项目的组或受测者',
  'credits per respondent will be frozen from your balance to ensure the project completion':
    '为确保项目能顺利完成，每一位受测者需要消耗的信用点将从账户余额中提前冻结。',
  'Respondents have been added': '已添加受测者',
  Active: '已激活',
  Unavailable: '不可用',
  'There are no reports available for selected test and language': '没有针对所选测试和语言的可用报告',
  'Please make sure you selected correct language or contact your administrator for help':
    '请确保选择了正确的语言或联系您的管理员寻求帮助',
  'This field is required': '此字段是必需的',
  'Report format': '报告格式',
  'Change report format': '更改报告格式',
  'Invite will be sent to respondents with valid email address only': '邀请将仅发送给具有有效电子邮件地址的受测者',
  Percent: '百分比',
  Undeclared: '*未定义',
  'Details for': '详细信息 %{categorieName} 类别',
  RawDetails: '原始数据详情',
  '中文(简体) / Chinese (Simplified)': '中文(简体)',
  '中文(繁體) / Chinese (Traditional)': '中文(繁體)',
  '中文(香港) / Chinese(Traditional) HK': '中文(香港)',
  'To select multiple assessments, please create a battery in settings and select it from within the Favourites category':
    '如需要选择多个测评工具的组合，请先在设置中创建一个成套测验，然后从收藏夹中选择该成套测验',
  'Administer group session': '启用有人监督开放组',
  'Assessment info': '测评信息',
  'Respondent to create new record':
    '新受测者加入本组时自动创建新的受测者记录（如不勾选此项，本开放测评组仅接受本组内已有的受测者加入）',
  'Create norm based on the selected group(s)': '从所选的组创建常模',
  'Norm name is required': '请输入常模名称',
  'Assessment is required': '请选择测评工具',
  'Ideal profile name is required': '请输入理想剖析图名称',
  'Norm is required': '请选择常模',
  'Assessment on which the ideal profile is based': '理想剖析图所用测评工具',
  'Select norm': '请选择常模',
  'missing emails': '无电子邮件',
  'Email invites': '电子邮件邀请',
  'You are about to end a project': '即将完成此项目。',
  'All active invites will be deactivated and unused credits released':
    '所有未完成的邀请都将被停用，未使用的信用点将被释放。',
  'It will be not possible to revert the action': '本操作无法还原。',
  'You are about to send reminder to the selected respondents': '将向选定的受测者发送提醒',
  'Press “Yes” to continue': '按“是”继续',
  'Delete 360 survey': '删除360模型',
  'Forgot password?': '忘记密码',
  'You should receive your reset link soon at': '重置链接即将通过邮件方式发送',
  "Can't find the email?": '找不到邮件',
  'You should also check your spam folder': '请尝试检查垃圾邮件文件夹',
  'Email address': '电子邮件地址',
  'Request a reset link': '请求重置密码邮件',
  'Back to login page': '返回登录页面',
  Ethics: '种族',
  'Biodata Configuration': '个人信息模板',
  'There are no respondents added to this group': '尚未向此组添加任何受测者',
  'Ok, got it': '了解',
  'The administered group session has been created': '已创建有人监督开放组',
  'Managed group session in progress': '正在进行的有人监督开放组',
  'Session link': '开放组链接',
  'No respondents to display': '无受测者',
  "You may add respondents to the group from the existing respondents' list, or they can create new records during the session authorization via the session link":
    '可以从现有受测者列表中将受测者添加到组中，也可以将开放组链接发放给受测者们使其加入本开放组并创建新的受测者记录',
  'Link copied successfully': '已成功复制链接',
  'Respondent sessions': '受测者组',
  'End session': '关闭',
  'Do you want to close the session? Assessments that have started may still be completed but no new respondents may be added':
    '是否要关闭开放组？尚未完成的测评仍可继续进行，但本开放组链接将自动失效，受测者无法再通过此链接加入本开放组。',
  'You are about to launch a managed group session. While the session is active, actions with the group will be limited':
    '将要启用有人监督开放组。当启用后，原普通组的部分操作功能将无法使用。',
  'You are about to launch a managed group session': '将要启用有人监督开放组。',
  'While the session is active, actions with the group will be limited': '当启用后，部分操作将受到限制',
  Expired: '已过期',
  Id: '识别码',
  DOB: '出生日期',
  'There are no invites in this session yet': '尚无邀请信',
  'There are no results in this session yet': '尚无数据结果',
  'There are no reports in this session yet': '尚无报告',
  'You are about to send an invite to all respondents in the project': '将向项目中的所有受测者发送邀请',
  'Press “Yes” to proceed with the action': '按“是”继续操作',
  'You are about to send invites to selected respondents': '将要向选定的受测者发送邀请',
  'There are no sessions in this project': '此项目中还未加入任何组',
  'You may start creating sessions via add or import sessions actions': '通过添加或导入功能创建组',
  'Number of raters': '评估者数量',
  'Import complete': '导入已完成',
  'Records imported': '已导入的记录',
  Azerbaijani: '阿塞拜疆语',
  'Chinese(Simplified)': '中文（简体）',
  'Chinese(Traditional)': '中文（繁体）',
  'Chinese(Traditional) HK': '中文（繁体）香港',
  'American Spanish': '美式西班牙语',
  Hindi: '印地语',
  Slovenian: '斯洛文尼亚语',
  'Portuguese-Brazilian': '葡萄牙语-巴西语',
  Urdu: '乌都语',
  Montenegrian: '黑山',
  Mongolian: '蒙古语',
  'English(United States)': '英文（美国）',
  'Administer answer sheet for selected respondents': '为选定的受测者启动答题卡输入',
  'Invite respondent(s)': '邀请受测者',
  'Remove respondents': '删除受测者',
  'Are you sure you want to remove selected respondents?': '确定要删除选定的受测者？',
  'respondent(s)': '受测者',
  'Add Bio Data template': '添加个人信息模板',
  'Loading biodata templates': '加载个人信息模板',
  'Add Name': '名称',
  'Add description': '描述',
  selectEditCategoryValues: '选择列表：',
  'Add values to category': '将新的内容添加到选项中',
  'Select values for this category': '选择该选项的内容',
  'Select all': '全选',
  'Biodata Template has been created': '个人信息模板已创建',
  'Biodata Template has been updated': '个人信息模板已更新',
  'Biodata template(s) deleted': '个人信息模板已删除',
  biodataDefaultTemplate: '创建默认个人信息模板',
  'Delete selected biodata template(s)': '删除选定的个人信息模板',
  'Delete biodata templates': '删除个人信息模板',
  'the selected biodata templates?': '所选的个人信息模板',
  'this biodata template?': '这个个人信息模板',
  'Update default biodata template': '更新默认个人信息模板',
  'Are you sure you want to set this template as default?': '确定要将此模板设为默认模板吗？',
  'Default biodata template': '默认个人信息模板',
  'Bio data template': '个人信息模板',
  'Copy and share generated links for': '复制并共享链接',
  'that have email address missing': '	缺少电子邮件地址',
  'You have chosen a group of': '	所选择的组包含',
  'have email address missing': '受测者缺少电子邮件地址',
  'This 360 survey is used by one or more project, therefore can not be edited or deleted':
    '本360模型正在被一个或多个项目使用，因此无法被编辑或删除',
  and: '及其他',
  more: '位受测者',
  biodataTooltip: '个人信息模板用于自定义选择选项来收集受测者的个人信息。',
  'Authorize selected respondent(s) to commence the assessment?': '授权选定的受测者',
  'Reset assessment deadline': '重设评估截止日期',
  Update: '更新',
  updateAssessmentInvitationToast: '实际邀请',
  updateProjectInvitationToast: '实际邀请',
  'Default Biodata Template has been updated': '默认生物数据模板已更新',
  DEFAULT: '默认',
  atLeastOneCategory: '请至少选择一项',
  downloadInvitesReport: '下載 CSV',
  fullName: '姓名',
  email: '电子邮件地址',
  inviteURL: '测评链接',
  testID: '测评工具',
  langID: '语言',
  csvReportName: '测评链接导出列表 %{date}.csv',
  biodataDefaultTemplateLabel: '** 无生物数据模板（包括所有类别）**',
  defaultCompleteBioTemplate: '** 个人信息模板（包含所有类别）**',
  defaultEmptyBioTemplate: '** 空白生物数据模板（不包含任何类别） **',
  selectNormForReport: '选择报告的标准',
  selectIdealProfileForReport: '请选择理想的配置文件',
  visibleNormForReport: '使用标准生成报告',
  hideNormForReport: '隐藏标准生成报告',
  showNormTitle: '在报告请求列表中显示',
  showConfirmationMessageTitle: '您确定要显示',
  hideNormTitle: '在报告请求列表中隐藏',
  hideConfirmationMessageTitle: '您确定要移除',
  showhideConfirmationMessageDescription1: '选定的标准用于生成报告吗？',
  showhideConfirmationMessageDescription2: '此标准用于生成报告吗？',
  visible: '可见',
  normsVisibilityToast: '标准的可见性已更新',
  updateGroupToast: '群组已更新',
  updateGroupErrorToast: '更新群组时出错',
  'Edit Group Details': '编辑群组详细信息',
  'Duplicate Group': '复制群组',
  notificationNormCreated: '规范 "%{name}" 已创建',
  invitesSentToast: '邀请信已发送',
  missingRequestedNorms: '所选报告缺少规范和/或理想的配置文件',
  'You will be redirected shortly': '您将很快被重定向',
  oneRemainingNorm: '此测试至少需要保留一个规范可见',
  'Set reference as required': "将参考字段设置为必填",
  referenceAsRequired: "在受访者邀请中使用 'reference' 字段将有用信息传递到受访者记录中"
};
export default translations;
