import { array, func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { I18n } from 'react-redux-i18n';
import CustomButton from '../../reusable/Buttons/Button';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import NewModal from '../../reusable/NewModal';

import OldRespondentsStep, { findUniqueTests } from './OldRespondentsStep';
// eslint-disable-next-line import/no-cycle
import Spinner from '../../reusable/Spinner';
import ReportsList from './ReportsList';

import { requestReport } from '../../../store/reports/actions';
import {
  clearAvailableReports,
  fetchAssessmentsNorms,
  fetchAvailableReports,
} from '../../../store/respondents/actions';
import {
  selectAvailableIdealProfiles,
  selectAvailableNorms,
  selectAvailableReports,
} from '../../../store/respondents/selectors';
import { selectUserCredits } from '../../../store/user/selectors';

import storageService from '../../../services/storageService';
import createToastNotification from '../../../utils/createToastNotification';
import isChineseEnv from '../../../utils/isChineseEnv';

const saveSelectedNorms = (data) => {
  const uniqueNormIDs = Array.from(
    data
      .reduce((map, request) => {
        request.normIDs.forEach((norm) => {
          map.set(norm.testID, norm);
        });
        return map;
      }, new Map())
      .values(),
  );

  const previousRequestReports = storageService.getItem('usedNormsIDs') || null;
  const mostUsedNorms = previousRequestReports ? JSON.parse(previousRequestReports) : [];

  const combinedNorms = [...mostUsedNorms, ...uniqueNormIDs];
  const uniqueNorms = Array.from(new Map(combinedNorms.map((item) => [item.testID, item])).values());
  const uniqueReports = Array.from(uniqueNorms.values());

  storageService.setItem('usedNormsIDs', JSON.stringify(uniqueReports));
};

const findCommonTests = (respondents) => {
  if (!respondents.length) return [];
  const respondentsWithoutDuplicateTests = respondents.map((resp) => ({
    ...resp,
    tests: findUniqueTests(resp.doneTests).map((test) => test.testID),
  }));
  if (respondents.length === 1) return respondentsWithoutDuplicateTests[0].tests;
  const common = [];
  respondentsWithoutDuplicateTests.forEach((resp, i, arr) => {
    resp.tests.forEach((test) => {
      if (common.includes(test)) return;
      const arrWithoutCurrentResp = arr.filter((item) => item.respondentID !== resp.respondentID);
      const respWithSameTest = arrWithoutCurrentResp.filter((item) => item.tests.includes(test));
      if (respWithSameTest.length === arr.length - 1) common.push(test);
    });
  });
  return common;
};

export const modifyNorms = (norms) => {
  const previousRequestReports = storageService.getItem('usedNormsIDs');
  const getSelectedNorm = (normKey, previousNorm) => {
    if (!previousNorm) return {};
    return norms[normKey].filter((item) => item.normID === previousNorm.normID);
  };

  const result = {};
  Object.keys(norms).forEach((key) => {
    result[key] = {
      name: key,
      options: norms[key],
      // value: norms[key][0],
      // value: {},
      value: getSelectedNorm(
        key,
        previousRequestReports ? JSON.parse(previousRequestReports).find((item) => item.testID === key) : {},
      ),
    };
  });
  return result;
};

export const generateReportNorms = (report, norms) => {
  if (!norms) return [];

  // Retrieve previously stored norms from storage
  const previousRequestReports = storageService.getItem('usedNormsIDs');
  const previousReports = previousRequestReports ? JSON.parse(previousRequestReports) : [];

  // Split dependencies from report
  const dependencies = report.dependencies.split(';');

  // Function to resolve normID or handle missing values
  const resolveNormId = (normsObj) => {
    if (!normsObj) return -1;
    if (normsObj.value && normsObj.value.normID) return normsObj.value.normID;
    return normsObj.normID !== undefined ? normsObj.normID : undefined;
  };

  // Find a normID from `norms` or fallback to the stored previous reports
  return dependencies.map((testId) => {
    const normObj = norms[testId] ? norms[testId] : previousReports.find((item) => item.testID === testId);

    return {
      testID: testId,
      normID: resolveNormId(normObj),
    };
  });
};

export const getTestsWithoutNormSelected = (data) => {
  const result = new Set();

  data.forEach((item) => {
    item.normIDs.forEach((norm) => {
      if (norm.normID === undefined) {
        result.add(norm.testID);
      }
    });
  });

  return Array.from(result);
};

export const selectedTestsNeedsIdealProfile = (data) => {
  /** For idealProfileID key in reports
   * If it's "undefined" => The reports DOES need an Ideal Profile
   * If it's "null" => The reports DOES NOT need an Ideal Profile
   */
  const result = new Set();

  data.forEach((item) => {
    if (item.idealProfileID === undefined) {
      result.add(item.reportID);
    }
  });

  return Array.from(result);
};

export const parseSolutionNorms = (norms) => {
  const normsList = norms.split(';');
  return normsList.reduce((acc, item) => {
    // norm looks like ADAPTGV=>-1
    const splitNorm = item.split('=>');
    return [...acc, { testID: splitNorm[0], normID: Number(splitNorm[1]) }];
  }, []);
};

export const generateReportIdealProfile = (report, idealProfiles) => {
  let id = null;
  if (!report.ipDependencies) return id;
  const dependencies = report.ipDependencies.split(';');
  const resolveProfileId = (profileObj) => profileObj.idealProfileID || profileObj.value.idealProfileID;
  dependencies.forEach((testId) => {
    const profileObj = idealProfiles[testId];
    if (!profileObj) return;
    id = resolveProfileId(profileObj);
  });
  return id;
};

const RequestReportModal = ({ onClose, respondents, successCallback }) => {
  let userLang = storageService.getItem('psLang');
  if (userLang === 'ch') {
    userLang = 'ch-prc';
  }
  const languages = useSelector((state) => state.reports.availableLanguages);
  const reports = useSelector(selectAvailableReports);
  const norms = useSelector(selectAvailableNorms);
  const idealProfiles = useSelector(selectAvailableIdealProfiles);
  const credits = useSelector(selectUserCredits);
  // const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const [isSubmitting, setSubmittingState] = useState(false);
  const [normsState, changeNormsState] = useState({});
  useEffect(() => {
    changeNormsState(modifyNorms(norms));
  }, [norms]);
  const onNormChange = (name, value) => {
    changeNormsState((prev) => ({ ...prev, [name]: { ...prev[name], value } }));
  };

  const [idealProfilesState, changeIdealProfilesState] = useState({});
  useEffect(() => {
    changeIdealProfilesState(modifyNorms(idealProfiles));
  }, [idealProfiles]);

  const onIdealProfileChange = (name, value) => {
    changeIdealProfilesState((prev) => ({ ...prev, [name]: { ...prev[name], value } }));
  };

  const [language, setLanguage] = useState(languages.find((item) => item.code === userLang?.toUpperCase()));
  // const [language, setLanguage] = useState(findLanguage(languages, user && user.distributorDefaultLanguageID));
  const [confirmedRespondents, setConfirmedRespondents] = useState(
    respondents.map((item) => ({ ...item, isChecked: true })),
  );
  const [isConfirmationModalVisible, setConfirmationModalState] = useState(false);
  const [isLoading, setLoadingStatus] = useState(false);
  useEffect(() => {
    setConfirmedRespondents(respondents.map((item) => ({ ...item, isChecked: true })));
  }, [respondents, respondents.length]);

  useEffect(() => {
    setLanguage(languages.find((item) => item.code === userLang.toUpperCase()));
  }, [languages.length]);

  const isMultipleRespondents = respondents.length > 1;
  const [step, setStep] = useState(isMultipleRespondents ? 0 : 1);

  useEffect(() => {
    setStep(respondents.length > 1 ? 0 : 1);
  }, [respondents.length]);
  useEffect(() => {
    const confirmedArr = confirmedRespondents.filter((resp) => resp.isChecked);
    if (!confirmedArr.length || step !== 1) return;
    const commonTests = findCommonTests(confirmedArr);
    const respondentsIds = confirmedArr.map((item) => item.respondentID);
    setLoadingStatus(true);
    dispatch(
      fetchAvailableReports(commonTests, language, respondentsIds, () => {
        setLoadingStatus(false);
      }),
    );
    dispatch(fetchAssessmentsNorms(commonTests));
  }, [language, step]);

  const onConfirmedRespondentsChange = (irrelevant, newRespondents) => {
    setConfirmedRespondents(newRespondents);
  };
  const [tempCheckedReports, setTempCheckedReports] = useState([]);
  const onConfirmationClose = () => {
    setConfirmationModalState(false);
  };
  const openConfirmationModal = (reports) => {
    setConfirmationModalState(true);
    setTempCheckedReports(reports.filter((item) => item.isChecked));
  };
  const onModalClose = () => {
    onClose();
    onConfirmationClose();
    dispatch(clearAvailableReports());
    setTempCheckedReports([]);
    setStep(respondents.length > 1 ? 0 : 1);
    setLanguage(languages.find((item) => item.code === userLang.toUpperCase()));
  };

  const onSubmit = () => {
    const respondents = confirmedRespondents.filter((resp) => resp.isChecked);
    const individualReports = tempCheckedReports.reduce((acc, item) => {
      const items = item.reports
        .filter((item) => item.isChecked)
        .map((reportItem) => ({
          reportID: reportItem.reportID,
          reportSetID: item.reportSetID,
          normIDs: reportItem.isSolutionReport
            ? parseSolutionNorms(reportItem.solutionNorms)
            : generateReportNorms(reportItem, normsState),
          idealProfileID: generateReportIdealProfile(reportItem, idealProfilesState),
          reportType: 0,
        }));
      return [...acc, ...items];
    }, []);
    const data = {
      respondentIDs: respondents.map((item) => item.respondentID),
      reportRequests: individualReports,
    };
    const missingNormPerReport = getTestsWithoutNormSelected(data.reportRequests);
    const missingIdealProfilePerReport = selectedTestsNeedsIdealProfile(data.reportRequests);

    if (missingNormPerReport.length > 0 || missingIdealProfilePerReport.length > 0) {
      createToastNotification({ message: I18n.t('missingRequestedNorms'), type: 'error' });
      onConfirmationClose();
    } else {
      setSubmittingState(true);
      // dispatch(setSubmittingState(false), saveSelectedNorms(data.reportRequests), onModalClose());
      dispatch(
        requestReport(data, () => {
          if (successCallback) {
            successCallback();
            setSubmittingState(false);
          }
          saveSelectedNorms(data.reportRequests);
          onModalClose();
        }),
      );
    }
  };

  const title =
    respondents.length > 1
      ? I18n.t('Request report')
      : `${I18n.t('Request report for')} ${respondents.length && isChineseEnv
        ? `${respondents[0].familyName || ''}${respondents[0].firstName}`
        : `${respondents[0].firstName} ${respondents[0].familyName}`
      }`;

  const checkedRespondents = confirmedRespondents.filter((resp) => resp.isChecked);
  const commonTests = findCommonTests(checkedRespondents);
  const areSelectedRespondentsInvalid =
    !checkedRespondents.length || checkedRespondents.find((item) => !item.doneTests.length) || !commonTests.length;

  // Info for confirmation modal
  const confirmDescSets = tempCheckedReports
    .filter((item) => item.type === 'set')
    .map((item) => item.name)
    .join(',')
    .toUpperCase();
  const confirmDescResps = `${confirmedRespondents.filter((resp) => resp.isChecked).length} ${I18n.t('respondent(s)')}`;
  const confirmDescription = `${I18n.t('Request')} ${confirmDescSets} ${I18n.t('for')} ${confirmDescResps}`;
  return (
    <StyledModal isVisible onClose={onModalClose} title={title}>
      <Spinner isLoading={isLoading} />
      <ConfirmationModal
        isVisible={isConfirmationModalVisible}
        onClose={onConfirmationClose}
        description={confirmDescription}
        onConfirm={onSubmit}
        title={I18n.t('Request Reports')}
        isConfirmDisabled={isSubmitting}
      />
      <Container>
        {step === 0 ? (
          <>
            <StyledRespondentsStep
              respondents={confirmedRespondents}
              onValueChange={onConfirmedRespondentsChange}
              tableTitle={I18n.t('Selected respondents')}
              secondColumn={I18n.t('tests')}
            />
            <Footer>
              <StyledButton variant="secondary" handler={onClose}>
                {I18n.t('Cancel')}
              </StyledButton>
              <CommonTests>
                <Column>
                  <span>{I18n.t('Common tests: ')}</span>
                  {/* <span>{commonTests.join(',')}</span> */}
                  <StyledCommonTest>
                    {commonTests.map((item, i) => (
                      <>
                        <span key={item}>{item}</span>
                        {i === commonTests.length - 1 ? '' : ','}
                      </>
                    ))}
                  </StyledCommonTest>
                </Column>
                {areSelectedRespondentsInvalid && <Warning>{I18n.t('At least 1 assessment must be common')}</Warning>}
              </CommonTests>
              <StyledButton
                variant="primary"
                handler={() => setStep(1)}
                disabled={Boolean(areSelectedRespondentsInvalid)}
              >
                {I18n.t('Next')}
              </StyledButton>
            </Footer>
          </>
        ) : (
          <ReportsList
            toPrevStep={() => setStep(0)}
            multiStep={respondents.length > 1}
            languages={languages}
            language={language}
            setLanguage={setLanguage}
            reports={reports}
            norms={normsState}
            isLoading={isLoading}
            idealProfiles={idealProfilesState}
            commonTests={commonTests}
            onSubmit={(reports) => openConfirmationModal(reports)}
            onNormChange={onNormChange}
            onIdealProfileChange={onIdealProfileChange}
            credits={credits}
            respondents={checkedRespondents}
          />
        )}
      </Container>
    </StyledModal>
  );
};

RequestReportModal.propTypes = {
  onClose: func.isRequired,
  respondents: array.isRequired,
  successCallback: func,
};

RequestReportModal.defaultProps = {
  successCallback: null,
};

const StyledModal = styled(NewModal)`
  width: 74rem;
  height: 100%;
  max-height: 70rem;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const StyledRespondentsStep = styled(OldRespondentsStep)`
  max-height: 45rem;
  height: 100%;
  overflow: auto;
  margin-bottom: 1.5rem;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CommonTests = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

const StyledButton = styled(CustomButton)`
  min-width: 12rem;
  text-transform: uppercase;
`;

const Warning = styled.span`
  color: ${(props) => props.theme.colors.red};
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  text-align: center;
`;

const StyledCommonTest = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export default RequestReportModal;
