import React, { useEffect, useMemo, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { dateValueFormatter, dateWithoutTimeComparator, currentLocaleText } from '../../../lib/ag-grid';
import RatersTableWrapper from '../components/RatersTableWrapper';
import apiInstance2 from '../../../services/apiService';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import reportsBulkActions, { findReportFormat } from '../../../table/bulkActions/reports';
import * as actions from '../../../table/actionsList';
import useModals from '../../../hooks/useModals';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import { deleteReports, markReportsAsViewed } from '../../../store/reports/actions';
import downloadZippedArchiveFromReports from '../../Reports/reports.utils';
import { ReactComponent as ProgressIcon } from '../../../assets/icons/progress.svg';
import { ReactComponent as UnreadIcon } from '../../../assets/icons/unread.svg';
import { ReactComponent as CompletedIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import { formatIconsMap } from '../../../table/columns/reports';

const statusesMap = [I18n.t('Pending'), I18n.t('Unread'), I18n.t('Viewed'), I18n.t('Error')];
const iconsMap = [ProgressIcon, UnreadIcon, CompletedIcon, WarningIcon];

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
   100% {
     transform: rotate(360deg);
   }
`;

const animation = () =>
  css`
    ${rotate} 2s infinite linear;
  `;

const styleIcon = (Icon, status) => styled(Icon)`
  margin-right: 10px;
  width: 18px;
  height: 18px;
  animation: ${() => (status === 1 ? animation : 'none')};

  .a {
    ${() =>
      status === 4 &&
      css`
        fill: ${(props) => props.theme.colors.red};
        stroke: ${(props) => props.theme.colors.red};
        stroke-width: 0.3px;
      `}
  }
`;

const StatusCell = ({ status, error }) => {
  const finalizedStatus = error ? 4 : Number(status);
  const Icon = styleIcon(iconsMap[finalizedStatus - 1], finalizedStatus);

  return (
    <StatusContainer>
      <Icon />
      <StatusLabel error={error}>
        {finalizedStatus === 4 ? I18n.t('Error. Support ticket raised.') : statusesMap[finalizedStatus - 1]}
      </StatusLabel>
    </StatusContainer>
  );
};

const RatersReports = ({ projectId, sessionId }) => {
  const dispatch = useDispatch();
  const [reports, setReports] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [selectedReports, setSelectedReports] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiInstance2
      .get(`/api/v2/360/projects/${projectId}/sessions/${sessionId}/reports`)
      .then((response) => {
        setLoading(false);
        setReports(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedReports });

  const modals = [actions.DELETE_REPORT];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const onDownloadClick = (reports) => {
    const items = selectedReports.length ? selectedReports : reports;
    if (!items.length) return;
    if (items.length > 1) {
      downloadZippedArchiveFromReports(
        items.filter((item) => item.reportType !== 1),
        () => dispatch(markReportsAsViewed(items.map((item) => item.notificationID))),
      );
    } else {
      items.forEach((report) => {
        const link = document.createElement('a');
        link.href = report.downloadLink;
        link.download = report.downloadLink.substr(report.downloadLink.lastIndexOf('/') + 1);
        link.click();
      });
      dispatch(markReportsAsViewed(items.map((item) => item.notificationID)));
    }
  };

  const openMultipleReports = (reports) => {
    const items = selectedReports.length ? selectedReports : reports;
    if (!items.length) return;
    items.forEach((report) => {
      window.open(report.downloadLink, '_blank');
    });
  };

  const onDeleteConfirm = () => {
    const data = selectedReports.map((report) => ({
      reportType: report.reportType,
      notificationID: report.notificationID || null,
      requestID: report.reportRequestID || null,
    }));
    dispatch(
      deleteReports(data, () => {
        closeModal(actions.DELETE_REPORT);
        setReports((prev) => prev.filter((report) => !data.find((item) => item.requestID === report.reportRequestID)));
      }),
    );
  };
  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.OPEN_REPORT]: openMultipleReports,
    [actions.DOWNLOAD_REPORT]: onDownloadClick,
    [actions.DELETE_REPORT]: () => openModal(actions.DELETE_REPORT),
  };
  const bulkActions = useMemo(() => {
    return reportsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedReports) : item.isDisabled,
    }));
  }, [selectedReports]);

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      setSelectedReports(selectedData);
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      field: 'questionnaire',
      headerName: I18n.t('Name'),
      celldatatype: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      cellRenderer: ({ data }) => {
        const Icon = formatIconsMap[findReportFormat(data)];
        return (
          <Row>
            {data.downloadLink && <Icon />}
            <span>{data.questionnaire}</span>
          </Row>
        );
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'reportType',
      headerName: I18n.t('Status'),
      celldatatype: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      cellRenderer: ({ data }) => {
        return <StatusCell status={data.reportType} error={data.error} />;
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'creationDate',
      headerName: I18n.t('Creation Date'),
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      sort: 'desc',
      valueGetter: (params) => {
        return new Date(params.data.creationDate);
      },
      valueFormatter: dateValueFormatter,
      filterParams: {
        maxNumConditions: 1,
        comparator: dateWithoutTimeComparator,
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  function getRowClass(params) {
    if (params.data.reportType === 2) {
      return 'row-unread';
    }
    return null;
  }

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  return (
    <RatersTableWrapper projectId={projectId} sessionId={sessionId} activeTab={3}>
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : reports}
          rowSelection={rowSelection}
          onFilterChanged={handleFilterChanged}
          getRowClass={getRowClass}
          localeText={currentLocaleText}
        />
      </div>
      <ConfirmationModal
        title={I18n.t('Report deletion')}
        onClose={() => closeModal(actions.DELETE_REPORT)}
        isVisible={modalsState[actions.DELETE_REPORT]}
        description={I18n.t('Are you sure you want to delete selected report?')}
        onConfirm={onDeleteConfirm}
        caution
      />
    </RatersTableWrapper>
  );
};

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StatusLabel = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (props.error ? 'red' : props.theme.colors.primary)};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export default RatersReports;
