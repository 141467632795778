import React, { useEffect, useState, useMemo } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { currentLocaleText } from '../../lib/ag-grid';
import PageWrapper from '../PageWrapper';
import TableTabs from '../reusable/TableTabs';
import SimplifiedTable from '../reusable/Tables/SimplifiedTable';
import BulkActionsSidebar from '../BulkActionsSidebar';
import ManageBatteryModal from './ManageBattery';
import ConfirmationModal from '../reusable/ConfirmationModal';

import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import useModals from '../../hooks/useModals';

import { fetchTestsAndLanguages, deleteBatteries } from '../../store/user/actions';
import { selectAssessments } from '../../store/user/selectors';

import assessmentsTableActions from '../../table/tableActions/assessments';
import assessmentsBulkActions from '../../table/bulkActions/assessments';
import * as actions from '../../table/actionsList';
import CustomBadge from '../../components/reusable/Badges/CustomBadge';
import { resolveAssessmentName } from '../../utils/assessmentsNames';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const resolveType = (item) => {
  if (item.batteryID && item.isSolution) return `${I18n.t('Solution')} (${item.uses.split(';').join(',')})`;
  if (item.batteryID && !item.isSolution) return `${I18n.t('Battery')} (${item.uses.split(';').join(',')})`;
  return I18n.t('Assessment');
};

const AssessmentsAndBatteries = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [isLoading, setLoadingStatus] = useState(true);
  const data = useSelector(selectAssessments);
  const [selectedAssessments, setSelectedAssessments] = useState([]);
  const allTests = useSelector((state) => state.user.tests);
  useEffect(() => {
    dispatch(fetchTestsAndLanguages(() => setLoadingStatus(false)));
  }, []);
  const tabs = [
    { name: 'assessments-and-batteries', label: I18n.t('Assessment and Batteries') },
    ...(user?.userType === 3 ? [] : [{ name: 'ideal-profiles', label: I18n.t('Ideal Profiles') }]),
    ...(user?.userType === 1 || user?.userType === 3 ? [] : [{ name: 'norms', label: I18n.t('Norms') }]),
  ];

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedAssessments });

  const modals = [actions.ADD_CUSTOM_BATTERY, actions.DELETE_CUSTOM_BATTERY, actions.EDIT_CUSTOM_BATTERY];

  const { modalsState, openModal, closeModal } = useModals(modals);

  const bulkActionsHandlers = {
    [actions.EDIT_CUSTOM_BATTERY]: () => openModal(actions.EDIT_CUSTOM_BATTERY),
    [actions.DELETE_CUSTOM_BATTERY]: () => openModal(actions.DELETE_CUSTOM_BATTERY),
  };

  const onBatteriesDelete = () => {
    dispatch(
      deleteBatteries(
        selectedAssessments.map((item) => item.batteryID),
        () => closeModal(actions.DELETE_CUSTOM_BATTERY),
      ),
    );
  };

  const bulkActions = useMemo(() => {
    return assessmentsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedAssessments) : item.isDisabled,
    }));
  }, [selectedAssessments]);

  const tableActions = assessmentsTableActions.map((item) => ({
    ...item,
    handler: () => {
      openModal(actions.ADD_CUSTOM_BATTERY);
    },
  }));

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      setSelectedAssessments(selectedData);
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      field: 'name',
      headerName: I18n.t('Name'),
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      sort: 'asc',
      cellRenderer: ({ data }) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{resolveAssessmentName(data)}</span>
            {data.batteryID && data.batteryID > 1000000 && <CustomBadge />}
          </div>
        );
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      headerName: I18n.t('Type'),
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      valueGetter: ({ data }) => {
        return resolveType(data);
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      headerName: I18n.t('Available languages'),
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      sortable: false,
      valueGetter: ({ data }) => {
        return data.langAvailability
          ? data.langAvailability.split(';').join(',')
          : data.availableLanguages?.join(',') || '';
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  return (
    <PageWrapper
      title={I18n.t('Assessment settings')}
      backButtonHandler={() => dispatch(push(`/settings`))}
      buttons={user?.userType !== 1 ? tableActions : []}
      style={{ overflowY: 'auto' }}
    >
      <BulkActionsSidebar actions={bulkActions} isOpen={isSidebarOpen} onClose={closeSidebar} />
      <TableTabs
        tabs={tabs}
        activeTab={tabs[0]}
        clickHandler={(tab) => {
          dispatch(push(tab.name));
        }}
      />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : data}
          rowSelection={rowSelection}
          onFilterChanged={handleFilterChanged}
          localeText={currentLocaleText}
        />
      </div>
      {modalsState[actions.ADD_CUSTOM_BATTERY] && (
        <ManageBatteryModal onClose={() => closeModal(actions.ADD_CUSTOM_BATTERY)} assessments={allTests} />
      )}
      {modalsState[actions.EDIT_CUSTOM_BATTERY] && (
        <ManageBatteryModal
          onClose={() => closeModal(actions.EDIT_CUSTOM_BATTERY)}
          assessments={allTests}
          battery={selectedAssessments.length ? selectedAssessments[0] : null}
        />
      )}

      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_CUSTOM_BATTERY]}
        title={selectedAssessments.length === 1 ? I18n.t('Delete battery') : I18n.t('Delete batteries')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedAssessments.length === 1 ? I18n.t('this battery?') : I18n.t('selected batteries?')
        }`}
        onClose={() => closeModal(actions.DELETE_CUSTOM_BATTERY)}
        onConfirm={onBatteriesDelete}
      />
    </PageWrapper>
  );
};

const Table = styled(SimplifiedTable)`
  //padding-bottom: 7rem;
`;

export default AssessmentsAndBatteries;
