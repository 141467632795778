import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { currentLocaleText } from '../../lib/ag-grid';
import RatersTableWrapper from './components/RatersTableWrapper';
import {
  deleteRaters,
  fetchRaters,
  setRatersFilterType,
  setRatersFilterValue,
  setSelectedRaters,
} from '../../store/raters/actions';
import useTableFilter from '../../hooks/useTableFilter';
import ratersColumns from '../../table/columns/raters';
import BulkActionsSidebar from '../BulkActionsSidebar';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import ratersBulkActions from '../../table/bulkActions/raters';
import * as actions from '../../table/actionsList';
import useModals from '../../hooks/useModals';
import ConfirmationModal from '../reusable/ConfirmationModal';
import SessionInvite from '../Sessions/SessionInvite';
import getRatersByStatus from '../../utils/getRatersByStatus';
import raterRoles from '../../constants/raterRoles';
import isChineseEnv from '../../utils/isChineseEnv';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const inviteStatusMap = {
  0: I18n.t('Not Invited'),
  1: I18n.t('Invited'),
};

const completeStatusMap = {
  0: I18n.t('Not completed'),
  1: I18n.t('Completed'),
};

const statusesStyles = {
  invited: {
    title: 'Invited',
    background: '#DFF6FF',
    color: '#008AC0',
  },
  completed: {
    title: 'Completed',
    background: '#ECF6EF',
    color: '#27AE60',
  },
  notInvited: {
    title: 'Not invited',
    background: '#F9F9F9',
    color: '#5B5B5B',
  },
  notCompleted: {
    title: 'Not completed',
    background: '#F9F9F9',
    color: '#5B5B5B',
  },
};

const StatusCell = ({ data, type }) => {
  const inviteStatuses = ['notInvited', 'invited'];
  const completeStatuses = ['notCompleted', 'completed'];

  const status = type === 'invite' ? inviteStatuses[data.inviteStatus] : completeStatuses[data.completeStatus];
  const styles = statusesStyles[status];

  return (
    <StyledStatus style={{ color: styles.color, backgroundColor: styles.background }}>
      {I18n.t(styles.title)}
    </StyledStatus>
  );
};

const Raters = ({ projectId, sessionId }) => {
  const dispatch = useDispatch();

  const [isLoading, setLoadingStatus] = useState(true);
  const [shouldResetPage, setShouldResetPageStatus] = useState(false);

  // Props from State
  const activeProjectInView = useSelector((state) => state.projects.activeProjectInView);
  const selectedRaters = useSelector((state) => state.raters.selectedRaters);
  const filter = useSelector((state) => state.raters.filter);
  const raters = useSelector((state) => state.raters.raters);
  const activeSessionInView = useSelector((state) => state.sessions.activeSessionInView);
  const sessionRaters = activeSessionInView ? [...activeSessionInView.raters, activeSessionInView.self] : [];
  const roleAliases = raterRoles(activeProjectInView);

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedRaters });

  // Modals
  const modals = [actions.DELETE_RATERS, actions.SEND_INVITE_TO_RATERS];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const fetchData = useCallback(({ tableData, shouldReset, showSpinner, tableCallback }) => {
    if (showSpinner) setLoadingStatus(true);
    dispatch(
      fetchRaters(projectId, sessionId, tableData, {
        callback: () => {
          setLoadingStatus(false);
          tableCallback && tableCallback();
        },
        shouldReset,
      }),
    );
  }, []);

  const columns = useMemo(() => {
    return ratersColumns(activeProjectInView);
  }, [activeProjectInView]);

  // Table filtering
  const setFilterType = (name, value) => dispatch(setRatersFilterType(name, value));
  const setFilterValue = (name, value) => dispatch(setRatersFilterValue(name, value));
  const { inputsWithHandlers, appliedFiltersWithColumnNames } = useTableFilter({
    filter,
    columns,
    setFilterType,
    setFilterValue,
  });

  // Dropdown Actions
  const [commandType, setCommandType] = useState(0);

  // Bulk Actions
  const bulkActions = useMemo(() => {
    return ratersBulkActions.map((item) => ({
      ...item,
      handler: () => {
        if (item.name === actions.SEND_INVITE_TO_RATERS) setCommandType(item.commandType);
        openModal(item.name);
      },
      isDisabled: item.isDisabled && item.isDisabled(selectedRaters, activeProjectInView),
    }));
  }, [selectedRaters, activeProjectInView]);

  // Actions
  const onDelete = () => {
    const ids = selectedRaters.map((item) => item.raterID);
    dispatch(
      deleteRaters(activeProjectInView.projectId, activeSessionInView.sessionId, ids, () => {
        closeModal(actions.DELETE_RATERS);
      }),
    );
  };

  // session raters filtered by selected command type
  const filteredSessionRaters = useMemo(() => {
    if (!activeSessionInView) return [];
    const { notCompleted, notInvited, invitedNotCompleted } = getRatersByStatus(sessionRaters);
    const byTypes = { 0: notCompleted, 1: notInvited, 2: invitedNotCompleted };
    return byTypes[commandType];
  }, [activeSessionInView, commandType, sessionRaters]);

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      dispatch(setSelectedRaters(selectedData));
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      headerName: I18n.t('PersonName'),
      celldatatype: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      valueGetter: ({ data }) =>
        isChineseEnv ? `${data.familyName}${data.firstName}` : `${data.firstName} ${data.familyName}`,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'email',
      headerName: I18n.t('Email Address'),
      celldatatype: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'role',
      headerName: I18n.t('Role'),
      celldatatype: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      valueGetter: ({ data }) => `${roleAliases[data.role].label}`,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'inviteStatus',
      headerName: I18n.t('Invite Status'),
      celldatatype: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      valueGetter: ({ data }) => inviteStatusMap[data.inviteStatus],
      cellRenderer: ({ data }) => <StatusCell data={data} type="invite" />,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'completeStatus',
      headerName: I18n.t('Complete Status'),
      celldatatype: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      valueGetter: ({ data }) => completeStatusMap[data.completeStatus],
      cellRenderer: ({ data }) => <StatusCell data={data} type="complete" />,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  useEffect(() => {
    fetchData({ shouldReset: true, showSpinner: true });
  }, []);

  return (
    <RatersTableWrapper
      projectId={projectId}
      sessionId={sessionId}
      activeTab={0}
      setShouldResetPageStatus={setShouldResetPageStatus}
    >
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : raters}
          rowSelection={rowSelection}
          onFilterChanged={handleFilterChanged}
          localeText={currentLocaleText}
        />
      </div>
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_RATERS]}
        onClose={() => closeModal(actions.DELETE_RATERS)}
        title={I18n.t('Delete raters')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedRaters.length > 1 ? I18n.t('selected raters?') : I18n.t('this rater?')
        }`}
        onConfirm={onDelete}
      />
      {modalsState[actions.SEND_INVITE_TO_RATERS] && (
        <SessionInvite
          onClose={() => closeModal(actions.SEND_INVITE_TO_RATERS)}
          itemType={selectedRaters.length ? 'rater' : 'session'}
          items={selectedRaters.length ? selectedRaters : filteredSessionRaters}
          commandType={commandType}
          successCallback={setShouldResetPageStatus ? () => setShouldResetPageStatus(true) : () => {}}
          sessionId={selectedRaters.length ? '' : activeSessionInView && activeSessionInView.sessionId}
        />
      )}
    </RatersTableWrapper>
  );
};

const StyledStatus = styled.div`
  justify-content: center;
  align-items: center;
  padding: 2px 14px;
  height: 14px;
  min-width: 11rem;
  display: inline;
  align-self: flex-start;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: 4px;
`;

export default Raters;
