const translations = {
  About: 'Информация',
  Launch: 'Запуск',
  Tutorial: 'Руководство',

  // Login Page (Login.js)
  Email: 'Электронная почта',
  Submit: 'Отправить',
  LoginSubmit: 'Войти',
  Password: 'Пароль',
  'Email is required': 'Необходимо ввести адрес электронной почты',
  'Password is required': 'Необходим пароль',
  'Should be a proper email address': 'Неверный адрес электронной почты',

  // (AddCompetencyCategory.js)
  Save: 'Сохранить',
  'Enter Name': 'Введите имя',
  'Add competency category': 'Добавить категорию компетенций',
  'Add description for the category': 'Добавить описание категории',

  // (CompetencyGroupNavbar.js)
  'Main info': 'ГЛАВНАЯ ИНФОРМАЦИЯ',

  // (AddCompetencyGroup.js)
  Add: 'Добавить',
  'Name is required': 'Необходимо указать имя',
  'Add 360 assessment': 'Добавить 360 опросник',
  'Custom competencies': 'Добавленные компетенции',
  'Inbuilt competencies': 'Стандартные компетенции',
  'Competency description': 'Описание компетенции',

  // (AddCompetencyItem.js)
  Edit: 'Редактировать',

  // (AddCompetencyQuestion.js)
  'Add question': 'Добавить вопрос',

  // (AddCompetencyQuestionForm.js)
  'Item text': 'Текст пункта',
  'Free field': 'Свободное поле',
  'Multiple choice': 'Выбор из нескольких вариантов',
  'Add another language': 'Добавить другой язык',

  // (AddCreditsFailureModal.js)
  'Credits addition error': 'Ошибка добавления кредитов',
  'Try again': 'Попробуйте еще раз',

  //  (AddCreditsSuccessModal.js)
  Done: 'Выполнено',
  'Adding credits': 'Добавление кредитов',
  'Credits successfully added!': 'Кредиты успешно добавлены!',
  'Now your credit balance is:': 'Ваш новый кредитный баланс:',

  // (AddCredits.js)
  Cancel: 'Отмена',
  'Add credits': 'Добавить кредиты',
  'Your credits balance': 'Кредитный баланс',
  'How many credits do you want to add?': 'Сколько кредитов вы хотите добавить?',
  'Purchase ID': 'Идентификатор покупки',
  'Error occurred during an attempt to add credits to your account':
    'Произошла ошибка при попытке добавить кредиты на ваш аккаунт',
  'Credits amount': 'Сумма кредитов',

  // Edit Respondent (Index.js)
  Groups: 'Группы',
  Invites: 'Приглашения',
  Reports: 'Отчеты',
  Assessments: 'Инструменты оценки',
  IAssessments: 'Инструменты оценки',
  SAssessments: 'Инструменты оценки',
  'Respondent Information': 'Информация о респонденте',

  // Edit Respondent (AddTagModal.js)
  'Add tags': 'Добавить теги',
  'Select tags': 'Выбрать теги или добавить новые',

  // Edit Respondent (Information.js)
  Sex: 'Пол',
  Title: 'Название',
  BioTitle: 'Должность',
  Sector: 'Сектор',
  sector: 'Сектор',
  Company: 'Компания',
  Industry: 'Сфера деятельности',
  industry: 'Сфера деятельности',
  Education: 'Образование',
  education: 'Образование',
  Reference: 'Дополнительная информация',
  Ethnicity: 'Этническая принадлежность',
  ethnicity: 'Этническая принадлежность',
  'Job area': 'Сфера работы',
  jobArea: 'Сфера работы',
  Remove: 'Удалить',
  'First Name': 'Имя',
  'Family Name': 'Фамилия',
  'Save changes': 'Сохранить изменения',
  'Date of Birth': 'Дата рождения',
  'First Language': 'Родной язык',
  'Additional Info': 'Дополнительная информация',
  'Main Information': 'Основная информация',

  // Edit Respondent (RespondentGroups.js)
  group: 'группа',
  'Remove from groups': 'Удалить из групп(-ы)',
  'Are you sure you want to remove respondent from selected group(s)':
    'Вы действительно хотите удалить респондента из выбранной группы?',

  // Edit Respondent (RespondentInvites.js)
  invite: 'приглашение',
  'Invite Deletion': 'Удалить приглашение',

  // Edit Respondent (RespondentReports.js)
  'Report deletion': 'Удалить отчет',
  'Are you sure you want to delete selected report?': 'Вы действительно хотите удалить выбранный отчет?',

  // Edit Respondent (RespondentTests.js)
  'Test deletion': 'Удаление теста',
  'Are you sure you want to delete selected test?': 'Вы уверены, что хотите удалить выбранный тест?',

  // Edit Respondent (TagsManagement.js)
  Tags: 'Теги',
  '+ Add tag': '+ Добавьте теги',

  // Groups (AddNewGroup.js)
  Create: 'Создать',
  'Group name': 'Название группы',
  'Add new group': 'Добавить группу',
  'Group description': 'Описание группы',

  // Groups (GroupHeader.js)
  Type: 'Тип',
  'Creation date': 'Дата создания',

  // Groups (Groups.js)
  List: 'Список',
  Respondents: 'Респонденты',
  'Delete group': 'Удалить группу',
  'Are you sure you want to delete this group(s)': 'Вы уверены, что хотите удалить эту группу (группы)',

  // Invites (Invites.js)
  'Delete invite': 'Удалить приглашение',
  'selected invites?': 'выбранные приглашения?',
  'this invite?': 'это приглашение?',

  // Groups request report
  'Select common assessment / battery': 'Выбрать общий опросник/батарею',
  reports: 'отчеты',

  // LaunchScreen (LaunchScreen.js)
  Language: 'Язык',
  language: 'Язык',

  // Notifications (Notifications.js)
  Results: 'Результаты',
  notification: 'уведомление',
  Notifications: 'Уведомления',

  // Projects (AddProjectsModal.js)
  Min: 'Мин.',
  Info: 'Информация',
  Raters: 'Респонденты',
  Deadline: 'Срок',
  'Peer Alias': 'Псевдоним коллеги',
  Description: 'Описание',
  'Project Name': 'Название проекта',
  'All standard': 'Все стандартные',
  'Edit Project': 'Редактировать проект',
  'Manager Alias': 'Псевдоним менеджера',
  'Project Alias': 'Псевдоним проекта',
  'Direct Report Alias': 'Псевдоним подчиненного',
  'Choose Project Type': 'Выбрать тип проекта',
  '360 assessment has to be fully supported in at least 1 language':
    'Оценка 360 должна полностью поддерживаться хотя бы на 1 языке',
  '360 Survey': '360 Опрос',
  '360 Surveys': '360 Опросы',
  'Other Alias': 'Другая роль',

  // Projects (projectList.js)
  'Add Assessment Project': 'Добавить проект оценки',
  'Add 360 Appraisal Project': 'Добавить проект оценки 360',
  'Manage your assessment projects here. Plan and implement the right assessment approach and tests. Effectively and consistently administrate, communicate and manage the whole assessment process using this user friendly tool.':
    'Здесь вы можете управлять своими проектами по оценке. Планируйте и внедряйте правильный подход к оценке и тестированию. Эффективно и последовательно коммуницируйте и управляйте всем процессом оценки с помощью этого удобного инструмента.',
  'Manage your entire 360 appraisal process here. Easy to use, allowing appraisals to be set up in minutes and results generated instantly. Choose from Psytech&apos;s existing competency framework or fully customise your 360 project.':
    'Управляйте всем процессом оценки 360. Простота использования позволяющая сформиривать процесс оценки 360 за считанные минуты и мгновенно получать результаты. Вы можете выбрать компетенции из существующей системы компетенций Psytech или полностью настроить свой проект 360.',

  // Projects (Projects.js)
  All: 'Все',
  Project: 'Проект',
  Projects: 'Проекты',
  'this project?': 'этот проект?',

  '360 Appraisal': 'Оценка 360',
  'Delete project': 'Удалить проект',
  'selected projects?': 'выбранные проекты?',
  'Are you sure you want to delete': 'Вы уверены, что хотите удалить',

  // Raters (Raters.js)
  rater: 'респондент',
  'Delete raters': 'Удалить респондентов',
  'Loading raters': 'Загрузка элементов',
  'selected raters?': 'выбранных респондентов?',
  'this rater?': 'этого респондентов?',
  Session: 'Сессия',
  'Session for': 'Сессия для',

  // Raters (AddRatersModal.js)
  Role: 'Роль',
  Other: 'Другое',
  Peers: 'Коллеги',
  Confirm: 'Подтвердить',
  Managers: 'Менеджеры',
  'No email': 'Нет электронной почты',
  'Add raters': 'Добавить респондентов',
  'Direct reports': 'Подчиненные',
  'Search existing records': 'Искать в имеющихся записях учета',
  'Make sure selected record has a valid email address added':
    'Убедитесь, что для выбранной записи добавлен действующий адрес электронной почты',

  // Raters Invites (columns.js)
  Name: 'Имя',
  PersonName: 'Имя',
  'Expiry Date': 'Срок действия',
  'Invite status': 'Статус приглашения',
  'Email Address': 'Адрес электронной почты',
  'Hide expired': 'Срок действия скрытой формы истек',

  // Raters Invites (Invites.js)
  'chosen invite(s)?': 'выбранное(-ые) приглашение(-я)?',
  'selected invite(s)?': 'отобранное(-ые) приглашение(-я)',
  'Loading invites': 'Загрузка приглашений',
  missingEmailError: 'адрес электронной почты отсутствует или недействителен в записи(-ях) респондентов',

  // Raters Reports (Reports.js)
  Pending: 'В ожидании',
  Unread: 'Не прочитано',
  Viewed: 'Просмотрено',
  Error: 'Ошибка',
  'Support ticket raised': 'Ошибка. Подана заявка в службу поддержки',
  'Loading reports': 'Загрузка отчетов',

  // Raters Results (Results.js)
  result: 'результат',

  // Reports Reports (Reports.js)
  'Are you sure you want to delete selected report(s)?': 'Вы уверены, что хотите удалить выбранные отчеты?',

  // RequestReport360 (RequestReport360.js)
  'Report Request for': 'Запрос отчета для',
  'Report Request': 'Запрос на отчет',
  credits: 'кредиты',
  Request: 'Запрос',
  Self: 'Сам',

  // Respondents AddRespondentModal (AddRespondentForm.js)
  'Company Name': 'Название компании',
  'Job Area': 'Вид деятельности',

  // Respondents AddRespondentModal (AddRespondentModal.js)
  'Add respondent': 'Добавить респондента',
  'Main Details': 'Основные данные',
  'Extra Details': 'Дополнительные данные',
  'Group Membership': 'Добавить в группу',

  // Respondents AddRespondentModal (GroupsTab.js)
  'Select groups': 'Выбрать из списка групп',

  // Respondents AdministerAssessment (index.js)
  Start: 'Начать',
  Administer: 'Администрировать',
  to: 'для',
  questionnaires: 'опросник',
  reasoning: 'тесты способностей',
  solutions: 'решения',
  batteries: 'батареи',
  favourites: 'избранные',
  other: 'другие',
  // Respondents (AnswersStep.js)
  'Test:': 'Тест:',

  // Respondents AnswerSheet (index.js)
  Previous: 'Предыдущий',
  'Administer answer sheet for': 'Заполнить ответы с бланка',
  answers: 'ответы',
  Respondent: 'Респондент',

  // Respondents (AssessmentStep.js)
  'Welcome text': 'Вступительный текст',
  'Expiry date': 'Срок действия',

  // Invite (index.js)
  'Invite respondents': 'Пригласить респондентов',
  Invite: 'Пригласить ',
  'Create invite and go next': 'Создать приглашение',
  Finish: 'Закончить',
  'You are sending an invitation for:': 'Вы отправляете приглашение:',
  in: 'в',
  invitation: 'приглашения',
  OR: 'ИЛИ',

  // Invite (InvitationStep.js)
  Immediately: 'Немедленно',
  Later: 'Позже',
  'Email address is missing or not valid in respondent record':
    'Адрес электронной почты отсутствует или недействителен в записи респондента',
  'Copy link': 'Копировать ссылку',
  Copied: 'Скопировано',
  'Copy and share generated link': 'Скопировать и поделиться сгенерированной ссылкой',
  'Send invitation link via email to:': 'Отправить ссылку-приглашение по электронной почте на адрес:',
  'Email template': 'Шаблон письма',
  'When do you want to send an invite?': 'Когда вы хотите отправить приглашение?',
  'Email invite': 'Отправить приглашение по электронной почте',
  'Email sent': 'Письмо отправлено',

  // RequestReportModal (index.js)
  'Request report': 'Запросить отчет(-ы)',
  'Request report for': 'Запросить отчет(-ы) для',
  for: 'для',
  'Request Reports': 'Запросить отчеты',
  'Common tests: ': 'Общие тесты',
  'At least 1 assessment must be common': 'По крайней мере 1 инструмент оценки должен быть общим',
  'Selected respondents': 'Выбранные респонденты',
  tests: 'Тесты',
  'Assessment/Battery': 'Опросник/Батарея',
  'Total credits': 'Общая сумма кредитов',

  // RequestReportModal (ReportsList.js)
  'Available Reports': 'Доступные отчеты',
  Norm: 'Нормативная группа',
  Options: 'Опции',
  'Ideal Profile': 'Идеальный профиль',

  // Respondents (AddToGroup.js)
  'Add to group': 'Добавить в группу',
  'Add respondent(s) to group': 'Добавить респондента(-ов) в группу',

  // Respondents (ManageTagsModal.js)
  'Manage respondents tags': 'Обновить теги',
  'Select from existing tags or add new': 'Выбрать из имеющихся тегов или добавить новый',
  'Add tag to selected respondent(s)': 'Добавить нового респондента',
  'Remove tag from selected respondent(s)': 'Удалить тег для выделенных респондентов',

  // Respondents  (RespondentDropdown.js)
  'Add new respondent': 'Добавить респондента',
  'Import respondents from file': 'Импортировать респондентов',
  'Edit respondent': 'Редактировать',
  'Delete respondent': 'Удалить респондента',
  'All respondents are selected': 'Все респонденты выбраны (%{respondentsCount})',
  'Clear selection': 'Очистить выбор',
  'All respondents on this page are selected': 'Все респонденты на этой странице выбраны (%{respondentsCount})',
  'Select all respondents in this result': 'Выбрать всех (%{respondentsCount}) респондентов за этими критериеми',

  // Respondents  (Respondents.js)
  'Delete respondents from group': 'Удалить респондентов из группы',
  'Loading respondents': 'Загрузка респондентов',
  'Are you sure you want to remove': 'Вы уверены, что хотите удалить',
  'from the group?': 'из группы?',

  // Reusable Accordion (Accordion.js)
  INBUILT: 'Встроенные',

  // Reusable Accordion (CompetencyGroupsAccordion.js)
  'Collapse all': 'Свернуть все',
  'Expand all': 'Развернуть все',

  // Reusable  Filter (DateInputGroup.js)
  'Date range': 'Временной промежуток',

  // Reusable  Filter (Filter.js)
  Filter: 'Фильтр',
  'Clear all': 'Очистить все',
  Apply: 'Применить',

  // Reusable Filter (TextInputGroup.js)
  'Filter type': 'Тип фильтра',

  // Reusable Filter (Search.js)
  Search: 'Поиск',

  // Reusable Badges (CustomBadge.js)
  CUSTOM: 'Добавленная вручную',

  // Reusable TextEditor (Editor.js)
  'Insert variable': 'Вставить переменную',

  // Reusable  (ConfirmationModal.js)
  No: 'Нет',
  Yes: 'Да',

  // Reusable  (DatePicker.js)
  'Select the date': 'Выберите дату',

  // Reusable  (DateTimeSelect.js)
  'Time:': 'Время:',

  // Reusable  (ErrorMessage.js)
  'Oops, something went wrong.': 'Упс, что-то пошло не так.',
  'Please try again.': 'Пожалуйста, попробуйте еще раз',
  Ok: 'Хорошо',

  // Reusable (Quantity.js)
  Quantity: 'Количество',

  // Reusable (StatusCell.js)
  'In Progress': 'В процессе заполнения',
  'On Hold': 'В ожидании',
  Canceled: 'Отменено',
  Completed: 'Завершено',

  // Session SessionInvite (index.js)
  'Send invite to raters': 'Отправить приглашение',
  'Send invite to raters who have not yet completed': 'Отправить приглашение всем, кто не дал оценку',
  'Send invite to raters who have not yet been invited': 'Отправить приглашение неприглашенным',
  'Send invite to raters who have already been invited but haven&apos;t yet completed': 'Отправить напоминание',
  'Send invite to self with a request to specify their own raters': 'Пригласить указать респондентов',
  'Welcome text for Self Raters': 'Вступительный текст для репондента',
  'Email template for Self Raters': 'Шаблон письма для респондента',
  'Welcome text for Other Raters': 'Вступительный текст для респондентов',
  'Email template for Other Raters': 'Шаблон письма для респондентов',
  Send: 'Направить',

  // Session SessionInvite (SessionInvitationStep.js)
  'Email body': 'Текст письма',
  'Email subject': 'Тема письма',
  'Invitation date': 'Дата приглашения',
  'Email body for Self Raters': 'Шаблон письма для респондента',
  'Email body for Other Raters': 'Шаблон письма для респондентов',
  'Email subject for Other Raters': 'Тема письма для респондентов',
  'Email subject for Self Raters': 'Тема письма для респондента',

  // Session (AddSessionModal.js)
  'Sorry, session with selected respondent already exists.':
    'Извините, сессия с выбранным респондентом уже существует.',
  'Add session': 'Добавить сессию',
  'Session SELF': 'Сессия Сам',
  'Search existing record to select session SELF': 'Поиск существующей записи для выбора сессии Сам',

  // Session (SessionHeader.js)
  'All Standard': 'Все стандартные',
  '360 Assessment:': 'Оценка 360 градусов',
  'Type:': 'Тип:',
  'Status:': 'Статус:',
  'Deadline:': 'Время окончания:',

  // Session (Sessions.js)
  Sessions: 'Сессии',
  session: 'сессия',
  'Loading sessions': 'Загрузка сессий',
  'Delete session': 'Удалить',
  'Delete sessions': 'Удалить',
  'this session?': 'эту сессию?',
  'Are you sure you want to delete this project': 'Вы уверены, что хотите удалить это проект',
  'Sessions list': 'Список сессий',

  // Session header (SessionHeader.js)
  'days left': 'дней осталось',
  'Send invites': 'Отправить приглашения',
  'Outstanding ratings': 'Ожидание оценок',
  'Send reminder': 'Отправить напоминание',
  'Sessions completed': 'Сесий завершено',
  'Send invite': 'Отправить приглашение',

  // Settings AddResponseScale (AddResponseScale.js)
  'Loading...': 'Загрузка…',

  // Settings (AddScaleForm.js)
  'Add response scale': 'Добавить шкалу ответов',
  'Edit response scale': 'Редактировать шкалу ответов',

  // Settings Components   (EditTitle.js)
  'Edit Name': 'Редактировать имя',
  'Edit 360 assessment': 'Редактировать 360 опросник',

  // Settings Components  (QuestionItem.js)
  Question: 'Вопрос',
  'No translation available for': 'Нет в наличии перевода для',

  // Settings Components  (ReviewCompetencies.js)
  'Loading competencies': 'Загрузка компетенций',
  Competencies: 'Компетенции',
  CONFIRM: 'Подтвердить',

  // Settings utils (ResponseScale.util.js)
  'Name is the required field': 'Имя является обязательным полем',
  'All response scales should be filled': 'Все шкалы ответов должны быть заполнены',

  // Settings (CustomCompetencies.js)
  'Are you sure you want to remove selected': 'Вы уверены, что хотите удалить выбранный',
  Collapse: 'Свернуть',
  Expand: 'Развернуть',
  '360 settings': 'Настройки 360 проектов',

  // Settings (Settings.js)
  Settings: 'Настройки ',

  // 360 surveys
  'Add new 360 survey': 'Добавить новый 360 опросник',
  'Add 360 survey': 'Добавить 360 опросник',
  'Edit 360 survey': 'Редактировать 360 опросник',
  'Duplicate 360 survey': 'Копировать 360 опросник',
  'Delete selected 360 survey(s)': 'Удалить выбраные 360 опросники',
  Sorting: 'Сортировка',

  // (BulkActionsSidebar.js)
  'Bulk actions': 'Основные действия',

  // Sidebar.js
  'Are you sure you want to log out?': 'Вы уверены, что хотите выйти из системы?',
  'Credits balance:': 'Кредитный баланс:',

  // Settings  (Settings.const.js)
  'Assessments and batteries': 'Инструменты оценки и батареи ',
  'Ideal Profiles': 'Идеальные профили',
  Norms: 'Нормативные группы',
  '360 Assessments': '360 опросник',
  'Response Scales': 'Шкалы ответов',
  Templates: 'Шаблоны',
  'Email templates': 'Шаблоны электроного письма',
  Account: 'Аккаунт',
  'General info': 'Общая информация',
  'Credits History': 'История использования кредитов',
  'Reports Usage': 'Использование отчетов',
  'Assessments Stats': 'Использование инструментов оценки',

  // Settings (AssessmentsAndBatteries.js)
  assessment: 'инструмент оценки',
  'this battery?': 'эта батарея?',
  'Delete battery': 'Удалить',
  'Delete batteries': 'Удалить',
  'Assessment settings': 'Настройка опросника',
  'selected batteries?': 'выбранные батареи',
  // Settings (AddNormMode.js)
  'Add New Norm': 'Добавить новую нормативную группу',
  'Norm name': 'Название нормативной группы',
  'Assessment on which the norm profile is based': 'Инструмент оценки данной нормативной группы',
  Frequency: 'Частота',
  SD: 'Стандартное отклонение',
  'Sample Size': 'Размер выборки',
  'Sample size must be greater than or equal to 1': 'Размер выборки должен быть больше или равен 1',
  'Select group': 'Выбрать группу',
  'Assessment on which the norm is based': 'Инструмент оценки данной нормативной группы',

  // Settings (CompetencyGroups.js)
  'Copy of': 'Копия',
  'Competency Group': 'Группа компетенций',
  'Delete assessment': 'Удалить опросник',
  'selected assessments': 'выбранные опросники',
  'this assessment': 'этот опросник',
  'Duplicate assessment': 'Создать копию',
  'Are you sure you want to duplicate': 'Вы уверены, что хотите создать копию',

  // Settings (CompetencyGroupsDetails.js)
  Structure: 'Структура',
  'Response scale': 'Шкала ответов',
  'Loading Competency Details': 'Загрузка данных о компетенциях',
  'Categories:': 'Категории:',
  'Competencies:': 'Компетенции:',
  'Numbers of items': 'Количество вопросов',
  'Multiple choice:': 'Несколько ответов:',
  'Free text:': 'Произвольный текст:',
  'Estimated time': 'Предполагаемое время',
  minutes: 'минут',
  'Available languages': 'Доступные языки',
  'Make sure at least one language is fully supported in assessment':
    'Убедитесь, что по крайней мере один язык полностью поддерживается в процессе оценки',
  Questions: 'Вопросы',
  'Competency deletion': 'Удаление компетенции',
  'competencies?': 'компетенции?',
  'competency?': 'компетенция?',
  'Assessment deletion': 'Удаление опросника',
  'Are you sure you want to deleted selected the assessment?': 'Вы уверены, что хотите удалить выбранный опросник',
  // Settings Templates (WelcomeText.js)
  'Welcome texts': 'Вступительные тексты',
  'selected welcome texts?': 'выбранные вступительные тексты',
  'this welcome text?': 'этот вступительный текст',

  // Settings Templates (WelcomeTextScreen.js)
  'Add new welcome text': 'Добавить вступительный текст',
  'Template name': 'Название шаблона',
  'welcome text': 'вступительный текст',
  'Delete welcome texts': 'Удалить',

  // Settings Templates (templatesTabs.js)
  'Assessment welcome texts': 'Вступительные тексты',
  template: 'шаблон',
  'Delete templates': 'Удалить',
  'selected templates?': 'выбранные шаблоны?',
  'this template?': 'этот шаблон?',

  // Settings Templates (TemplatesScreen.js)
  'Field is required': 'Обязательное поле для заполнения',
  'Template subject': 'Тема шаблона',

  // Settings ResponseScales (ResponseScales.js)
  'Response Scale': 'Шкала ответов',
  'Delete scale(s)': 'Удалить',
  'Are you sure you want to delete this scale(s)?': 'Вы уверены, что хотите удалить эту(-и) шкалу(-ы)?',
  Responses: 'Ответы',
  Languages: 'Языки',
  'Score right to left': 'Оценка справа налево',
  indexedResponse: 'ответ %{index}',

  // Settings ResponseScales (bulkActions.js)
  'Delete selected response scale(s)': 'Удалить',
  // Settings Norms (NormsHeader.js)
  'Norm Type:': 'Тип нормативной группы:',
  'Creation Date:': 'Дата создания:',

  // Settings Norms (NormsScales.js)
  'Norm Table': 'Таблица нормативной группы',
  'Frequency Histograms': 'Гистограммы частотности',
  'Bio Data': 'Биографические данные',
  Stenine: 'Стенайн',
  Stentable: 'Стенайн таблица',

  // Settings Norms (Norms.js)
  'Add new norm': 'Добавить нормативную группу',
  'Delete norm(s)': 'Удалить',
  'Assessment and Batteries': 'Инструмент оценки и батареи',
  'selected norms?': 'выбранные нормативные группы?',
  'this norm?': 'эта нормативная группа?',
  'Creation Date': 'Дата создания',

  // Settings Norms (HistogramView.js)
  FREQUENCY: 'ЧАСТОТА',
  'RAW SCORE': 'СЫРЫЕ БАЛЛЫ',
  'Raw Score': 'Сырые баллы',

  // Settings ManageBattery (index.js)
  'Battery name': 'Название батареи',
  'Search existing assessments': 'Доступные опросники',
  'Add battery': 'Добавьте батарею',

  // Settings IdealProfile (IdealProfileScales.js)
  'Ideal profile type:': 'Тип идеального профиля:',
  'Unsaved data': 'Несохраненные данные',
  'The current ideal profile has not been saved. Would you like to save it?': 'Желаете сохранить внесенные изменения?',

  addIdealProfileGroups: 'Групи',

  // Sidebar.js
  Logout: 'Выход из системы',

  // Settings IdealProfile (IdealProfile.js)
  'Delete ideal profiles': 'Удалить',
  'selected ideal profiles?': 'выбранные идеальные профили?',
  'this ideal profile?': 'этот идеальный профиль?',

  // Settings AddIdealProfile.js
  'Add ideal profile': 'Добавить идеальный профиль',
  'Ideal profile name': 'Название идеального профиля',
  'I will manually select the values': 'Я выберу значения вручную',
  'I will use respondent data to determine the values': 'На основе данных респондентов',
  Test: 'Тест',
  // (advancedFilterOptions.js)
  'Is equal to': 'Равен',
  'Is not equal to': 'Не равен',
  'Starts with': 'Начинается с',
  Contains: 'Содержит',
  'Does not contain': 'Не содержит',
  'Ends with': 'Заканчивается на',
  'Lesser than': 'Менее чем',
  'Greater than': 'Более чем',
  '1 day': '1 день',
  '3 days': '3 дня',
  '1 week': '1 неделя',
  '2 weeks': '2 недели',
  '1 month': '1 месяц',
  '1 year': '1 год',

  // groupsTypes.js
  Unsupervised: 'Дистанционная',
  Supervised: 'Администрированная',
  // idealProfileTypes.js
  'Manually configured': 'Сформированый вручную',
  'Based on respondent data': 'Основывается на данных респондента',

  // inviteCreationCategories.js
  'Personality tests': 'Личностные опросники',
  'Interests Values Attitudes': 'Интересы Ценности Отношения',
  Reasoning: 'Тесты способностей',
  Value: 'Значения',
  'Preferences/Styles': 'Предпочтения/Стили',
  'Custom battery': 'Кастомизированная батарея',
  'General Solutions': 'Общие решения',
  'Screening Solutions': 'Скрининговые решения',
  Solution: 'Решение',
  Battery: 'Батарея',
  Assessment: 'Опросник',
  'Every 4 days': 'Каждые 4 дня',
  'Every 3 days': 'Каждые 3 дня',
  'Every 2 days': 'Каждые 2 дня',
  'Every day': 'Каждый день',
  "Don't send": 'Не посылайте',
  Immediate: 'Немедленно',

  // inviteStatuses.js
  'Not sent': 'Не отправлено',
  Scheduled: 'Запланировано',
  Sent: 'Отправлено',
  Failed: 'Ошибка отправки',

  // raterRoles.js
  Manager: 'Менеджер',
  Peer: 'Коллега',
  'Direct report': 'Подчиненный',

  // respondentSelectOptions.js
  'No Qualification': 'Квалификация отсутствует',
  'Secondary School': 'Средняя школа',
  'Industry or Trade Training': 'Обучение в сфере производства или торговли',
  'Professional Qualification': 'Профессиональная квалификация',
  'Tertiary Institute Diploma/Certificate': 'Диплом/сертификат о среднем образовании',
  'University Bachelor Degree': 'Диплом бакалавра университета',
  'Postgraduate Diploma/Certificate': 'Сертификат/диплом магистра',
  'Bachelor Honours': 'Диплом бакалавра с отличием',
  'Masters Degree': 'Магистерская степень',
  'Doctorate Degree': 'Докторская степень',
  'African American': 'Афро-американец',
  Aborigine: 'Абориген',
  Algerian: 'Алжирец',
  African: 'Африканец',
  Afrikaner: 'Африканер',
  Albanian: 'Албанец',
  American: 'Американец',
  Argentinian: 'Аргентинец',
  'Australian Aborigine': 'Австралийский абориген',
  Arab: 'Араб',
  Asian: 'Азиат',
  Austrian: 'Австриец',
  Australian: 'Австралиец',
  Bangladeshi: 'Бангладешец',
  Balinese: 'Балиец',
  'Black British': 'Афро-британец',
  'Black Canadian': 'Афро-канадец',
  Belgian: 'Бельгиец',
  'Black Carribean': 'Чернокожий житель Карибского региона',
  'Black Indian': 'Афро-индиец',
  'Black African': 'Чернокожие африканцы',
  Bolivian: 'Боливиец',
  Bengali: 'Бенгалец',
  'Black Other': 'Другие чернокожие',
  Bosnian: 'Босниец',
  Brazilian: 'Бразилец',
  British: 'Британец',
  Belarusian: 'Белорус',
  Bulgarian: 'Болгарин',
  Burmese: 'Бирманец',
  Canadian: 'Канадец',
  Castilian: 'Кастилец',
  Catalan: 'Каталонец',
  Celtic: 'Кельт',
  Chinese: 'Китаец',
  'Channel Islander': 'Житель Нормандских островов',
  Cossack: 'Казак',
  Chilean: 'Чилиец',
  Cambodian: 'Камбоджиец',
  Chechen: 'Чеченец',
  Colombian: 'Колумбиец',
  'Costa Rican': 'Костариканец',
  Croatian: 'Хорват',
  Corsican: 'Корсиканец',
  Cantonese: 'Житель Кантона (Гуанчжоу)',
  Cuban: 'Кубинец',
  Cypriot: 'Киприот',
  'Cypriot Greek': 'Грек-киприот',
  Czech: 'Чех',
  Danish: 'Датчанин',
  Dutch: 'Голландец',
  Eurasian: 'Евразиец',
  Ecuadorian: 'Эквадорец',
  Egyptian: 'Египтянин',
  English: 'Англичанин',
  Estonian: 'Эстонец',
  Ethiopian: 'Эфиоп',
  European: 'Европеец',
  Fijian: 'Фиджиец',
  Filipino: 'Филиппинец',
  Flemish: 'Фламандец',
  Finnish: 'Финн',
  French: 'Француз',
  Gaelic: 'Гаэл',
  Georgian: 'Грузин',
  German: 'Немец',
  Greek: 'Грек',
  Hungarian: 'Венгр',
  Hispanic: 'Житель США испанского или латино-американского происхождения',
  'Hong Kong Chinese': 'Житель Гонконга китайского происхождения',
  Icelandic: 'Исландец',
  Indonesian: 'Индонезиец',
  Irani: 'Иранец',
  Indian: 'Индиец',
  'Iranian/Persian': 'Иранец/перс',
  Iraqi: 'Житель Ирака',
  Irish: 'Ирландец',
  'Iranian Arab': 'Иранец арабского происхождения',
  Iranian: 'Иранец',
  Italian: 'Итальянец',
  Jamaican: 'Житель Ямайки',
  Jewish: 'Еврей',
  'Jewish/Israeli': 'Еврей/Израильтянин',
  Jordanian: 'Иорданец',
  Japanese: 'Японец',
  Kenyan: 'Кениец',
  Kiwi: 'Новозеландец',
  Kongo: 'Конголезец',
  Korean: 'Кореец',
  Latin: 'Латин',
  Lebanese: 'Ливанец',
  Lithuanian: 'Литовец',
  Libyan: 'Ливиец',
  'Latin American': 'Латиноамериканец',
  Latino: 'Житель США латиноамериканского происхождения',
  Luxembourger: 'Люксембуржец',
  Latvian: 'Латвиец',
  Macedonian: 'Македонец',
  'Middle Eastern': 'Житель Ближнего Востока',
  Malaitian: 'Житель Малайзии',
  Maori: 'Маори',
  Monacan: 'Житель Монако',
  Moroccan: 'Черногорец',
  Maltese: 'Марокканец',
  Mexican: 'Мальтиец',
  Malay: 'Мексиканец',
  'Mixed Race': 'Малаец',
  'New Zealand European': 'Новозеландец европейского происхождения',
  Nigerian: 'Нигериец',
  Norwegian: 'Норвежец',
  Nepalese: 'Непалец',
  'New Zealander': 'Новозеландец',
  'Other Ethnicity': 'Представитель другой этнической или расовой принадлежности',
  Paraguayan: 'Парагваец',
  'Pacific Islander': 'Американец с тихоокеанских островов',
  Persian: 'Перс',
  Peruvian: 'Перуанец',
  'Persian/Iranian': 'Перс/Иранец',
  Pakistani: 'Пакистанец',
  Palestinian: 'Палестинец',
  Polish: 'Поляк',
  'Papua New Guinean': 'Житель Папуа Новой Гвинеи',
  'Puerto Rican': 'Пуэрториканец',
  Portuguese: 'Португалец',
  Polynesian: 'Полинизиец',
  Romanian: 'Румын',
  Russian: 'Россиянин',
  Samoan: 'Самоанец',
  'South African Coloured': 'Южноафриканец',
  Scottish: 'Шотландец',
  Swede: 'Швед',
  'South African European': 'Южно-африканец европейского происхождения',
  Serbian: 'Серб',
  Swedish: 'Швед',
  'South American Indian': 'Южно-американец индийского происхождения',
  Sicilian: 'Словак',
  Slovak: 'Славянин',
  Slav: 'Словен',
  Spanish: 'Испанец',
  'Sri Lankan': 'Житель Шри Ланки',
  'Southeast Asian': 'Житель Южной Азии',
  Swiss: 'Швейцарец',
  Swahili: 'Суахили',
  Swazi: 'Свази',
  Taiwanese: 'Тайванец',
  Thai: 'Таец',
  Turkish: 'Турок',
  Tamil: 'Тамил',
  Tongan: 'Тонганец',
  Rwandan: 'Руандер',
  Tunisian: 'Тунисец',
  Udi: 'Уди',
  Ugandan: 'Угандиец',
  Ukrainian: 'Украинец',
  Uruguayan: 'Уругваец',
  Vietnamese: 'Вьетнамец',
  Welsh: 'Валлиец',
  White: 'Белый',
  'West Indian': 'Житель Вест-Индии',
  Zimbabwean: 'Зимбабвиец',
  Zulu: 'Зулус',
  '- Chief executive': ' -Глава исполнительной власти, исполнительный директор',
  '- Administrative or commercial manager': ' -Административный или коммерческий менеджер',
  '- Production or specialized services manager': ' -Менеджер по производству или специализированным услугам',
  '- Hospitality, retail or other services manager':
    ' -Менеджер в сфере гостинничного хозяйства, розничной торговли или других услуг',
  '- Senior manager': ' -Менеджер высшего звена',
  '- Middle manager': ' -Менеджер среднего звена',
  '- Junior manager/supervisor': ' -Менеджер низшего звена/супервайзер',
  '- Other': ' -Другое',
  Professional: 'Квалифицированный специалист',
  '- Science or engineering professional': ' - Наука и технология',
  '- Health professional': ' -Квалифицированный специалист в области здравоохранения',
  '- Teaching professional': ' -Квалифицированный специалист по обучению',
  '- Business or administration professional': ' -Квалифицированный специалист в области управления бизнесом',
  '- Information and communications technology professional':
    ' -Специалист в области информационных и коммуникационных технологий',
  '- Legal, social or cultural professional':
    ' -Квалифицированный специалист в области юриспруденции, социальной сферы или культуры',
  'Technician or associate professional': 'Техник или технический специалист',
  '- Science or engineering associate professional': ' - Наука и технология',
  '- Health associate professional': ' -Квалифицированный специалист в области здравоохранения',
  '- Business or administration associate professional': ' - - бизнес и менеджмент',
  '- Legal, social, cultural or related associate professional':
    ' - Юриспруденция, социальная сфера, культура или смежные области.',
  '- Information and communications technician': ' -Технический специалист в области информации и связи',
  'Clerical support worker': 'Офисный работник ',
  '- General or keyboard clerk': ' -Офисный работник или наборщик текстов на компьютере',
  '- Customer services clerk': ' -Специалист по обслуживанию клиентов',
  '- Numerical or material recording clerk': ' -Счетовод или учетчик',
  'Service or sales worker': 'Сотрудник сферы обслуживания или продаж',
  '- Personal service worker': ' -Рабочий в сфере бытовых услуг',
  '- Sales worker': ' -Сотрудник отдела продаж',
  '- Personal care worker': ' -Сиделка',
  '- Protective services worker': ' -Охранник',
  'Skilled agricultural, forestry or fishery worker':
    'Квалифицированный рабочий сельского, лесного или рыбного хозяйства',
  '- Market-oriented skilled agricultural worker': ' - Квалифицированный сельскохозяйственный работник',
  '- Market-oriented skilled forestry, fishing or hunting worker':
    '- Квалифицированный рабочий в лесном хозяйстве, на рыбалке или охоте',
  '- Subsistence farmer, fisher, hunter or gatherer': ' -Фермер, рыбак, охотник или сборщик (например, грибов и ягод)',
  'Craft or related trades worker': 'Мастер определенного ремесла ',
  '- Building or related trades worker, excluding electrician':
    ' -Рабочий строительной или связанной со строительством специальности, за исключением электрика',
  '- Metal, machinery or related trades worker':
    ' -Рабочий в сфере металлургии, машиностроения или связанных с ними областях',
  '- Handicraft or printing worker': ' -Ремесленник или печатник',
  '- Electrical or electronic trades worker': ' -Электрик или специалист по электронной торговли',
  '- Food processing, wood working, garment or other craft or related trades':
    ' -Технолог  производства пищевых продуктов, деревообработка, пошив одежды или связанные с этим занятия',
  'Plant or machine operator, or assembler': 'Станочник или рабочий-сборщик',
  '- Stationary plant or machine operator': ' -Оператор стационарной установки или рабочий-станочник',
  '- Assembler': ' -Рабочий-сборщик',
  '- Driver or mobile plant operator': ' -Водитель или оператор мобильной установки',
  'Elementary occupation': 'Неквалифицированная работа/род занятий',
  '- Cleaner or helper': ' -Уборщик или помощник ',
  '- Agricultural, forestry or fishery labourer': ' -Рабочий в области сельского, лесного или рыбного хозяйства',
  '- Labourer in mining, construction, manufacturing or transport': '- Разнорабочий в разных сферах',
  '- Food preparation assistant': ' -Помощник повара',
  '- Street or related sales or service worker': ' -Уличный/розничный торговец или работник сферы услуг',
  '- Refuse worker or other elementary worker': ' -Уборщик или другой неквалифицированный рабочий',
  'Armed forces occupation': 'Военнослужащий ',
  '- Commissioned armed forces officer': ' -Офицер Вооруженных Сил',
  '- Non-commissioned armed forces officer': ' -Военнослужащий в звании либо сержанта, либо старшины, либо прапорщика',
  '- Armed forces occupation, other rank': ' -Военнослужащий, другое звание',
  'Local Government/Authority': 'Местные власти',
  'Private, Corporate ': 'Частный, корпоративный',
  'Public service, Fed/State Govt': 'Государственная служба, федеральная/государственная власть',
  'State Owned Corporation': 'Государственная корпорация',
  Unknown: 'Неизвестно',
  'Administrative and Support Service Activities': 'Административная деятельность',
  'Agriculture, Forestry and Fishing': 'Сельское, лесное и рыбное хозяйство ',
  'Arts, Entertainment and Recreation': 'Искусство, развлечение и отдых',
  'Banking & Finance': 'Банковское дело и финансы',
  'Business Services': 'Бизнес-услуги',
  'Architecture and Construction': 'Архитектура и строительство',
  'Education and Training': 'Образование и обучение',
  'Utilities (Gas, Water, Electric, etc)': 'Коммунальные услуги (газ, вода, электричество и т.д.)',
  Engineering: 'Инженерная деятельность (инжиниринг)',
  'Hospitality and Tourism': 'Сфера туризма',
  'Human Resources': 'Управление персоналом',
  'Health Sector': 'Сектор здравоохранения',
  'Information and Technology': 'Информационные технологии',
  Insurance: 'Страхование',
  'Law Enforcement': 'Правохранительные органы',
  Manufacturing: 'Производство',
  'Mining and Quarrying': 'Горная промышленность и карьерные работы',
  'Military & Defence': 'Вооруженные силы и оборонная промышленность',
  'Oil and Gas/Energy Exploration': 'Разработка нефтяных и газовых месторождений',
  'Pharmaceutical Industry': 'Фармацевтическая промышленность',
  'Public Administration': 'Государственное администрирование',
  'Real Estate Activities': 'Работа с недвижимостью',
  'Scientific and Technical Activities': 'Научно-техническая деятельность',
  Security: 'Безопасность',
  'Social Work': 'Социальная работа',
  Telecommunications: 'Телекоммуникации',
  'Transportation and Storage': 'Перевозки и складское хозяйство',
  Media: 'Средства информации',
  'Wholesale and Retail Trade': 'Оптовая и розничная торговля',
  Arabic: 'Арабский',
  'Brazilian Portuguese': 'Бразильский португальский',
  'Chinese-Simplified': 'Китайский - упрощенный',
  'Chinese-Traditional': 'Китайский - традиционный',
  Hebrew: 'Иврит',
  'Hindi-Urdu': 'Хинди-урду',
  isiNdebele: 'Ндебеле',
  isiXhosa: 'Кхоса',
  isiZulu: 'Зулу',
  'Latin American Spanish': 'Латиноамериканский испанский ',
  Malayalam: 'Малайский',
  Punjabi: 'Панджаби ',
  Sepedi: 'Сепеди',
  'Southern Thai': 'Южно-тайский',
  Sesotho: 'Сесото',
  Setswana: 'Сетсвана',
  siSwati: 'Свати',
  'Tagalog (Filipino)': 'Тагальский (филиппинский)',
  Turkmen: 'Туркменский',
  Tshivenda: 'Венда',
  Xitsonga: 'Тсонга',
  Male: 'Мужской',
  Female: 'Женский',
  'Not Specified': 'Не указан',

  // errorMessages.js
  'Email address is not valid': 'адрес электронной почты неверный',

  // table bulkActions assessments.js
  'Edit battery': 'Редактировать',
  // table singleRowActions competency.js
  'Add competency': 'Добить компетенцию',
  'Edit competency category': 'Редактировать',
  'Delete competency category': 'Удалить',
  'Edit competency': 'Редактировать',
  'Delete competency': 'Удалить',
  'Edit question': 'Редактировать',
  'Delete question': 'Удалить',

  // table singleRowActions competencyGroup.js
  'Delete competency group': 'Удалить',
  'Go to competency groups details': 'Перейти к деталям',

  // table singleRowActions  group.js
  'Invite respondents to questionnaire': 'Пригласить пройти опрос',
  'Administer answer sheet': 'Заполнить лист ответов',
  'Administer managed group': 'Провести сессию оценивания для группы',
  'Create an ideal profile based on selected group': 'Создать идеальный профиль',
  'Create a Norm based on selected group': 'Создать нормативную группу',
  'View or edit': 'Перейти к деталям',
  Delete: 'Удалить',

  // table singleRowActions invites.js
  Remind: 'Напомнить',

  // table singleRowActions projects.js
  'Add to favorites': 'Добавьте в избранное',
  View: 'Просмотрите',
  'Delete Project': 'Удалите Проект',

  // table singleRowActions raters.js
  'Delete rater': 'Удалить респондента',

  // table singleRowActions reports.jsAdminister questionnaire
  Open: 'Открыть',
  Download: 'Загрузить',

  // table singleRowActions respondents.js
  'Administer questionnaire': 'Администрировать сессию оценки',
  'Invite to questionnaire': 'Пригласить к заполнению опросника',
  'Add tag': 'Дабавить тег',

  // table singleRowActions session.js
  'Invite raters who have not yet complete': 'Направьте приглашения респондентам, которые еще не заполнили опросник',
  'Invite who have not yet been invited': 'Отправить приглашения',
  'Send reminder to raters who have already been invited but have not yet completed': 'Отправить напоминания',
  'Invite to specify own rater': 'Пригласить определить респондентов',
  'Send invitation to self': 'Отправить приглашение',
  'Edit session': 'Редактировать сессию',

  // table bulkActions assessments.js
  'Add custom battery': 'Создать батарею',
  'Edit assessment': 'Редактировать',
  'Delete Assessment(s)': 'Удалить',

  // table bulkActions groups.js
  'Create a Norm based on the selected group(s)': 'Создать нормативную группу/групп',
  'Create an Ideal profile based on the selected group(s)': 'Создать идеальный профиль',
  'Delete selected group(s)': 'Удалить',
  'Generate an open group link': 'Сгенерировать ссылку для группы',

  // table bulkActions idealProfiles.js
  'Edit ideal profile': 'Редактировать идеальный профиль',

  // table bulkActions invites.js
  'Copy invite link to clipboard': 'Скопировать ссылку-приглашение',
  'Delete invites': 'Удалить приглашения',

  // table bulkActions projects.js
  'Edit project details': 'Редактировать данные проекта',
  'Delete projects(s)': 'Удалить',
  // table bulkActions raters.js
  'Send invite to selected rater(s)': 'Разослать приглашения',
  'Remove selected rater(s) from session': 'Удалить из сессии',

  // table bulkActions reports.js
  Actions: 'Действия',
  'Delete selected report(s)': 'Удалить выбранные отчеты',
  'Download selected report(s)': 'Загрузить выбранные отчеты',
  'View selected report(s)': 'Просмотреть выбранные отчеты',

  // table bulkActions respondentGroups.js
  'Remove respondent from selected group(s)': 'Удалить из выбранных групп',
  // table bulkActions respondents.js
  'Administer assessment': 'Провести сессию оценки',
  'Administer assessment for': 'Сессия оценки для %{respondentName}',
  'Invite respondent(s) to assessment': 'Пригласить',
  'Request report(s)': 'Запросить отчет(-ы)',
  'Go to respondent details': 'Перейти к данным',
  'Manage tags for selected respondent(s)': 'Обновить теги',
  'Add new group from selection': 'Добавить группу из выборки',
  'Add to existing group': 'Добавить к имеющейся группе',
  'Delete selected respondents from group': 'Удалить из группы',
  'Delete selected respondents': 'Удалить',
  'this respondent': 'этого респондента',
  'selected respondents': 'выбранные респонденты',

  // table bulkActions respondentsTest.js
  'Delete selected result(s)': 'Удалить',

  // table bulkActions sessions.js
  'Invite to specify own raters': 'Пригласить определить респондентов',
  'Delete Session(s)': 'Удалить',

  // table bulkActions templates.js
  'Go to template details': 'Перейти к деталям',
  'Delete selected template(s)': 'Удалить',
  // table bulkActions welcomeText.js
  'Go to welcome text details': 'Перейти к деталям',
  'Delete selected welcome text(s)': 'Удалить',
  // table entitiesDropdownActions groupDetails.js
  'Edit title': 'Редактировать название',
  'Duplicate 360 assessment': 'Продублировать 360 опросник',
  'Delete 360 assessment': 'Удалить 360 опросник',
  // table entitiesDropdownActions sessions.js
  'Invite raters who have not yet completed': 'Отправить приглашения',

  // table tableActions competencyGroupsDetails.js
  'Review competencies': 'Просмотреть компетенции',

  // table tableActions customGroups.js
  'Add new 360 assessment': 'Создать новый 360 опросник',

  // table tableActions raters.js
  'Add rater': 'Добавить респондента',
  // table tableActions respondents.js
  'Import respondents': 'Импортировать респондентов',

  // table tableActions templates.js
  'Add new template': 'Создать новый шаблон',
  'Edit template': 'Редактировать шаблон',
  'Edit welcome text': 'Редактировать вступительный текст',
  // QuestionOrder.js
  Default: 'По умолчанию',
  Custom: 'Заданное вручную',
  Random: 'В случайном порядке',
  // tables columns sessions.js
  Invited: 'Приглашение отослано',
  'Invite Not Sent': 'Приглашение не отослано',

  'Show expired': 'Показать неактивные',
  'Sorry we could not find any': 'Извините, мы не смогли найти никакого %{entityName}',
  'Sorry, we could not find any respondents': 'К сожалению, мы не смогли найти ни одного респондента',
  'Sorry, we could not find any reports': 'К сожалению, мы не смогли найти ни одного отчета',
  'Sorry, we could not find any results': 'К сожалению, мы не смогли найти ни одного результата',
  'Sorry, we could not find any invites': 'К сожалению, мы не смогли найти ни одного приглашения',
  'Sorry, we could not find any records': 'К сожалению, мы не смогли найти ни одной записи',
  'Sorry, we could not find any projects': 'К сожалению, мы не смогли найти ни одного проекта',
  'Sorry, we could not find any tests': 'К сожалению, мы не смогли найти ни одного текста',
  'Sorry, we could not find any groups': 'К сожалению, мы не смогли найти ни одной группы',
  'Sorry, we could not find any notifications': 'К сожалению, мы не смогли найти ни одного оповещения',
  'Sorry, we could not find any raters': 'К сожалению, мы не смогли найти ни одного респондента',
  'Sorry, we could not find any templates': 'К сожалению, мы не смогли найти ни одного шаблона',
  'Sorry, we could not find any sessions': 'К сожалению, мы не смогли найти ни одного сеанса',
  'Sorry, we could not find any welcome texts': 'К сожалению, мы не смогли найти ни одного вступительного текста',
  'Sorry, we could not find any competency groups': 'К сожалению, мы не смогли найти ни одной группы компетенций',
  'Sorry, we could not find any norms': 'К сожалению, мы не смогли найти ни одной нормы',
  'Sorry, we could not find any response scales': 'К сожалению, мы не смогли найти ни одной шкалы ответов',
  'Number of items': 'Количество пунктов',
  Categories: 'Категории',
  'User name': 'Имя пользователя',
  'Update amount': 'Обновить количество',
  Members: 'Участники',
  'Invitation Date': 'Дата приглашения',
  'Respondent Name': 'Имя респондента',
  Report: 'Отчет',
  'Date submitted': 'Дата отправки',
  Status: 'Статус',
  'Not invited': 'Приглашение не направлено',
  'Not completed': 'Не закончено',
  'Completed Date': 'Дата окончания',
  'Direct Report': 'Подчиненый',
  Progress: 'Прогресс',
  'Assessments Done': 'Проведенные оценки',
  'Add new project': 'Добавить новый проект',
  Date: 'Дата',
  'Loading entities': 'Загрузка %{entityName}',
  Created: 'Дата создания',
  'No options': 'Вариантов нету',
  createOption: 'Создать',
  '**Default': '**По умолчанию',
  '**Default text': '**По умолчанию',

  // Settings Account ChangePassword.js

  'Passwords must match': 'Пароли должны совпадать',
  'Change password': 'Изменить пароль',
  'Confirm new password': 'Подтвердить новый пароль',
  'New password': 'Новый пароль',
  Change: 'Изменить',
  'Pins must match': 'Пин-коды должны совпадать',
  'Change pin': 'Изменить пин-код',
  'Confirm new pin': 'Подтвердить новый пин-код',
  'New pin': 'Новый пин-код',
  'Total:': 'Всего:',
  'Loading credits history...': 'Загрузка истории кредитов',
  'From date': 'С',
  'To date': 'До',
  Pin: 'Пин-код',
  'Receive email notifications': 'Получить уведомления по электронной почте',
  'Complete Status': 'Статус заполнения',
  'Invite Status': 'Статус рассылки приглашений',
  'Not Invited': 'Приглашения не направлены',
  'Sessions count': 'Количество сессий',

  // ImportSessions
  'Import sessions': 'Импортировать сессии',
  prepare: 'подготовить',
  preview: 'просмотреть',
  import: 'импортировать',
  'Prepare a csv file yourself or': 'Подготовить csv файл самостоятельно или',
  'download the sample import file': 'загрузить образец импрортированного файла',
  'Make sure the data order is set out correctly': 'Убедитесь, что данные введены в правильном порядке',
  'Upload the file': 'Загрузить файл',
  'Drag and drop file here or browse the file': 'Перетащите файл сюда или выберите в браузере',
  'Upload the csv file to import respondents': 'Загрузите csv файл для импортирования респондентов',
  'The first line contains field headers': 'Первая строка содержит заголовки поля',
  'Choose file': 'Выбрать файл',
  Next: 'Дальше',

  // AccountTabs
  general: 'общий',
  'credit updates': 'обновления кредитов',
  'Reports Stats': 'запрошенные отчеты',
  'assessments stats': 'использование инструментов оценки',

  // ReportUsage
  total: 'Всего: %{total}',
  'Report name': 'Название отчета',
  'Usage count': 'Количество использований',
  'Assessment name': 'Название инструмента оценки',
  Total: 'Общее количество',
  'Loading requested reports': 'Загрузка',
  'Loading used assessments': 'Загрузка',

  // Toasts & system notifications
  'Tag(s) have been deleted': 'Тег(-и) удалено',
  'Loading items': 'Загрузка элементов',
  'Saving results': 'Сохраниние результатов',
  'Ideal profile has been created': 'Идеальный профиль создано',
  'Copy of 360 survey has been created': 'Копию 360 опросника было создано',
  'Tag(s) have been updated': 'Тег(-и) обновлены',
  'Welcome text has been saved': 'Вступительный текст сохранен',
  'Welcome text has been updated': 'Вступительный текст сохранен',

  // TODO об'єднати в один переклад
  'It looks like you have been editing something': 'Похоже, вы что-то редактировали',
  'Would you like to save your changes before leaving': 'Вы хотите сохранить изменения перед выходом?',
  Warning: 'Внимание',

  // Toasts
  groupCreationToast: 'Группа создана',
  respondentsDeletionToast: 'Респондента(-ов) удалено',
  resultsSuccessToast: 'Результаты для %{respondentInfo} успешно добавлены',
  surveyDeletionCancelToast: '360 опросники, используемые в проекте, невозможно удалить',
  creditBalanceUpdateToast: 'Баланс кредитов обновлен',
  correctDataToast: 'Убедитесь, что все данные добавлены правильно',
  downloadLimitToast: 'Упс! Вы не можете загрузить больше 50 отчетов одновременно',
  categoryUpdateToast: 'Категория обновлена',
  newCategoryToast: 'Новая категория добавлена',
  createdRequestToast: 'Запрос создан',
  respondentCreationToast: 'Респондент добавлен',
  respondentUpdateToast: 'Информация обновлена',
  clipboardCopyToast: 'Успешно скопировано в бефер обмена',
  addedAssessmentToast: 'Опросник добавлен',
  deletedAssessmentToast: 'Опросник удален',
  completedAssessmentToast: '%{respondentName} завершил %{assessment}',
  importRespondentsErrorToast: 'Что то пошло не так во время импорта респондентов',
  importSessionsErrorToast: 'Что то пошло не так во время импорта сесии',
  competencyTitleUpdateToast: 'Шаблон обновлен',
  competencyUpdateToast: 'Компетенция обновлена',
  newCompetencyToast: 'Компетенция добавлена',
  questionUpdateToast: 'Вопрос обновлен',
  questionAdditionToast: 'Вопрос добавлен',
  groupLinkErrorToast: 'Что то пошло не так во время создания групповой ссылки, попробуйте еще раз',
  reportsDeletionToast: 'Отчет удалено',
  resultsSavingErrorToast: 'Ошибка сохранения результатов для %{respondentName} %{assessment}',
  reportRequestCreationToast: 'Отчет создано',
  assessmentDataFailToast: 'Не удалось загрузить данные опросника',
  scaleUpdatedToast: 'Шкалу ответов обновлено',
  scaleCreatedToast: 'Шкалу ответов сохранено',
  scaleDeletionToast: 'Шкала(-ы) ответов удалено',
  surveyUpdatedToast: '360 опросник обновлено',
  normsDeletionToast: 'Нормы удалено',
  'Deletion blocked': 'Удаление заблокировано',
  groupCreationErrorToast: 'Ошибка при создании группы',
  groupsDeletionToast: 'Группу удалено',
  reportsGenerationToast: 'Отчет(-ы) запрошены успешно',
  invitesDeletionToast: 'Приглашение(-я) удалено',
  normCreationToast: 'Нормы созданы',
  normCreationErrorToast: 'Невозможно создать норму, попробуйте еще раз',
  projectDeletionToast: 'Проект удален',
  projectsDeletionToast: 'Проекты удалены',
  projectCreationToast: 'Проект создан',
  projectUpdateToast: 'Проект обновлен',
  ratersDeletionToast: 'Респондент(-ы) удален',
  categoryDeletionToast: 'Категорию компетенций удалено',
  resultsDeletionToast: 'Результат(-ы) удалено',
  tagsAdditionToast: 'Теги добавлено',
  addedToGroupToast: 'Добалено в группу успешно',
  questionnaireSessionErrorToast: 'Попытка создать сессию оценки неудачна, повторите попытку позже',
  sessionAdditionToast: 'Сессию добавлено',
  ratersAdditionToast: 'Сессию оценщика добавлено',
  competencyDeletionToast: 'Компетенцию удалено',
  questionDeletionToast: 'Вопрос удален',
  assessmentsDeletionToast: 'Опросник удален',
  profilesDeletionToast: 'Идеальный профиль(-и) удалено',
  profileUpdateToast: 'Идеальный профиль обновлен',
  templatesDeletionToast: 'Шаблон(-ы) удалены',
  templateAdditionToast: 'Шаблон сохранен',
  templateUpdateToast: 'Шаблон обновлен',
  welcomeTextsDeletionToast: 'Вступительный текст удален',
  batteryAdditionToast: 'Батарею создано',
  batteryUpdateToast: 'Батарею обновлено',
  batteriesDeletionToast: 'Батареи удалены',
  batteryDeletionToast: 'Батарею удалено',
  passwordUpdateSuccessToast: 'Пароль успешно обновлен',
  pinUpdateSuccessToast: 'Пин успешно обновлен',
  'Reset assessment deadline': 'Сбросить срок оценки',
  Update: 'обновлять',
  updateAssessmentInvitationToast: 'Актуальные приглашения',
  updateProjectInvitationToast: 'Актуальные приглашения',
  biodataTooltip:
    'Шаблоны биоданных позволяют собирать персонализированные биографические данные респондентов. Создавайте шаблоны биоданных в области «Настройки».',
  'Default Biodata Template has been updated': 'Шаблон биоданных по умолчанию обновлен.',
  DEFAULT: 'по умолчанию',
  'Bio data template': 'Биографический шаблон данных',
  biodataDefaultTemplateLabel: '** Нет шаблона для биографических данных (все категории включены) **',
  'Add Bio Data template': 'Добавить шаблон биографических данных',
  'Loading biodata templates': 'Загрузка шаблонов биографических данных.',
  'Add Name': 'Добавить имя',
  'Add description': 'Добавить описание',
  selectEditCategoryValues: 'Выберите или отредактируйте значения:',
  'Add values to category': 'Добавить значения категории ',
  'Select values for this category': 'Выберите значения для этой категории',
  'Select all': 'Выбрать все',
  'Biodata Template has been created': 'Шаблон биографических данных создан',
  'Biodata Template has been updated': 'Шаблон биографических данных был обновлен',
  'Biodata template(s) deleted': 'Шаблон(ы) биографических данных удален(ы)',
  biodataDefaultTemplate: 'Установлено по умолчанию',
  'Delete selected biodata template(s)': 'Удалить выбранный(ые) шаблон(ы) биографических данных',
  'Delete biodata templates': 'Удалить шаблоны биографических данных',
  'the selected biodata templates?': 'Выбранные шаблоны биографических данных?',
  'this biodata template?': 'Этот шаблон биографических данных?',
  'Update default biodata template': 'Обновить шаблон биографических данных по умолчанию',
  'Are you sure you want to set this template as default?':
    'Вы уверены, что хотите установить этот шаблон по умолчанию?',
  'Biodata Configuration': 'Шаблоны биографических данных',
  defaultCompleteBioTemplate: '** Шаблон всех биографических данных (все категории включены) **',
  defaultEmptyBioTemplate: '** Шаблон пустых биографических данных (категории не включены) **',
  invitesSentToast: 'Приглашение(я) отправлено',
  selectNormForReport: 'Пожалуйста, выберите норму',
  selectIdealProfileForReport: 'Пожалуйста, выберите идеальный профиль',
  visibleNormForReport: 'Использовать норму для отчетов',
  hideNormForReport: 'Скрыть норму для отчетов',
  showNormTitle: 'Сделать видимой для списка запросов отчетов',
  showConfirmationMessageTitle: 'Вы уверены, что хотите сделать видимой',
  hideNormTitle: 'Скрыть для списка запросов отчетов',
  hideConfirmationMessageTitle: 'Вы уверены, что хотите удалить',
  showhideConfirmationMessageDescription1: 'выбранные нормы для генерации отчетов?',
  showhideConfirmationMessageDescription2: 'эту норму для генерации отчетов?',
  visible: 'Видимый',
  normsVisibilityToast: 'Видимость нормы обновлена',
  updateGroupToast: 'Группа обновлена',
  updateGroupErrorToast: 'Ошибка при обновлении группы',
  'Edit Group Details': 'Редактировать детали группы',
  'Duplicate Group': 'Дублировать группу',
  emptyLanguageReports: 'К сожалению, мы не смогли найти отчеты, доступные на этом языке',
  Notes: 'Заметки',
  'Forgot password?': 'Забыли пароль?',
  'You should receive your reset link soon at': 'Вы скоро получите ссылку для сброса на',
  "Can't find the email?": 'Не можете найти письмо?',
  'You should also check your spam folder': 'Вам также следует проверить папку со спамом',
  'Email address': 'Адрес электронной почты',
  'Request a reset link': 'Запросить ссылку для сброса',
  'Back to login page': 'Вернуться на страницу входа',
  downloadInvitesReport: 'Скачать csv',
  csvReportName: 'Отчет о приглашениях %{date}.csv',
  'Copy and share generated links for': 'Скопируйте и поделитесь сгенерированными ссылками для',
  'that have email address missing': 'отсутствует адрес электронной почты',
  'You have chosen a group of': 'Вы выбрали группу из',
  'have email address missing': 'отсутствует адрес электронной почты',
  'This 360 survey is used by one or more project, therefore can not be edited or deleted':
    'Этот опрос 360 используется одним или несколькими проектами, поэтому не может быть отредактирован или удален',
  and: 'и',
  more: 'больше',
  missingRequestedNorms: 'Отсутствуют нормы и/ или идеальные профили для выбранных отчетов',
  'You will be redirected shortly': 'Вы будете перенаправлены в ближайшее время',
  oneRemainingNorm: 'По крайней мере одна норма должна оставаться видимой для этого теста',
  'Set reference as required': "Установить поле ссылки как обязательное",
  referenceAsRequired: "Используйте поле 'reference' в приглашении респондента, чтобы передать полезную информацию в запись респондента"
};
export default translations;
