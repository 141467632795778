import React, { useRef } from 'react';
import { I18n } from 'react-redux-i18n';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { dateValueFormatter, dateWithoutTimeComparator, currentLocaleText } from '../../../../lib/ag-grid';
import useUpdateEffect from '../../../../hooks/useUpdateEffect';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule, ExcelExportModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const agGridDefaultSettings = {
  pagination: true,
  paginationPageSize: 100,
  paginationPageSizeSelector: [20, 50, 100],
  rowHeight: '58',
  headerHeight: '58',
  domLayout: 'autoHeight',
  gridOptions: { popupParent: document.body },
};

const agGriddefaultColumnDefinition = {
  sortable: true,
};

const columnDefinition = [
  {
    headerName: I18n.t('Respondent'),
    cellDataType: 'text',
    filter: 'agTextColumnFilter',
    flex: 1,
    valueFormatter: (params) => {
      return `${params.data.firstName} ${params.data.familyName}`;
    },
    filterParams: {
      buttons: ['apply', 'reset'],
      closeOnApply: true,
    },
  },
  {
    field: 'questionnaire',
    headerName: I18n.t('Test'),
    cellDataType: 'text',
    filter: 'agSetColumnFilter',
    flex: 1,
    filterParams: {
      buttons: ['apply', 'reset'],
      closeOnApply: true,
    },
  },
  {
    field: 'creationDate',
    headerName: I18n.t('Date Tested'),
    cellDataType: 'date',
    flex: 1,
    filter: 'agDateColumnFilter',
    valueGetter: (params) => {
      return new Date(params.data.creationDate);
    },
    valueFormatter: dateValueFormatter,
    filterParams: {
      maxNumConditions: 1,
      comparator: dateWithoutTimeComparator,
      buttons: ['apply', 'reset'],
      closeOnApply: true,
    },
  },
];

export default function({ rows, exportTrigger }) {
  const gridRef = useRef(null);

  useUpdateEffect(() => {
    gridRef.current?.api.exportDataAsExcel();
  }, [exportTrigger]);

  return (
    <div>
      <div className="ag-theme-quartz ag-grid-custom-styles activity-log-results-table">
        <AgGridReact
          ref={gridRef}
          className="testy"
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={rows}
          localeText={currentLocaleText}
        />
      </div>
    </div>
  );
}
