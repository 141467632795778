import storageService from "../services/storageService";

export const findLanguage = (languages, defaultLang) => {
    const interfaceLanguage = storageService.getItem('psLang');
    let found;
    if (interfaceLanguage) {
        found = languages.find((item) => item.code.toLocaleLowerCase() === interfaceLanguage);
    }

    const eng = languages.find((item) => item.code === 'EN');
    if (!defaultLang) return eng;

    const defaultUserLanguage = languages.find((item) => item.code === defaultLang);
    return defaultUserLanguage || found || eng;
};