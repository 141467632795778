import { useFormik } from 'formik';
import * as Yup from 'yup';
// import { addMonths } from 'date-fns';

import { resolveAssessmentId } from '../../../utils/assessmentsNames';
import { errorMessages } from '../../../constants/errorMessages';
import useAvailableTests from '../../../hooks/useAvailableTests';
import createTestsOptions from '../../Respondents/CreateInvite/createInviteUtils';
import { reminderOptions, sendingOptions } from '../AssessmentProject/components/options';

export const setCheckedStatesForReports = (reportSets, project) =>
  reportSets.map((set) => {
    const hasReportsChecked = Boolean(
      project?.reports.find(
        (report) => set.reportIDArray.includes(report.reportID) && report.reportSetID === set.reportSetID,
      ),
    );
    const newReports = set.reports.map((report) => {
      const isChecked = Boolean(
        project?.reports.find((item) => item.reportID === report.reportID && item.reportSetID === set.reportSetID),
      );
      return { ...report, isChecked };
    });
    return { ...set, reports: newReports, isChecked: hasReportsChecked };
  });

const useEditAssessmentForm = ({
  project,
  languages,
  groupedAssessments,
  categories,
  welcomeTexts,
  templates,
  reportsLanguages,
  onSubmit,
}) => {
  const language = project?.langID ? languages.find((item) => item.code === project.langID) : null;
  // finding category
  const ungroupedAssessments = groupedAssessments
    ? Object.values(groupedAssessments).reduce((acc, item) => [...acc, ...item])
    : [];
  const assessment = ungroupedAssessments.find((item) => resolveAssessmentId(item) === resolveAssessmentId(project));
  const category = categories.find((item) => item.value === assessment?.category?.toLowerCase());
  // finding assessment
  const assessments = groupedAssessments && category ? groupedAssessments[category.value] : [];
  const availableAssessments = useAvailableTests({
    assessments,
    language,
  });
  const assessmentsOptions = createTestsOptions(availableAssessments);
  const assessmentOption = assessmentsOptions.find(
    (item) => resolveAssessmentId(item) === resolveAssessmentId(assessment),
  );
  // finding template and welcome text
  const template = project?.emailTID ? templates.find((item) => item.templateID === project.emailTID) : templates[0];
  const welcomeText = project?.welcomeTextTID
    ? welcomeTexts.find((item) => item.templateID === project.welcomeTextTID)
    : welcomeTexts[0];
  // finding reminder type and sending type
  const reminderType =
    project?.remindInviteType || project?.remindInviteType === 0
      ? reminderOptions.find((item) => item.backendReference === project.remindInviteType)
      : null;
  const sendingType =
    project?.sendInviteType || project?.sendInviteType === 0
      ? sendingOptions.find((item) => item.backendReference === project.sendInviteType)
      : null;
  // reports part
  const reportsLanguage = project?.reportLangID
    ? reportsLanguages.find((item) => item.code === project.reportLangID)
    : null;

  const formikBag = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: {
      projectName: project?.name || '',
      projectDescription: project?.description || '',
      language,
      category: resolveAssessmentId(project) ? category : null,
      assessments: resolveAssessmentId(project) ? assessments : [],
      assessment: resolveAssessmentId(project) ? assessmentOption : null,
      template,
      welcomeText,
      reminderType,
      sendingType,
      invitationDate: project?.sendInviteDate ? new Date(project.sendInviteDate) : '',
      isDirectReport: project?.sendDirectFeedbackReport,
      reportsLanguage,
    },
    validationSchema: Yup.object().shape({
      projectName: Yup.string()
        .required(errorMessages.inputField)
        .max(50, errorMessages.tooLong(50)),
      projectDescription: Yup.string().max(300, errorMessages.tooLong(300)),
      language: Yup.object().required(errorMessages.inputField),
      category: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      assessment: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      template: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      welcomeText: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      reminderType: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      sendingType: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      invitationDate: Yup.string().when('sendingType', {
        is: (val) => val.backendReference === 2,
        then: Yup.string().required(errorMessages.inputField),
      }),
      isDirectReport: Yup.bool(),
      reportsLanguage: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
    }),
    onSubmit,
  });
  return formikBag;
};

export default useEditAssessmentForm;
