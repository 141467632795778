import qs from 'qs';

const resolveFilterValue = (data, type) => {
  switch (type) {
    case 'date':
      return { FromDate: data.value, ToDate: data.filterType.resolveEndDate(data.value) };
    case 'select':
      return data.value.value;
    default:
      return { Type: data.filterType.backendReference, Value: data.value };
  }
};

const generateQueryString = (tableState, columns) => {
  const paginationString = qs.stringify(tableState.pagination);
  const sortingString = qs.stringify(tableState.sortBy);
  const searchString = tableState.search ? `SearchText=${encodeURIComponent(tableState.search)}` : '';
  const usePagination = tableState?.hasOwnProperty('usePagination')
    ? `usePagination=${tableState.usePagination}`
    : null;

  const filters = tableState.appliedFilters
    ? tableState.appliedFilters.reduce((acc, filterState) => {
        if (!filterState.value) return acc;
        const columnObj = columns.find(
          (col) => (typeof col.Header === 'function' ? col.stringHeader : col.Header) === filterState.columnName,
        );
        const value = resolveFilterValue(filterState, columnObj.type);
        return { ...acc, [columnObj.filteringName]: value };
      }, {})
    : [];

  const filteringString = qs.stringify(filters, { encodeValuesOnly: false, allowDots: true });

  return `${usePagination ? usePagination + '&' : ''}${paginationString && `${paginationString}&`}${sortingString &&
    `${sortingString}&`}${searchString && `${searchString}&`}${filteringString}`;
};

export default generateQueryString;
