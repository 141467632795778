import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subMonths, endOfDay, startOfDay } from 'date-fns';
import styled from 'styled-components';
import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { currentLocaleText } from '../../../lib/ag-grid';
import PageWrapper from '../../PageWrapper';
import TableTabs from '../../reusable/TableTabs';
import DateSelect from '../../reusable/FormComponents/DateSelect';
import Spinner from '../../reusable/Spinner';
import accountTabs from './accountTabs';
import { fetchUsedAssessments } from '../../../store/user/actions';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const AssessmentsUsage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const data = useSelector((state) => state.user.usedAssessments);
  const goBackToSettings = () => dispatch(push('/settings'));

  const currentDate = new Date();
  const [dates, setDates] = useState({ from: subMonths(currentDate, 1), to: endOfDay(currentDate) });
  const [isLoading, setLoadingState] = useState(true);

  useEffect(() => {
    setLoadingState(true);
    dispatch(fetchUsedAssessments(dates, () => setLoadingState(false)));
  }, [dates]);

  const onTabClick = (tab) => dispatch(push(tab.path));

  const onDateChange = (name, newDate) => {
    setDates((prev) => ({ ...prev, [name]: name === 'to' ? endOfDay(newDate) : startOfDay(newDate) }));
  };

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      field: 'testID',
      headerName: I18n.t('Assessment name'),
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'usage',
      headerName: I18n.t('Usage Count'),
      cellDataType: 'number',
      flex: 1,
      filter: 'agNumberColumnFilter',
      aggFunc: 'sum',
      headerValueGetter: (params) => {
        let total = 0;
        for (let i = 0; i < params.api.getDisplayedRowCount(); i++) {
          const rowNode = params.api.getDisplayedRowAtIndex(i);
          total += rowNode?.data?.usage || 0;
        }
        return `Usage Count (Total: ${total})`;
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
  ]);

  const total = data.length;

  const tabs =
    user?.userType === 3
      ? accountTabs.filter((item) => item.name === 'general' || item.name === 'assessmentsUsage')
      : accountTabs;

  return (
    <PageWrapper style={{ overflowY: 'auto' }} title={I18n.t('Account')} backButtonHandler={goBackToSettings}>
      <Spinner isLoading={isLoading} full text={`${I18n.t('Loading used assessments')}...`} />
      <TableTabs tabs={tabs} activeTab={accountTabs[3]} clickHandler={onTabClick} />
      <InfoRow>
        <Balance style={{ visibility: 'hidden' }}>
          {I18n.t('Total')}: {total}
        </Balance>
        <Dates>
          <StyledDateSelect
            onDateChange={(date) => onDateChange('from', date)}
            selectedDate={dates.from}
            label={I18n.t('From date')}
            isBottom
          />
          -
          <StyledDateSelect
            onDateChange={(date) => onDateChange('to', date)}
            selectedDate={dates.to}
            label={I18n.t('To date')}
            isBottom
          />
        </Dates>
      </InfoRow>
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={data}
          localeText={currentLocaleText}
        />
      </div>
    </PageWrapper>
  );
};

AssessmentsUsage.propTypes = {};

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const Dates = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDateSelect = styled(DateSelect)`
  margin-left: 1rem;
  z-index: 9;
  position: relative;

  & > div > div > div:first-of-type {
    width: 15rem;
  }

  & > div > div > div:last-child {
    left: initial;
    right: 0;
  }
`;

const Balance = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.grey5};
`;

export default AssessmentsUsage;
