import copy from 'copy-to-clipboard';
import { addDays, setHours, setMinutes } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { func, object } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { I18n } from 'react-redux-i18n';
import ReactTooltip from 'react-tooltip';
import Modal from '../../reusable/NewModal';
import DateTimeSelect from '../../reusable/Selects/DateTimeSelect';
import Spinner from '../../reusable/Spinner';
import Steps from '../../reusable/Steps';

import { generateGroupLink } from '../../../store/groups/actions';
import { selectCategories, selectUnsupervisedAssessments } from '../../../store/tests/selectors';
import createToastNotification from '../../../utils/createToastNotification';

import { selectAvailableLanguages, selectAvailableMappedBiodataTemplates } from '../../../store/user/selectors';

import { ReactComponent as CopyLinkIcon } from '../../../assets/icons/copy_link.svg';
import { ReactComponent as QuestionIcon } from '../../../assets/icons/question-mark.svg';
import useAvailableTests from '../../../hooks/useAvailableTests';
import useStep from '../../../hooks/useStep';
import { findLanguage } from '../../../utils/findLanguage';
import { findAssessment } from '../../Respondents/CreateInvite';
import createTestsOptions from '../../Respondents/CreateInvite/createInviteUtils';
import CustomButton from '../../reusable/Buttons/Button';
import RadioButton from '../../reusable/FormComponents/RadioButton';
import CustomSelect from '../../reusable/Selects/Select';

const steps = ['assessment', 'link'];

const AdministerManagedGroup = ({ onClose, group }) => {
  const user = useSelector((state) => state.user.user);
  const languages = useSelector(selectAvailableLanguages);
  const groupedAssessments = useSelector(selectUnsupervisedAssessments);
  const categories = useSelector(selectCategories);

  const availableBiodataTemplates = useSelector(selectAvailableMappedBiodataTemplates);

  const [activeCategory, setActiveCategory] = useState(categories.length ? categories[0] : null);
  const [assessments, setAssessments] = useState(groupedAssessments ? groupedAssessments[activeCategory] : []);
  const dispatch = useDispatch();
  const { step, goNext } = useStep({ steps });

  const [language, setLanguage] = useState(findLanguage(languages, user && user.distributorDefaultLanguageID));
  const [assessment, setAssessment] = useState(findAssessment(assessments, language));
  const [expiryDate, setExpiryDate] = useState(setHours(setMinutes(addDays(new Date(), 1), 0), 18));
  const [isLoading, setLoadingState] = useState(false);

  const [link, setLink] = useState(null);
  const [isUrlCopied, setCopiedState] = useState(false);
  const hasDefaultTemplate = availableBiodataTemplates.find(
    (template) => template.isDefault === true && template.value !== 0,
  );
  const [biodataTemplate, setBiodataTemplate] = useState(hasDefaultTemplate || availableBiodataTemplates[0]);

  useEffect(() => {
    if (languages.length) setLanguage(findLanguage(languages, user && user.distributorDefaultLanguageID));
  }, [languages]);

  useEffect(() => {
    if (categories.length) {
      setActiveCategory(categories[0]);
    }
  }, [categories]);
  useEffect(() => {
    if (groupedAssessments) {
      const assessments = groupedAssessments[activeCategory];
      setAssessments(assessments);
      setAssessment(findAssessment(assessments, language));
    }
  }, [groupedAssessments, activeCategory, language]);

  const onModalClose = () => {
    onClose();
  };
  const onLanguageChange = (data) => {
    setLanguage(data);
  };

  const onCategoryChange = (option) => {
    setActiveCategory(option.value);
    setAssessment(findAssessment(assessments, language));
  };
  const onAssessmentValueChange = (item) => {
    setAssessment(item);
  };

  const onBiodataTemplateChange = (item) => {
    setBiodataTemplate(item);
  };

  const copyLink = () => {
    copy(link);
    setCopiedState(true);
  };

  const onGenerateLink = () => {
    const data = {
      batteryID: assessment.batteryID || -1,
      langID: language.value,
      testID: assessment.testID,
      expiryDateUtc: zonedTimeToUtc(expiryDate).toISOString(),
      biodatatemplateid: biodataTemplate.value,
    };
    setLoadingState(true);
    dispatch(
      generateGroupLink(group.groupID, data, (data, err) => {
        if (!err) {
          setLink(data.url);
          goNext();
        }
        if (err) {
          createToastNotification({
            type: 'error',
            message: `${I18n.t('groupLinkErrorToast')}.`,
          });
        }
        setLoadingState(false);
      }),
    );
  };

  const availableAssessments = useAvailableTests({
    language,
    assessments,
  });

  const assessmentsOptions = useMemo(() => {
    return assessments ? createTestsOptions(availableAssessments) : [];
  }, [availableAssessments]);

  const assessmentWithLabel = useMemo(() => {
    if (assessment) {
      return assessment.label ? assessment : createTestsOptions([assessment])[0];
    }
    return null;
  }, [assessment]);

  const categoriesOptions = categories.map((category) => ({ name: category, value: category }));
  const activeCategoryOption = { name: activeCategory, value: activeCategory };

  return (
    <StyledModal isVisible onClose={onModalClose} title={I18n.t('Generate an open group link')}>
      <Spinner isLoading={isLoading} />
      <Steps steps={steps} stepIndex={step} />
      {steps[step] === 'assessment' ? (
        <AssessmentContainer>
          <StyledCustomSelect
            options={languages}
            placeholder={I18n.t('Language')}
            selectProps={{ value: language, onChange: onLanguageChange }}
          />
          <AssessmentTypes>
            {categoriesOptions.map((item) => (
              <RadioOption
                key={item.value}
                isActive={item.value === activeCategoryOption.value}
                onClick={() => onCategoryChange(item)}
              >
                <RadioButton isChecked={item.value === activeCategoryOption.value} readOnly />
                <span>{I18n.t(item.name)}</span>
              </RadioOption>
            ))}
          </AssessmentTypes>
          <StyledCustomSelect
            options={assessmentsOptions}
            placeholder={I18n.t(activeCategoryOption.name)}
            selectProps={{
              value: assessmentWithLabel,
              onChange: onAssessmentValueChange,
            }}
          />
          <InfoSelectWrapper>
            <StyledCustomSelect
              options={availableBiodataTemplates}
              placeholder={I18n.t('Bio data template')}
              selectProps={{
                value: biodataTemplate,
                onChange: onBiodataTemplateChange,
              }}
            />
            <StyledQuestionIcon data-tip data-for="biodata" />
            <StyledTooltip id="biodata">{I18n.t('biodataTooltip')}</StyledTooltip>
          </InfoSelectWrapper>
          <DateTimeSelect
            onDateChange={(date) => setExpiryDate(date)}
            inputName={I18n.t('Expiry date')}
            minDate={new Date()}
            date={expiryDate}
          />
        </AssessmentContainer>
      ) : (
        <LinkContainer>
          <MiniTitle>{I18n.t('The link has been successfully generated')}</MiniTitle>
          <LinkWrapper>
            <CustomLink>{link}</CustomLink>
            <Button variant="secondary" handler={copyLink} isLinkCopied={isUrlCopied} wide>
              <StyledCopyLinkIcon isLinkCopied={isUrlCopied} />
              {isUrlCopied ? I18n.t('Copied') : I18n.t('Copy link')}
            </Button>
          </LinkWrapper>
        </LinkContainer>
      )}

      {steps[step] === 'assessment' && (
        <ButtonWrapper>
          <Button handler={onGenerateLink} disabled={!assessment || !language}>
            {I18n.t('Generate')}
          </Button>
        </ButtonWrapper>
      )}
    </StyledModal>
  );
};

AdministerManagedGroup.propTypes = {
  onClose: func.isRequired,
  group: object.isRequired,
};

const StyledModal = styled(Modal)`
  min-height: 50rem;
  min-width: 75rem;
  width: 75rem;
  display: flex;
  flex-direction: column;
`;

const AssessmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  flex-grow: 1;
`;

const AssessmentTypes = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;

const RadioOption = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  user-select: none;
  margin-bottom: 0.5rem;

  :hover {
    cursor: pointer;
  }

  span {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 0.5rem;
    color: ${(props) => (props.isActive ? props.theme.colors.lightBlue : props.theme.colors.darkBlue2)};
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: flex-end;
`;

const Button = styled(CustomButton)`
  width: ${(props) => (props.wide ? '16rem' : '14rem')};
  text-transform: uppercase;

  ${(props) =>
    props.isLinkCopied &&
    css`
      border-color: ${(props) => props.theme.colors.green};
      color: ${(props) => props.theme.colors.green};
      :hover {
        border-color: ${(props) => props.theme.colors.green};
        color: ${(props) => props.theme.colors.green};
      }
    `}
`;

const LinkContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MiniTitle = styled.h4`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.medium};
  margin-bottom: 2rem;
`;
const StyledCopyLinkIcon = styled(CopyLinkIcon)`
  margin-right: 1rem;

  ${(props) =>
    props.isLinkCopied &&
    css`
      path {
        fill: ${(props) => props.theme.colors.green};
      }
    `}
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55%;
`;

const CustomLink = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSizes.normal};
  letter-spacing: 1.5px;
  color: ${(props) => props.theme.colors.darkBlue2};
`;

const StyledTooltip = styled(ReactTooltip)`
  width: 30rem;
`;

const StyledCustomSelect = styled(CustomSelect)`
  width: 95%;
`;

const StyledQuestionIcon = styled(QuestionIcon)`
  margin-bottom: 20px;
`;

const InfoSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default AdministerManagedGroup;
