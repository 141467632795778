import React from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';
import { startOfTomorrow } from 'date-fns';
import { errorMessages } from '../../../../constants/errorMessages';
import ProjectModalFooter from '../ProjectModalFooter';
import CustomButton from '../../../reusable/Buttons/Button';
import CustomInput from '../../../reusable/FormComponents/Input';
import CustomTextArea from '../../../reusable/FormComponents/TextArea';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right-thin.svg';
import DateTimeSelect from '../../../reusable/Selects/DateTimeSelect';
import { saveProjectInfoData } from '../../../../store/projects/actions';

const InfoStep = ({ goPrev, goNext }) => {
  const initialData = useSelector((state) => state.projects.createAssessmentProject.info);
  const dispatch = useDispatch();
  const { values, handleChange, setFieldError, handleSubmit, errors, touched, setFieldValue } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      projectName: initialData.projectName,
      projectDescription: initialData.projectDescription,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      projectName: Yup.string()
        .required(errorMessages.inputField)
        .max(50, errorMessages.tooLong(50)),
      projectDescription: Yup.string().max(300, errorMessages.tooLong(300)),
      deadline: Yup.date()
        .nullable()
        .required(I18n.t('This field is required')),
    }),
    onSubmit: (values) => {
      dispatch(saveProjectInfoData(values));
      goNext();
    },
  });

  const onInputChange = (name) => (e) => {
    handleChange(e);
    setFieldError(name, '');
  };

  const onDeadlineChange = (date) => {
    setFieldValue('deadline', date);
    setFieldError('deadline', '');
  };

  return (
    <Container>
      <Form>
        <Title>{I18n.t('General information')}</Title>
        <Row>
          <CustomInput
            id="projectName"
            inputName={I18n.t('Project name')}
            value={values.projectName}
            onChange={onInputChange('projectName')}
            error={errors.projectName}
          />
        </Row>
        <StyledTextArea
          inputName={I18n.t('Description')}
          name="projectDescription"
          id="projectDescription"
          value={values.projectDescription}
          onChange={onInputChange('projectDescription')}
          errors={errors}
          touched={touched}
        />
        <DateTimeSelect
          inputName={I18n.t('Deadline')}
          date={values.deadline}
          onDateChange={onDeadlineChange}
          error={errors.deadline}
          minDate={startOfTomorrow()}
          popperPlacement="top-end"
        />
      </Form>
      <Footer>
        <Button handler={goPrev} variant="secondary">
          <ArrowLeft />
          {I18n.t('Back')}{' '}
        </Button>
        <Button handler={handleSubmit}>
          {I18n.t('Next')} <ArrowRight />
        </Button>
      </Footer>
    </Container>
  );
};

InfoStep.propTypes = {
  goPrev: func.isRequired,
  goNext: func.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Title = styled.h3`
  color: ${(props) => props.theme.colors.mediumBlue};
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 600;
  margin-bottom: 1.6rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 2.4rem;
  flex-grow: 1;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.6rem;
`;

const StyledTextArea = styled(CustomTextArea)`
  height: 18rem;
  margin-bottom: 2.6rem;
`;

const Footer = styled(ProjectModalFooter)`
  justify-content: space-between;
`;

const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
  path {
    fill: ${(props) => props.theme.colors.lightBlue};
  }
`;

const Button = styled(CustomButton)`
  text-transform: uppercase;
  width: 12rem;
  justify-content: space-evenly;
`;

export default InfoStep;
