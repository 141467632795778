import { useFormik } from 'formik';
import { func } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import styled from 'styled-components';
import * as Yup from 'yup';

import { errorMessages } from '../../../../constants/errorMessages';

import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right-thin.svg';
import CustomButton from '../../../reusable/Buttons/Button';
import CustomSelect from '../../../reusable/Selects/Select';
import Spinner from '../../../reusable/Spinner';
import ProjectModalFooter from '../ProjectModalFooter';

import useAvailableTests from '../../../../hooks/useAvailableTests';
import { saveProjectAssessmentData, saveProjectReportsData } from '../../../../store/projects/actions';
import { selectCategoriesOptions, selectUnsupervisedAssessments } from '../../../../store/tests/selectors';
import { selectAvailableLanguages } from '../../../../store/user/selectors';
import { resolveAssessmentId } from '../../../../utils/assessmentsNames';
import { findLanguage } from '../../../../utils/findLanguage';
import createTestsOptions from '../../../Respondents/CreateInvite/createInviteUtils';

const AssessmentStep = ({ goPrev, goNext }) => {
  const initialData = useSelector((state) => state.projects.createAssessmentProject.assessment);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const groupedAssessments = useSelector(selectUnsupervisedAssessments);
  const languages = useSelector(selectAvailableLanguages);
  const categories = useSelector(selectCategoriesOptions);
  const [assessments, setAssessments] = useState([]);

  const { values, setFieldValue, handleSubmit, errors, setFieldError } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      language: initialData.language,
      category: initialData.category,
      assessment: initialData.assessment,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      language: Yup.object().required(errorMessages.inputField),
      category: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      assessment: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
    }),
    onSubmit: (values) => {
      dispatch(saveProjectAssessmentData(values));
      if (resolveAssessmentId(values.assessment) !== resolveAssessmentId(initialData.assessment)) {
        dispatch(
          saveProjectReportsData({
            language: null,
            reports: [],
            readyToBeSentReports: [],
            idealProfiles: {},
            norms: {},
          }),
        );
      }
      goNext();
    },
  });

  const availableAssessments = useAvailableTests({
    assessments,
    language: values.language,
  });

  const assessmentsOptions = useMemo(() => {
    return createTestsOptions(availableAssessments);
  }, [availableAssessments]);

  useEffect(() => {
    if (!categories.length || !languages.length || initialData.language) return;
    const data = {
      language: findLanguage(languages, user && user.distributorDefaultLanguageID),
      // category: categories.length ? categories[0] : null,
    };
    dispatch(saveProjectAssessmentData(data));
  }, [user, categories, languages]);

  useEffect(() => {
    if (!categories.length || !values.language || initialData.category) return;
    const preselectedCategory = categories[0];
    setAssessments(groupedAssessments[preselectedCategory.value]);
    dispatch(saveProjectAssessmentData({ category: categories[0] }));
  }, [categories, values.language]);

  useEffect(() => {
    if (!values.category || !assessmentsOptions.length || initialData.assessment) return;
    const preselectedAssessment = assessmentsOptions.find((item) => item.testID === '15FQ+');
    dispatch(saveProjectAssessmentData({ assessment: preselectedAssessment }));
  }, [values.category, assessmentsOptions.length]);

  const onCategoryChange = (category) => {
    if (category.value !== values.category?.value) setFieldValue('assessment', null);
    setFieldValue('category', category);
    setAssessments(groupedAssessments[category.value]);
    setFieldError('category', '');
  };

  useEffect(() => {
    if (values.category && !assessments.length) onCategoryChange(values.category);
  }, []);

  const onLanguageChange = (language) => {
    if (language.value !== values.language?.value) {
      setFieldValue('assessment', null);
      setFieldValue('category', null);
      setAssessments([]);
    }
    setFieldValue('language', language);
    setFieldError('language', '');
  };

  const onAssessmentChange = (assessment) => {
    setFieldValue('assessment', assessment);
    setFieldError('assessment', '');
  };

  const isLoading = !categories.length || !languages.length;

  return (
    <Container>
      <Spinner isLoading={isLoading} />
      <Form>
        <Title>{I18n.t('Assessment')}</Title>
        <Row>
          <StyledSelect
            options={languages}
            placeholder={I18n.t('Language')}
            selectProps={{ value: values.language, onChange: onLanguageChange }}
            error={errors.language}
          />
          <StyledSelect
            options={categories.map((entry) => {
              return { ...entry, label: I18n.t(entry.label) };
            })}
            placeholder={I18n.t('Category')}
            selectProps={{
              value: values.category?.label
                ? { ...values.category, label: I18n.t(values.category?.label) }
                : values.category,
              onChange: onCategoryChange,
            }}
            error={errors.category}
          />
        </Row>
        <FullRowSelect
          options={assessmentsOptions}
          placeholder={I18n.t('Assessment')}
          selectProps={{ value: values.assessment, onChange: onAssessmentChange }}
          isDisabled={!assessments.length}
          error={errors.assessment}
        />
        <Label>{I18n.t('createAssessmentBattery')}</Label>
      </Form>
      <Footer>
        <Button handler={goPrev} variant="secondary">
          <ArrowLeft />
          {I18n.t('Back')}
        </Button>
        <Button handler={handleSubmit} disabled={!values.assessment}>
          {I18n.t('Next')} <ArrowRight />
        </Button>
      </Footer>
    </Container>
  );
};

AssessmentStep.propTypes = {
  goPrev: func.isRequired,
  goNext: func.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Title = styled.h3`
  color: ${(props) => props.theme.colors.mediumBlue};
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 600;
  margin-bottom: 1.6rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 2.4rem;
  flex-grow: 1;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSelect = styled(CustomSelect)`
  width: 34rem;
`;

const FullRowSelect = styled(CustomSelect)`
  width: 100%;
  margin-bottom: 0.5rem;
`;

const Label = styled.span`
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  padding-left: 1rem;
`;

const Footer = styled(ProjectModalFooter)`
  justify-content: space-between;
`;

const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
  path {
    fill: ${(props) => props.theme.colors.lightBlue};
  }
`;

const Button = styled(CustomButton)`
  text-transform: uppercase;
  width: 12rem;
  justify-content: space-evenly;
`;

export default AssessmentStep;
