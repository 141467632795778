import {
  AG_GRID_LOCALE_CN,
  AG_GRID_LOCALE_HK,
  AG_GRID_LOCALE_IT,
  AG_GRID_LOCALE_UA,
  AG_GRID_LOCALE_ES,
} from '@ag-grid-community/locale';
import storageService from '../services/storageService';

export function dateValueFormatter(params) {
  if (params.value && params.value !== 'Invalid Date') {
    const date = new Date(params.value);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  return '';
}

export function dateWithoutTimeComparator(filterDate, cellValue) {
  if (!cellValue) return -1;

  const cellDate = new Date(cellValue);
  const filterDateObj = new Date(filterDate);

  cellDate.setHours(0, 0, 0, 0);
  filterDateObj.setHours(0, 0, 0, 0);

  if (cellDate < filterDateObj) return -1;
  if (cellDate > filterDateObj) return 1;
  return 0;
}

const localeMap = {
  ch: AG_GRID_LOCALE_CN,
  chtr: AG_GRID_LOCALE_HK,
  it: AG_GRID_LOCALE_IT,
  ua: AG_GRID_LOCALE_UA,
  es: AG_GRID_LOCALE_ES,
  'es-latam': AG_GRID_LOCALE_ES,
};

const lang = storageService.getItem('psLang');
const currentLocaleText = localeMap[lang];

export { localeMap, currentLocaleText };
