import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { dateValueFormatter, dateWithoutTimeComparator } from '../../lib/ag-grid';
import PageWrapper from '../PageWrapper';
import {
  fetchProjects,
  deleteProjects,
  setSelectedProjects,
  updateExpiryProjectInvite,
} from '../../store/projects/actions';
import projectsTableActions from '../../table/tableActions/projects';
import projectsBulkActions from '../../table/bulkActions/projects';
import TableTabs from '../reusable/TableTabs';
import * as actions from '../../table/actionsList';
import useModals from '../../hooks/useModals';
import BulkActionsSidebar from '../BulkActionsSidebar';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import ConfirmationModal from '../reusable/ConfirmationModal';
import AddProjectsModal from './AddProjectsModal';
import { selectProjectsTabsCounts } from '../../store/projects/selector';
import ResetExpiryDate from '../Invites/ResetExpiryDate';
import { ReactComponent as AppraisalLogo } from '../../assets/icons/360p.svg';
import { ReactComponent as AssessmentLogo } from '../../assets/icons/assesment.svg';
import StatusCell, { statusMap } from '../reusable/StatusCell';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const Projects = () => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const selectedProjects = useSelector((state) => state.projects.selectedProjects);
  const { totalCount, totalAssessment, total360 } = useSelector(selectProjectsTabsCounts);
  const [shouldResetPage, setShouldResetPageStatus] = useState(false);

  const gridRef = useRef();

  const [expiryDate, setExpiryDate] = useState(new Date());

  const modals = [actions.ADD_PROJECT, actions.DELETE_PROJECTS, actions.RESET_DEADLINE];

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedProjects });

  const { modalsState, openModal, closeModal } = useModals(modals);
  const onProjectsDelete = () => {
    dispatch(
      deleteProjects(
        selectedProjects.map((p) => ({ type: p.type, projectId: p.projectId })),
        () => {
          closeModal(actions.DELETE_PROJECTS);
        },
      ),
    );
  };

  // table actions
  const tableHandlers = {
    [actions.ADD_PROJECT]: () => {
      dispatch(setSelectedProjects([]));
      openModal(actions.ADD_PROJECT);
    },
  };

  const tableActions = useMemo(() => {
    return projectsTableActions.map((item) => ({ ...item, handler: tableHandlers[item.name] }));
  }, []);

  // bulk and row actions
  const navigateToProjectDetails = (project) => {
    const url = project.type === 0 ? `/projects/360/${project.projectId}` : `/projects/assessment/${project.projectId}`;
    dispatch(push(url));
    dispatch(setSelectedProjects([]));
  };

  // bulk and row actions
  const navigateToEdit = (project) => {
    dispatch(setSelectedProjects([project]));
    openModal(actions.ADD_PROJECT);
  };

  const bulkActionsHandler = {
    [actions.RESET_DEADLINE]: () => openModal(actions.RESET_DEADLINE),
    [actions.EDIT_PROJECT_DETAILS]: () => navigateToEdit(selectedProjects[0]),
    [actions.DELETE_PROJECTS]: () => openModal(actions.DELETE_PROJECTS),
  };

  const bulkActions = useMemo(() => {
    return projectsBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandler[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedProjects) : item.isDisabled,
    }));
  }, [selectedProjects]);

  const [isLoading, setLoadingStatus] = useState(true);

  const tabs = useMemo(() => {
    return [
      { name: 'all', label: `${I18n.t('All')} (${totalCount})`, value: 0 },
      { name: '360', label: `${I18n.t('360 Appraisal')} (${total360})`, value: 2 },
      { name: 'assessments', label: `${I18n.t('IAssessments')} (${totalAssessment})`, value: 1 },
    ];
  }, [totalCount, total360, totalAssessment]);

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  const fetchData = useCallback(
    ({ tableData, shouldReset, showSpinner, tableCallback }) => {
      if (showSpinner) setLoadingStatus(true);
      dispatch(
        fetchProjects(tableData, activeTab.value, {
          callback: () => {
            setLoadingStatus(false);
            // eslint-disable-next-line no-unused-expressions
            tableCallback && tableCallback();
          },
          shouldReset,
        }),
      );
    },
    [activeTab.value],
  );

  const onValueChange = (value) => {
    setExpiryDate(value);
  };

  useEffect(() => {
    setExpiryDate(selectedProjects[0] ? new Date(selectedProjects[0].deadline) : new Date());

    return () => {};
  }, [selectedProjects.length]);

  const handleExpiryDateUpdates = () => {
    const { name, description, status, projectId } = selectedProjects[0];
    const data = {
      name,
      description,
      deadline: expiryDate.toISOString(),
      status,
      projectId,
    };
    dispatch(updateExpiryProjectInvite(data), closeModal(actions.RESET_DEADLINE));
  };

  const projectTypesMap = {
    0: I18n.t('360 Appraisal'),
    1: I18n.t('Assessment'),
  };

  const ProjectCellStyled = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;

    svg {
      height: 18px;
      width: auto;
      margin-right: 8px;

      path {
        fill: #1baae1;
      }
    }
  `;

  const ProjectCell = ({ data }) => {
    return (
      <ProjectCellStyled>
        {data.type === 0 ? <AppraisalLogo /> : <AssessmentLogo />}
        <span>{I18n.t(projectTypesMap[data.type])}</span>
      </ProjectCellStyled>
    );
  };

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      dispatch(setSelectedProjects(selectedData));
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      field: 'name',
      headerName: I18n.t('Name'),
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'description',
      headerName: I18n.t('Description'),
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      headerName: I18n.t('Type'),
      valueGetter: ({ data }) => I18n.t(projectTypesMap[data.type]),
      cellRenderer: ({ data }) => <ProjectCell data={data} />,
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'status',
      headerName: I18n.t('Status'),
      cellRenderer: ({ data }) => <StatusCell row={{ original: data }} />,
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      filterParams: {
        valueFormatter: (params) => statusMap.find((s) => s.value === params.value)?.title || String(params.value),
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'totalCount',
      Header: I18n.t('Sessions'),
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'creationDate',
      headerName: I18n.t('Creation Date'),
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      sort: 'desc',
      valueGetter: (params) => {
        return new Date(params.data.creationDate);
      },
      valueFormatter: dateValueFormatter,
      filterParams: {
        maxNumConditions: 1,
        comparator: dateWithoutTimeComparator,
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'deadline',
      headerName: I18n.t('Deadline'),
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      valueGetter: (params) => {
        return new Date(params.data.deadline);
      },
      valueFormatter: dateValueFormatter,
      filterParams: {
        maxNumConditions: 1,
        comparator: dateWithoutTimeComparator,
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  const handleRowClick = (event) => {
    if (
      event.event?.target?.closest('[col-id="ag-Grid-ControlsColumn"]')?.getAttribute('col-id') !==
      'ag-Grid-ControlsColumn'
    )
      navigateToProjectDetails(event.data);
  };

  useEffect(() => {
    fetchData({ shouldReset: true, showSpinner: true });
  }, [activeTab]);

  useEffect(() => {
    if (!isLoading && shouldResetPage && gridRef.current) {
      setLoadingStatus(true);
      fetchData({
        shouldReset: true,
        showSpinner: true,
        tableCallback: () => {
          if (gridRef.current?.api) {
            gridRef.current.api.setGridOption('rowData', projects);
            gridRef.current.api.refreshCells();
          }
        },
      });
      setShouldResetPageStatus(false);
    }
  }, [shouldResetPage]);

  return (
    <PageWrapper title={I18n.t('Projects')} buttons={tableActions} style={{ overflowY: 'auto' }}>
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <TableTabs tabs={tabs} activeTab={activeTab} clickHandler={onTabClick} />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          ref={gridRef}
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : projects}
          rowSelection={rowSelection}
          onRowClicked={handleRowClick}
          onFilterChanged={handleFilterChanged}
          loading={isLoading ? true : false}
        />
      </div>
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_PROJECTS]}
        title={I18n.t('Delete project')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedProjects.length > 1 ? I18n.t('selected projects?') : I18n.t('this project?')
        }`}
        onClose={() => closeModal(actions.DELETE_PROJECTS)}
        onConfirm={onProjectsDelete}
        caution
      />
      {modalsState[actions.ADD_PROJECT] && (
        <AddProjectsModal
          onClose={() => closeModal(actions.ADD_PROJECT)}
          selectedProjects={selectedProjects}
          onReset={setShouldResetPageStatus}
        />
      )}
      {modalsState[actions.RESET_DEADLINE] && (
        <ResetExpiryDate
          onClose={() => closeModal(actions.RESET_DEADLINE)}
          onConfirm={handleExpiryDateUpdates}
          expiryDate={expiryDate}
          onValueChange={onValueChange}
        />
      )}
    </PageWrapper>
  );
};

export default Projects;
