const translations = {
  About: 'Acerca de',
  Launch: 'Iniciar',
  Tutorial: 'Tutorial',
  SignIn: 'Ingresar',

  // Login Page (Login.js)
  Email: 'Correo electrónico',
  Submit: 'Enviar',
  LoginNext: 'Ingresar',
  LoginSubmit: 'Continuar',
  SwitchUser: 'Cambiar usuario',
  ForgotPassword: 'Olvidé mi contraseña',
  Password: 'Contraseña',
  'Email is required': 'El correo electrónico es requerido',
  'Password is required': 'La contraseña es requerida',
  'Should be a proper email address': 'Debe ser una correo elecrónico válido',

  // (AddCompetencyCategory.js)
  Save: 'Guardar',
  'Enter Name': 'Ingrese su nombre',
  'Add competency category': 'Añadir Categoría de Competencia',
  'Add description for the category': 'Añadir descripción de la categoría',

  // (CompetencyGroupNavbar.js)
  'Main info': 'INFORMACIÓN PRINCIPAL',

  // (AddCompetencyGroup.js)
  Add: 'Añadir',
  Next: 'Siguiente',
  'Name is required': 'El nombre es requerido',
  'Add 360 assessment': 'Añadir evaluación 360',
  'Custom competencies': 'Competencias personalizadas',
  'Inbuilt competencies': 'Competencias por defecto',

  // (AddCompetencyItem.js)
  Edit: 'Editar',
  'Competency description': 'Descripción de competencia',

  // (AddCompetencyQuestion.js)
  'Add question': 'Adicionar pregunta',

  // (AddCompetencyQuestionForm.js)
  'Item text': 'Texto del ítem',
  'Free field': 'Campo libre',
  'Multiple choice': 'Opción múltiple',
  'Add another language': 'Añadir otro idioma',

  // (AddCreditsFailureModal.js)
  'Credits addition error': 'Error de suma de créditos',
  'Try again': 'Intentar de nuevo',

  //  (AddCreditsSuccessModal.js)
  Done: 'Finalizado',
  'Adding credits': 'Adicionando créditos',
  'Credits successfully added!': 'Créditos adicionados con éxito',
  'Now your credit balance is:': 'Ahora su saldo de créditos es',

  // (AddCredits.js)
  Cancel: 'Cancelar',
  'Add credits': 'Añadir créditos',
  'Your credits balance': 'Su saldo de créditos',
  'How many credits do you want to add?': '¿Cuántos créditos quiere agregar?',
  'Purchase ID': 'ID de compra',
  'Error occurred during an attempt to add credits to your account':
    'Se produjo un error durante un intento de añadir créditos a su cuenta',
  'Credits amount': 'Cantidad de creditos',
  // Edit Respondent (Index.js)
  Groups: 'Grupos',
  Invites: 'Invitaciones',
  Reports: 'Reportes',
  Assessments: 'Evaluaciones',
  IAssessments: 'Evaluaciones',
  SAssessments: 'Evaluaciones',
  'Respondent Information': 'Información del encuestado',

  // Edit Respondent (AddTagModal.js)
  'Add tags': 'Añadir etiquetas',
  'Select tags': 'Seleccionar etiquetas',

  // Edit Respondent (Information.js)
  Sex: 'Género',
  BioTitle: 'Title',
  Title: 'Título',
  Sector: 'Sector',
  Company: 'Compañía',
  Industry: 'Industria',
  Education: 'Educación',
  Reference: 'Referencia',
  Ethnicity: 'Etnia',
  'Job area': 'Área de trabajo',
  jobarea: 'Área de trabajo',
  'First Name': 'Primer nombre',
  'Family Name': 'Apellido',
  'Save changes': 'Guardar cambios',
  'Date of Birth': 'Fecha de nacimiento',
  'First Language': 'Primer idioma',
  'Additional Info': 'Información adicional',
  'Main Information': 'Información principal',

  // Edit Respondent (RespondentGroups.js)
  group: 'Grupo',
  'Remove from groups': 'Remover de los grupos',
  'Are you sure you want to remove respondent from selected group(s)':
    '¿Está seguro de que desea eliminar al encuestado de los grupos seleccionados?',

  // Edit Respondent (RespondentInvites.js)
  invite: 'Invitar',
  'Invite Deletion': 'Eliminar invitación',

  // Edit Respondent (RespondentReports.js)
  'Report deletion': 'Eliminar reporte',
  'Are you sure you want to delete selected report?': '¿Está seguro de que desea eliminar el reporte seleccionado?',

  // Edit Respondent (RespondentTests.js)
  'Test deletion': 'Eliminar prueba',
  'Are you sure you want to delete selected test?': '¿Está seguro de que desea eliminar la prueba seleccionada?',

  // Edit Respondent (TagsManagement.js)
  Tags: 'Etiquetas',
  '+ Add tag': '+ Añadir etiquetas',
  'Members count': 'Conteo de integrantes',
  member: 'integrante',

  // Groups (AddNewGroup.js)
  Create: 'Crear',
  'Group name': 'Nombre del grupo',
  'Add new group': 'Añadir nuevo grupo',
  'Group description': 'Descripción del grupo',

  // Groups (GroupHeader.js)
  Type: 'Tipo',
  'Creation date': 'Fecha de creación',

  // Groups (Groups.js)
  List: 'Lista',
  Respondents: 'Encuestados',
  'Delete group': 'Eliminar grupo',
  'Are you sure you want to delete this group(s)': '¿Está seguro de que desea eliminar este grupo(s)?',

  // Manage active links
  'Manage active links': 'Administra links activos',
  'Manage active group links': 'Administra links de grupos activos',

  // Groups request report
  'Select common assessment / battery': 'Selecciona questionario / bateria común',
  reports: 'reportes',

  // Groups generate open group link
  link: 'link',
  Generate: 'Generar',
  'The link has been successfully generated': 'El link ha sido generado correctamente',

  // Invites (Invites.js)
  'Delete invite': 'Eliminar invitación',
  'selected invites?': 'Invitaciones seleccionadas',
  'this invite?': '¿Esta invitación?',

  // LaunchScreen (LaunchScreen.js)
  Language: 'Idioma',
  language: 'Idioma',

  // Notifications (Notifications.js)
  Results: 'Resultados',
  notification: 'Notifación',
  Notifications: 'Notificaciones',

  // Projects (AddProjectsModal.js)
  Min: 'Min',
  Info: 'Información',
  Raters: 'Evaluadores',
  Deadline: 'Fecha límite',
  'Peer Alias': 'Alias del colega',
  Description: 'Descripción',
  'Project Name': 'Nombre del Proyecto',
  'All standard': 'Todo estándar',
  'Edit Project': 'Editar Proyecto',
  'Manager Alias': 'Alias del Administrador',
  'Project Alias': 'Alias del Proyecto',
  'Direct Report Alias': 'Alias de Informe Directo',
  'Choose Project Type': 'Elegir tipo de proyecto',
  '360 assessment has to be fully supported in at least 1 language':
    'La evaluación 360 debe ser totalmente compatible en al menos 1 idioma',
  '360 Survey': 'Encuesta 360',
  '360 Surveys': 'Encuestas 360',
  'Other Alias': 'Otros alias',
  deadlineWarning: 'Fecha, cuando los reportes serán generados',
  createAssessmentBattery:
    'Para seleccionar multiples questionarios, por favor crea una bateria en configuraciones y seleccionala de la categoría favoritos',
  manualInviteDesc:
    'Invitaciones pueden ser enviadas manualmente desde la vista de encuestados la configuración del proyecto.',
  autoInviteDesc: 'La invitación será enviada cada vez que se agrege un nuevo encuestado al proyecto.',
  scheduledInviteDesc:
    'Las invitaciones serán enviadas en la fecha seleccionada. Si se quiere agregar un nuevo enuestado después de esta\n' +
    'fecha, las invitaciones serán enviadas automaticamente cuando se agregen.',
  daysLeft: '%{days} días restantes',

  // Projects (projectList.js)
  'Add assessment project': 'Añadir Proyecto de Evaluación',
  'Add Assessment Project': 'Añadir Proyecto de Evaluación',
  'Add 360 Appraisal Project': 'Añadir Proyecto de Evaluación 360',
  'Manage your assessment projects here Plan and implement the right assessment approach and tests Effectively and consistently administrate, communicate and manage the whole assessment process using this user friendly tool':
    'Gestione sus proyectos de evaluación aquí. Planificar e implementar el enfoque de evaluación y las pruebas adecuadas. Administra, comunica y gestiona de forma eficaz y coherente todo el proceso de evaluación con esta herramienta fácil de usar.',
  'Manage your entire 360 appraisal process here Easy to use, allowing appraisals to be set up in minutes and results generated instantly Choose from Psytech existing competency framework or fully customise your 360 project':
    'Gestionar todo su proceso de evaluación 360 aquí. Fácil de usar, lo que permite configurar las valoraciones en minutos y los resultados generados al instante. Elija entre el marco de competencias existente de Psytech&apos;o personalice completamente su proyecto 360.',

  // Projects (Projects.js)
  All: 'Todos',
  Project: 'Proyecto',
  Projects: 'Proyectos',
  'this project?': '¿Este proyecto?',

  '360 Appraisal': 'Evaluación 360',
  'Delete project': 'Eliminar Proyecto',
  'selected projects?': '¿Proyectos Seleccionados?',
  'Are you sure you want to delete': 'Esta seguro que lo desea eliminar',

  // Raters (Raters.js)
  rater: 'Evaluador',
  'Delete raters': 'Eliminar evaluadores',
  'Loading raters': 'Cargando Evaluadores',
  'selected raters?': '¿Evaluadores seleccionados?',
  'this rater?': 'este evaluador?',

  // Raters (AddRatersModal.js)
  Role: 'Rol',
  Other: 'Otros',
  Peers: 'Pares',
  Confirm: 'Confirmar',
  Managers: 'Gerentes',
  'No email': 'Sin Correo Electrónico',
  'Add raters': 'Añadir Evaluador',
  'Direct reports': 'Reportes Directos',
  'Search existing records': 'Buscar Registros Existentes',
  'Make sure selected record has a valid email address added':
    'Asegúrese de que el registro seleccionado tenga una dirección de correo electrónico válida agregada',
  Session: 'Sesión',
  'Session for': 'Sesión para',

  // Raters Invites (columns.js)
  Name: 'Nombre',
  PersonName: 'Nombre',
  'Expiry Date': 'Fecha de expiración',
  'Invite status': 'Estado de Invitación',
  'Email Address': 'Dirección de correo electrónico',
  'Hide expired': 'Ocultar Expiración',

  // Raters Invites (Invites.js)
  'chosen invite(s)?': '¿Invitación(es) escogida(es)?',
  'selected invite(s)?': 'Invitación Seleccionada',
  'Loading invites': 'Cargando Invitaciones',
  missingEmailError: 'Cuenta de correo faltante o no válida en el registro(s) de encuentado(s)',

  // Raters Reports (Reports.js)
  Pending: 'Pendiente',
  Unread: 'No leído',
  Viewed: 'Visto',
  Error: 'Error',
  'Support ticket raised': 'Error. Boleto de apoyo generado.',
  'Loading reports': 'Cargando Reportes',

  // Raters Results (Results.js)
  result: 'Resultado',

  // Reports Reports (Reports.js)
  'Are you sure you want to delete selected report(s)?':
    '¿Está seguro de que desea eliminar los reportes seleccionados?',

  // RequestReport360 (RequestReport360.js)
  'Report Request for': 'Solicitud de reporte para',
  'Report Request': 'Solicitud de reporte',
  credits: 'Créditos',
  'Total Credits:': 'Total de Créditos',
  Request: 'Solicitud',
  Self: 'Evaluado',

  // Respondents AddRespondentModal (AddRespondentForm.js)
  'Company Name': 'Nombre de compañía',
  'Job Area': 'Área de trabajo',
  Remove: 'Remover',

  // Respondents AddRespondentModal (AddRespondentModal.js)
  'Add respondent': 'Añadir encuestado',
  'Main Details': 'Detalles principales',
  'Extra Details': 'Detalles extra',
  'Group Membership': 'Membresía grupal',

  // Respondents AddRespondentModal (GroupsTab.js)
  'Select groups': 'Grupo seleccionado',

  // Respondents AdministerAssessment (index.js)
  Start: 'Comenzar',
  Administer: 'Administrar',
  to: 'Para',
  questionnaires: 'cuestionarios',
  reasoning: 'razonamiento',
  solutions: 'soluciones',
  batteries: 'baterias',
  favourites: 'favoritos',
  other: 'otros',
  // Respondents (AnswersStep.js)
  'Test:': 'Prueba',

  // Respondents AnswerSheet (index.js)
  Previous: 'Anterior',
  'Administer answer sheet for': 'Administrar hoja de respuesta a',
  answers: 'respuestas',
  Respondent: 'Encuestados',

  // Respondents (AssessmentStep.js)
  'Welcome text': 'Texto de bienvenida',
  'Expiry date': 'Fecha de expiración',

  // Invite (index.js)
  'Invite respondents': 'Invitar a los encuestados',
  Invite: 'Invitar',
  'Create invite and go next': 'Crear invitación y seguir',
  Finish: 'Finalizar',
  'You are sending an invitation for:': 'Estás enviando una invitación para:',
  in: 'en',
  invitation: 'invitaciones',
  OR: 'O',

  // Invite (InvitationStep.js)
  Immediately: 'Inmediatamente',
  Later: 'Más tarde',
  'Email address is missing or not valid in respondent record':
    'Falta o no es válida la dirección de correo electrónico en el registro del encuestado',
  'Copy link': 'Copiar vínculo',
  Copied: 'Copiado',
  'Copy and share generated link': 'Copiar y compartir enlace generado',
  'Send invitation link via email to:': 'Enviar enlace de invitación por correo electrónico a:',
  'Send invitation link via email for respondents':
    'Enviar enlace de invitación por correo electrónico a %{respondentsCount} encuestados',
  'Email template': 'Plantilla de correo electrónico',
  'When do you want to send an invite?': '¿Cuándo quieres enviar una invitación?',
  'Email invite': 'Invitación por correo electrónico',
  'Email sent': 'Email enviado',

  // RequestReportModal (index.js)
  'Request report': 'Solicitar Reporte',
  'Request report for': 'Solicitar Reporte para',
  for: 'para',
  'Request Reports': 'Solicitar Reportes',
  'Common tests: ': 'Pruebas Comunes',
  'At least 1 assessment must be common': 'Al menos 1 (una) evaluación debe ser común',
  'Selected respondents': 'Encuestas seleccionadas',
  tests: 'Pruebas',
  'Assessment/Battery': 'Tarea/Bateria',
  'Total credits:': 'Creditos totales',

  // RequestReportModal (ReportsList.js)
  'Available Reports': 'Informes disponibles',
  Norm: 'Norma',
  Options: 'Opciones',
  'Ideal Profile': 'Perfil ideal',

  // Respondents (AddToGroup.js)
  'Add to group': 'Añadir al grupo',
  'Add respondent(s) to group': 'Añadir encuestado(s) al grupo',

  // Respondents (ManageTagsModal.js)
  'Manage respondents tags': 'Administrar etiquetas de encuestados',
  'Select from existing tags or add new': 'Seleccionar de etiquetas existentes o añadir nuevas',
  'Add tag to selected respondent(s)': 'Agregar etiqueta a encuestado(s) seleccionado(s)',
  'Remove tag from selected respondent(s)': 'Eliminar etiqueta de encuestado(s) seleccionado(s)',

  // Respondents  (RespondentDropdown.js)
  'Add new respondent': 'Añadir nuevo encuestado',
  'Import respondents from file': 'Importar encuestados del archivo',
  'Edit respondent': 'Editar encuestado',
  'Delete respondent': 'Eliminar encuestado',

  // Respondents  (Respondents.js)
  'Delete respondents from group': 'Eliminar a los encuestados del grupo',
  'Loading respondents': 'Cargando a los encuestados',
  'Are you sure you want to remove': '¿Está seguro de que desea eliminar?',
  'from the group?': '¿Desde grupo?',
  'All respondents are selected': 'Todos los encuestados de %{respondentsCount} están seleccionados',
  'Clear selection': 'Limpiar selección',
  'All respondents on this page are selected':
    'Todos los encuestados de %{respondentsCount} de esta página están seleccionados',
  'Select all respondents in this result': 'Selecciona todos los encuestados de %{respondentsCount} en este resultado',

  // Reusable Accordion (Accordion.js)
  INBUILT: 'INTEGRACIÓN',

  // Reusable Accordion (CompetencyGroupsAccordion.js)
  'Collapse all': 'Colapsar todo',
  'Expand all': 'Desplegar todo todo',

  // Reusable  Filter (DateInputGroup.js)
  'Date range': 'Rango de fechas',

  // Reusable  Filter (Filter.js)
  Filter: 'Filtro',
  'Clear all': 'Borrar todo',
  Apply: 'Aplicar',

  // Reusable Filter (TextInputGroup.js)
  'Filter type': 'Tipo de filtro',

  // Reusable Filter (Search.js)
  Search: 'Buscar',

  // Reusable Badges (CustomBadge.js)
  CUSTOM: 'PERSONALIZADO',

  // Reusable TextEditor (Editor.js)
  'Insert variable': 'Insertar variable',

  // Reusable  (ConfirmationModal.js)
  No: 'No',
  Yes: 'Si',

  // Reusable  (DatePicker.js)
  'Select the date': 'Seleccione la fecha',

  // Reusable  (DateTimeSelect.js)
  'Time:': 'Hora:',

  // Reusable  (ErrorMessage.js)
  'Oops, something went wrong': 'Uy! Algo salió mal.',
  'Please try again': 'Por favor Inténtalo de nuevo.',
  Ok: 'Ok',

  // Reusable (Quantity.js)
  Quantity: 'Cantidad',

  // Reusable (StatusCell.js)
  'In Progress': 'En progreso',
  'On Hold': 'En espera',
  Canceled: 'Cancelada',
  Completed: 'Terminado',

  // Session SessionInvite (index.js)
  'Send invite to raters': 'Enviar invitación a los evaluadores',
  'Send invite to raters who have not yet completed': 'Enviar invitación a los evaluadores que aún no han completado',
  'Send invite to raters who have not yet been invited':
    'Enviar invitación a evaluadores que aún no han sido invitados',
  'Send invite to raters who have already been invited but haven&apos;t yet completed':
    'Enviar invitación a evaluadores que ya han sido invitados pero que todavía no han completado',
  'Welcome text for Self Raters': 'Texto de bienvenida para Auto Calificadores',
  'Email template for Self Raters': 'Plantilla de correo para Auto Calificadores',
  'Welcome text for Other Raters': 'Texto de bienvenida para otros Calificadores',
  'Email template for Other Raters': 'Plantilla de correo para otros Calificadores',
  Send: 'Enviar',

  // Session SessionInvite (SessionInvitationStep.js)
  'Email body': 'Cuerpo del correo',
  'Email subject': 'Asunto del correo',
  'Invitation date': 'Fecha de la invitación',
  'Email body for Self Raters': 'Cuerpo de correo  para autoevaluadores',
  'Email body for Other Raters': 'Cuerpo del correo para otros evaluadores',
  'Email subject for Other Raters': 'Asunto del correo  para otros evaluadores',
  'Email subject for Self Raters': 'Asunto del correo electrónico para auto evaluadores',

  // Session SpecifyOwnRatersInvite (index.js)
  'Send invite to self with a request to specify their own raters':
    'Envía invitaciones con la solicitud de especificar sus propios evaluadores',

  // Session (AddSessionModal.js)
  'Sorry, session with selected respondent already exists.':
    'Disculpe, la sesión con el encuestado seleccionado ya existe',
  'Add session': 'Agregar sesión',
  'Session SELF': 'Sesión propia',
  'Search existing record to select session SELF': 'Buscar registro existente para seleccionar sesión propia',

  // Session (SessionHeader.js)
  'All Standard': 'Toda estándar',
  '360 Assessment:': 'Evaluación 360:',
  'Type:': 'Tipo:',
  'Status:': 'Estado:',
  'Deadline:': 'Fecha límite:',

  // Session (Sessions.js)
  Sessions: 'Sesiones',
  session: 'sesión',
  'Loading sessions': 'Cargando sesiones',
  'Delete session': 'Eliminar sesión',
  'Delete sessions': 'Eliminar sesiones',
  'this session?': '¿esta sesión?',
  'Are you sure you want to delete this project': '¿Está seguro de que quieres eliminar este proyecto?',
  'Sessions list': 'Lista de sesiones',

  // Session header (SessionHeader.js)
  'days left': 'días restantes',
  'Send invites': 'Enviar invitaciones',
  'Outstanding ratings': 'Mejores calificaciones',
  'Send reminder': 'Enviar recordatorio',
  'Sessions completed': 'Sesiones completadas',
  'Send invite': 'Enviar invitación',

  // Settings AddResponseScale (AddResponseScale.js)
  'Loading...': 'Cargando...',
  indexedResponse: 'Respuesta %{index}',

  // Settings (AddScaleForm.js)
  'Add response scale': 'Agregar escala de respuesta',
  'Edit response scale': 'Editar escala de respuesta',

  // Settings Components   (EditTitle.js)
  'Edit Name': 'Editar nombre',
  'Edit 360 assessment': 'Editar evaluación 360',

  // Settings Components  (QuestionItem.js)
  Question: 'Pregunta',
  'No translation available for': 'No hay traducción disponible para',

  // Settings Components  (ReviewCompetencies.js)
  'Loading competencies': 'Cargando de competencias',
  Competencies: 'Competencias',
  CONFIRM: 'CONFIRMAR',

  // Settings utils (ResponseScale.util.js)
  'Name is the required field': 'El nombre es el campo obligatorio',
  'All response scales should be filled': 'Deben llenarse todas las escalas de respuesta',

  // Settings (CustomCompetencies.js)
  'Are you sure you want to remove selected': '¿Estás seguro de que quieres eliminar los seleccionados?',
  Collapse: 'Colapsar',
  Expand: 'Expandir',
  '360 settings': 'Configuración 360',

  // Settings (Settings.js)
  Settings: 'Configuraciones',

  // (BulkActionsSidebar.js)
  'Bulk actions': 'Acciones masivas',

  // 360 surveys
  'Add new 360 survey': 'Agregar nueva encuesta 360',
  'Add 360 survey': 'Agregar encuesta 360',
  'Edit 360 survey': 'Editar encuesta 360',
  'Duplicate 360 survey': 'Duplicar encuenta 360',
  'Delete selected 360 survey(s)': 'Borrar encuesta 360 seleccionada',
  Sorting: 'Organizar',

  // Sidebar.js
  'Are you sure you want to log out?': '¿Está seguro de que quieres cerrar sesión?',
  'Credits balance:': 'Saldo de créditos:',

  // Settings  (Settings.const.js)
  'Assessments and batteries': 'Evaluaciones y baterías',
  'Ideal Profiles': 'Perfiles ideales',
  Norms: 'Normas',
  '360 Assessments': 'Evaluaciones 360',
  'Response scales': 'Escalas de respuesta',
  Templates: 'Plantillas',
  'Email templates': 'Plantillas de correo electrónico',
  Account: 'Cuenta',
  'General info': 'Información general',
  'Credits History': 'Historial de créditos',
  'Reports Usage': 'Uso de informes',
  'Assessments Stats': 'Uso de evaluaciones',
  Tutorials: 'Tutoriales',
  'Watch tutorials': 'Ver tutoriales',

  // Settings (AssessmentsAndBatteries.js)
  assessment: 'evaluación',
  'this battery?': '¿esta batería?',
  'Delete battery': 'Eliminar batería',
  'Delete batteries': 'Eliminar baterías',
  'Assessment settings': 'Configuración de evaluaciones',
  'selected batteries?': '¿seleccionar baterías ?',
  // Settings (AddNormMode.js)
  'Add New Norm': 'Agregar nueva norma',
  'Norm name': 'Nombre de la norma',
  'Assessment on which the norm profile is based': 'Evaluación en la que se basa el perfil normativo',
  Frequency: 'Frecuencia',
  SD: 'Desviación Estándar',
  'Sample Size': 'Tamaño de la muestra',
  'Sample size must be greater than or equal to 1': 'El tamaño de la muestra debe ser mayor o igual a 1',
  'Select group': 'Seleccione grupo',
  'Assessment on which the norm is based': 'Encuesta en la que se basa la norma',

  // Settings (CompetencyGroups.js)
  'Copy of': 'Copia de',
  'Competency Group': 'Grupo de competencia',
  'Delete assessment': 'Eliminar evaluación',
  'selected assessments': 'evaluaciones seleccionadas',
  'this assessment': 'esta evaluación',
  'Duplicate assessment': 'Evaluación duplicada',
  'Are you sure you want to duplicate': '¿Está seguro de que quieres duplicar?',

  // Settings (CompetencyGroupsDetails.js)
  Structure: 'Estructura',
  'Response scale': 'Escala de respuesta',
  'Loading Competency Details': 'Cargando detalles de la competencia',
  'Categories:': 'Categorías:',
  'Competencies:': 'Competencias:',
  'Numbers of items': 'Números de artículos',
  'Multiple choice:': 'Opciones multiples:',
  'Free text:': 'Texto libre:',
  'Estimated time': 'Tiempo estimado',
  minutes: 'Minutos',
  'Available languages': 'Idiomas disponibles',
  'Make sure at least one language is fully supported in assessment':
    'Asegurese de que al menos 1 (un) idioma esté soportado en el cuestionario',
  Questions: 'Preguntas',
  'Competency deletion': 'Borrado de competencias',
  'competencies?': 'Competencias',
  'competency?': 'Competencia',
  'Assessment deletion': 'Eliminación de evaluación',
  'Are you sure you want to deleted selected the assessment?':
    '¿Está seguro de que desea eliminar la evaluación seleccionada?',
  // Settings Templates (WelcomeText.js)
  'Welcome texts': 'Texto de bienvenida',
  'selected welcome texts?': 'Texto de bienvenida seleccionados',
  'this welcome text?': 'Este texto de bienvenida?',

  // Settings Templates (WelcomeTextScreen.js)
  'Add new welcome text': 'Añadir nuevo texto de bienvenida',
  'Template name': 'Nombre de plantilla',
  'welcome text': 'Texto de bienvenida',
  'Delete welcome texts': 'Eliminar texto de bienvenida',

  // Settings Templates (templatesTabs.js)
  'Assessment welcome texts': 'Texto de bienvenida a la evaluación',
  template: 'Plantilla',
  'Delete templates': 'Eliminar plantillas',
  'selected templates?': '¿Plantillas seleccionadas?',
  'this template?': '¿Esta plantilla?',

  // Settings Templates (TemplatesScreen.js)
  'Field is required': 'Campo requerido',
  'Template subject': 'Tema de la plantilla',

  // Settings ResponseScales (ResponseScales.js)
  'Response Scale': 'Escala de respuesta',
  'Delete scale(s)': 'Eliminar escala(s)',
  'Are you sure you want to delete this scale(s)?': '¿Está seguro de que desea eliminar esta(s) escala(s)?',
  Responses: 'Respuestas',
  Languages: 'Lenguajes',
  'Score right to left': 'Califica de derecha a izquierda',

  // Settings ResponseScales (bulkActions.js)
  'Delete selected response scale(s)': 'Eliminar escala (s) de respuesta seleccionada',
  // Settings Norms (NormsHeader.js)
  'Norm Type:': 'Tipo de norma',
  'Creation Date:': 'Fecha de creación',

  // Settings Norms (NormsScales.js)
  'Norm Table': 'Tabla de norma',
  'Frequency Histograms': 'Histograma de frecuencia',
  'Bio Data': 'Datos biográficos',
  Stenine: 'Estanino',
  Stentable: 'Tabla de estaninos',

  // Settings Norms (Norms.js)
  'Add new norm': 'Añadir nueva norma',
  'Delete norm(s)': 'Eliminar norma(s)',
  'Assessment and Batteries': 'Evaluación y Baterías',
  'selected norms?': '¿Normas seleccionadas?',
  'this norm?': 'Esta norma?',
  'Creation Date': 'Fecha de creación',

  // Settings Norms (HistogramView.js)
  FREQUENCY: 'Frecuencia',
  'RAW SCORE': 'PUNTUACIÓN BRUTA',
  'Raw Score': 'Puntuación bruta',

  // Settings ManageBattery (index.js)
  'Battery name': 'Nombre de la Batería',
  'Search existing assessments': 'Buscar evaluaciones existentes',
  'Add battery': 'Añadir batería',

  // Settings IdealProfile (IdealProfileScales.js)
  'Ideal profile type:': 'Tipo de perfil ideal',
  'Unsaved data': 'Datos no guardados',
  'The current ideal profile has not been saved. Would you like to save it?':
    'No se ha guardado el perfil ideal actual. ¿Le gustaría guardarlo?',
  addIdealProfileGroups: 'Grupos',

  // Sidebar.js
  Logout: 'Cerrar sesión',

  // Settings IdealProfile (IdealProfile.js)
  'Delete ideal profiles': 'Eliminar perfil ideal',
  'selected ideal profiles?': 'Perfiles ideales seleccionados',
  'this ideal profile?': 'Este perfil ideal',

  // Settings AddIdealProfile.js
  'Add ideal profile': 'Añadir perfil ideal',
  'Ideal profile name': 'Nombre del perfil ideal',
  'I will manually select the values': 'Seleccionare manualmente los valores',
  'I will use respondent data to determine the values':
    'Usaré los datos de los encuestados para determinar los valores',
  Test: 'Prueba',
  // (advancedFilterOptions.js)
  'Is equal to': 'Es igual a',
  'Is not equal to': 'No es igual a',
  'Starts with': 'Comienza con',
  Contains: 'Contiene',
  'Does not contain': 'No contiene',
  'Ends with': 'Termina con',
  'Lesser than': 'Menor que',
  'Greater than': 'Más grande que',
  '1 day': '1 día',
  '3 days': '3 días',
  '1 week': '1 semana',
  '2 weeks': '2 semanas',
  '1 month': '1 mes',
  '1 year': '1 año',

  // groupsTypes.js
  Unsupervised: 'Sin supervisión',
  Supervised: 'Supervisado',
  // idealProfileTypes.js
  'Manually configured': 'Configurado manualmente',
  'Based on respondent data': 'Basado en datos de encuestados',

  // inviteCreationCategories.js
  'Personality tests': 'Pruebas de personalidad',
  'Interests Values Attitudes': 'Intereses Valores Actitud',
  Reasoning: 'Razonamiento',
  Value: 'Valor',
  'Preferences/Styles': 'Preferencias/Estilos',
  'Custom battery': 'Batería personalizada',
  'General Solutions': 'Solución General',
  'Screening Solutions': 'Soluciones de cribado',
  Solution: 'Solución',
  Battery: 'Batería',
  Assessment: 'Evaluación',
  'Every 4 days': 'Cada 4 días',
  'Every 3 days': 'Cada 3 días',
  'Every 2 days': 'Cada 2 días',
  'Every day': 'Todos los días',
  "Don't send": 'No enviar',
  Immediate: 'Inmediato',

  // inviteStatuses.js
  'Not sent': 'No enviado',
  Scheduled: 'Programado',
  Sent: 'Enviado',
  Failed: 'Ha fallado',

  // raterRoles.js
  Manager: 'Gerente',
  Peer: 'Evaluado',
  'Direct report': 'Reporte directo',

  // respondentSelectOptions.js
  'No Qualification': 'Sin calificación',
  'Secondary School': 'Escuela secundaria',
  'Industry or Trade Training': 'Capacitación industrial o comercial',
  'Professional Qualification': 'Cualificación profesional',
  'Tertiary Institute Diploma/Certificate': 'Diploma / Certificado de Instituto Terciario',
  'University Bachelor Degree': 'Licenciatura universitaria',
  'Postgraduate Diploma/Certificate': 'Diploma / Certificado de posgrado',
  'Bachelor Honours': 'Honores de licenciatura',
  'Masters Degree': 'Maestría',
  'Doctorate Degree': 'Doctorado',
  'African American': 'Africano americano',
  Aborigine: 'Aborigen',
  Algerian: 'Argelino',
  African: 'Africano',
  Afrikaner: 'Afrikaner',
  Afrikaans: 'Afrikaans',
  Albanian: 'Albanés',
  American: 'Americano',
  Argentinian: 'Argentino',
  'Australian Aborigine': 'Aborigen australiano',
  Arab: 'Árabe',
  Asian: 'Asiático',
  Austrian: 'Austriaco',
  Australian: 'Australiano',
  Bangladeshi: 'Bangladesh',
  Balinese: 'Balinés',
  'Black British': 'Inglés de raza negra',
  'Black Canadian': 'Canadiense de raza negra',
  Belgian: 'Belga',
  'Black Carribean': 'Caribeño de raza negra',
  'Black Indian': 'Indio de raza negra',
  'Black African': 'Africano de raza negra',
  Bolivian: 'Boliviano',
  Bengali: 'Bengalí',
  'Black Other': 'De raza negra de otro origen',
  Bosnian: 'Bosnio',
  Brazilian: 'Brasileño',
  British: 'Británico',
  Belarusian: 'Bielorruso',
  Bulgarian: 'Búlgaro',
  Burmese: 'Birmano',
  Canadian: 'Canadiense',
  Castilian: 'Castellano',
  Catalan: 'Catalán',
  Celtic: 'Celta',
  Chinese: 'Chino',
  'Channel Islander': 'Original de las Islas de Canal',
  Cossack: 'Cosaco',
  Chilean: 'Chileno',
  Cambodian: 'Camboyano',
  Chechen: 'Checheno',
  Colombian: 'Colombiano',
  'Costa Rican': 'Costarricense',
  Croatian: 'Croata',
  Corsican: 'Corso',
  Cantonese: 'Cantonés',
  Cuban: 'Cubano',
  Cypriot: 'Chipriota',
  'Cypriot Greek': 'Chipriota griego',
  Czech: 'Checo',
  Danish: 'Danés',
  Dutch: 'Holandés',
  Eurasian: 'Euroasiático',
  Ecuadorian: 'Ecuatoriano',
  Egyptian: 'Egipcio',
  English: 'Inglés',
  Estonian: 'Estonio',
  Ethiopian: 'Etíope',
  European: 'Europeo',
  Fijian: 'Fiyiano',
  Filipino: 'Filipino',
  Flemish: 'Flamenco',
  Finnish: 'finlandés',
  French: 'Francés',
  Gaelic: 'Gaélico',
  Georgian: 'Georgiano',
  German: 'Alemán',
  Greek: 'Griego',
  Hungarian: 'Húngaro',
  Hispanic: 'Hispano',
  'Hong Kong Chinese': 'Chino de Hong Kong',
  Icelandic: 'Islandés',
  Indonesian: 'Indonesio',
  Irani: 'Irani',
  Indian: 'Indio',
  'Iranian/Persian': 'Iraní / Persa',
  Iraqi: 'Iraquí',
  Irish: 'Irlandés',
  'Iranian Arab': 'Árabe iraní',
  Iranian: 'Iraní',
  Italian: 'Italiano',
  Jamaican: 'Jamaicano',
  Jewish: 'Judío',
  'Jewish/Israeli': 'Judío / Israelí',
  Jordanian: 'Jordano',
  Japanese: 'Japonés',
  Kenyan: 'Keniano',
  Kiwi: 'Kiwi',
  Kongo: 'Kongolés',
  Korean: 'Coreano',
  Latin: 'Latín',
  Lebanese: 'Libanés',
  Lithuanian: 'Lituano',
  Libyan: 'Libio',
  'Latin American': 'Latinoamericano',
  Latino: 'Latino',
  Luxembourger: 'Luxemburgés',
  Latvian: 'Latviano',
  Macedonian: 'Macedonio',
  'Middle Eastern': 'Medio Oriental',
  Malaitian: 'Malaitiano',
  Maori: 'Maorí',
  Monacan: 'Monacense',
  Moroccan: 'Marroquí',
  Maltese: 'Maltés',
  Mexican: 'Mexicano',
  Malay: 'Malayo',
  'Mixed Race': 'De raza mixta',
  'New Zealand European': 'Neo Zelandés europeo',
  Nigerian: 'Nigeriano',
  Norwegian: 'Noruego',
  Nepalese: 'Nepalí',
  'New Zealander': 'Neozelandés',
  'Other Ethnicity': 'Otro origen étnico',
  Paraguayan: 'Paraguayo',
  'Pacific Islander': 'Isleño del Pacífico',
  Persian: 'Persa',
  Peruvian: 'Peruano',
  'Persian/Iranian': 'Persa / iraní',
  Pakistani: 'Pakistaní',
  Palestinian: 'Palestino',
  Polish: 'Polaco',
  'Papua New Guinean': 'Papúa Nueva Guinea',
  'Puerto Rican': 'Puertorriqueño',
  Portuguese: 'portugués',
  Polynesian: 'Polinesio',
  Romanian: 'Rumano',
  Russian: 'Ruso',
  Samoan: 'Samoano',
  'South African Coloured': 'Sudafricano de color',
  Scottish: 'Escocés',
  Swede: 'Sueco',
  'South African European': 'Europeo sudafricano',
  Serbian: 'Serbio',
  Swedish: 'Sueco',
  'South American Indian': 'Indio sudamericano',
  Sicilian: 'Siciliano',
  Slovak: 'Eslovaco',
  Slav: 'Eslavo',
  Spanish: 'Español',
  'Sri Lankan': 'Siri Lanka',
  'Southeast Asian': 'Sudeste asiático',
  Swiss: 'Suizo',
  Swahili: 'Swahili',
  Swazi: 'Swazi',
  Taiwanese: 'Taiwanés',
  Thai: 'Tailandés',
  Turkish: 'Turco',
  Tamil: 'Tamil',
  Tongan: 'Tongano',
  Rwandan: 'Rwandan',
  Tunisian: 'Tunecino',
  Udi: 'Udi',
  Ugandan: 'Ugandés',
  Ukrainian: 'Ucraniano',
  Uruguayan: 'Uruguayo',
  Vietnamese: 'Vietnamita',
  Welsh: 'Galés',
  White: 'Blanco',
  'West Indian': 'Indio occidental',
  Zimbabwean: 'Zimbabwean',
  Zulu: 'Zulú',
  '- Chief executive': '-Director ejecutivo',
  '- Administrative or commercial manager': '-Gerente administrativo o comercial',
  '- Production or specialized services manager': '-Responsable de producción o servicios especializados',
  '- Hospitality, retail or other services manager': '-Gerente de hotelería, comercio minorista u otros servicios',
  '- Senior manager': '-Gerente senior',
  '- Middle manager': '-Gerente de nivel medio',
  '- Junior manager/supervisor': '-Gerente / supervisor junior',
  '- Other': '- Otro',
  Professional: 'Profesional',
  '- Science or engineering professional': '- Ciencia o ingeniería',
  '- Health professional': '- Profesional de la salud',
  '- Teaching professional': '- Docente profesional',
  '- Business or administration professional': '- Profesional de negocios o administración',
  '- Information and communications technology professional':
    '- Profesional de tecnologías de la información y las comunicaciones',
  '- Legal, social or cultural professional': '- Profesional legal, social o cultural',
  'Technician or associate professional': 'Técnico o profesional asociado',
  '- Science or engineering associate professional': '- Asociado en ciencias o ingeniería',
  '- Health associate professional': '- Profesional asociado a la salud',
  '- Business or administration associate professional': '- Asociado a negocios o administración',
  '- Legal, social, cultural or related associate professional': '- Asociado a rama legal, social, cultural u otras',
  '- Information and communications technician': '- Técnico en información y comunicaciones',
  'Clerical support worker': 'Trabajador de apoyo administrativo',
  '- General or keyboard clerk': '- Empleado general  u oficinista',
  '- Customer services clerk': '- Empleado de servicio al cliente',
  '- Numerical or material recording clerk': '- Oficinista  de registro numérico o material',
  'Service or sales worker': 'Trabajador de servicios o ventas',
  '- Personal service worker': '- Trabajador de servicios personales',
  '- Sales worker': '- Trabajador de ventas',
  '- Personal care worker': '- Trabajador de cuidado personal',
  '- Protective services worker': '- Trabajador de servicios de protección',
  'Skilled agricultural, forestry or fishery worker': '-Trabajador calificado agrícola, forestal o pesquero',
  '- Market-oriented skilled agricultural worker': '- Trabajador agrícola calificado',
  '- Market-oriented skilled forestry, fishing or hunting worker':
    '- Trabajador especializado en silvicultura, pesca o caza',
  '- Subsistence farmer, fisher, hunter or gatherer': '- Agricultor de subsistencia, pescador, cazador o recolector',
  'Craft or related trades worker': 'Trabajador artesanal o de oficios afines',
  '- Building or related trades worker, excluding electrician':
    '- Trabajador de la construcción u oficios afines, ex electricista',
  '- Metal, machinery or related trades worker': '-Trabajador del metal, maquinaria u oficios afines',
  '- Handicraft or printing worker': '-Trabajador de artesaníaa o de imprenta',
  '- Electrical or electronic trades worker': '- Trabajador de oficios eléctricos u electrónicos',
  '- Food processing, wood working, garment or other craft or related trades':
    '- Procesamiento de alimentos, carpintería, confección u otras artesanías y oficios afines',
  'Plant or machine operator, or assembler': 'Operador de planta o máquina o ensamblador',
  '- Stationary plant or machine operator': '- Operador estacionario de instalaciones o máquinas',
  '- Assembler': '- Ensamblador',
  '- Driver or mobile plant operator': '- Conductor u operador de planta móvil',
  'Elementary occupation': 'Ocupación elemental',
  '- Cleaner or helper': '- Limpiador o ayudante',
  '- Agricultural, forestry or fishery labourer': '- Trabajador agrícola, forestal o pesquero',
  '- Labourer in mining, construction, manufacturing or transport': '- Trabajador en varios sectores',
  '- Food preparation assistant': '- Asistente de preparación de alimentos',
  '- Street or related sales or service worker': '- Trabajador de ventas o de servicios en la calle o relacionado',
  '- Refuse worker or other elementary worker': 'Recolectores de desechos u otro trabajo elemental',
  'Armed forces occupation': 'Ocupación de las fuerzas armadas',
  '- Commissioned armed forces officer': '- Oficial comisionado de las fuerzas armadas',
  '- Non-commissioned armed forces officer': '- Suboficial de las fuerzas armadas',
  '- Armed forces occupation, other rank': '- Ocupación de las fuerzas armadas, otro rango',
  'Local Government/Authority': 'Gobierno / autoridad local',
  'Private, Corporate ': 'Soldado Raso, Corporativo',
  'Public service, Fed/State Govt': 'Servicio público gubernamental, federal o estatal',
  'State Owned Corporation': 'Corporación de propiedad estatal',
  Unknown: 'Desconocido',
  'Administrative and Support Service Activities': 'Administración y servicios de apoyo',
  'Agriculture, Forestry and Fishing': 'Agricultura,  silvicultura y  pesca',
  'Arts, Entertainment and Recreation': 'Arte, entretenimiento y recreación',
  'Banking & Finance': 'Banca y Finanzas',
  'Business Services': 'Servicios asociados a negocios',
  'Architecture and Construction': 'Arquitectura y Construcción',
  'Education and Training': 'Educación y entrenamiento',
  'Utilities (Gas, Water, Electric, etc)': 'Servicios públicos (gas, agua, electricidad, etc.)',
  Engineering: 'Ingeniería',
  'Hospitality and Tourism': 'Hospitalidad y Turismo',
  'Human Resources': 'Recursos humanos',
  'Health Sector': 'Sector de salud',
  'Information and Technology': 'Información y Tecnología',
  Insurance: 'Seguros',
  'Law Enforcement': 'Trabajos asociados al cumplimiento de la ley',
  Manufacturing: 'Manufactura',
  'Mining and Quarrying': 'Minas y canteras',
  'Military & Defence': 'Militar y Defensa',
  'Oil and Gas/Energy Exploration': 'Exploración de petróleo, gas y  energía',
  'Pharmaceutical Industry': 'Industria farmacéutica',
  'Public Administration': 'Administración Pública',
  'Real Estate Activities': 'Actividades inmobiliarias',
  'Scientific and Technical Activities': 'Actividades científicas y técnicas',
  Security: 'Seguridad',
  'Social Work': 'Trabajo Social',
  Telecommunications: 'Telecomunicaciones',
  'Transportation and Storage': 'Transporte y almacenamiento',
  Media: 'Medios de comunicación',
  'Wholesale and Retail Trade': 'Comercio al por mayor y al por menor',
  Arabic: 'Arábica',
  'Brazilian Portuguese': 'Portugués de Brasil',
  'Chinese-Simplified': 'Chino-simplificado',
  'Chinese-Traditional': 'Chino-tradicional',
  Hebrew: 'Hebreo',
  'Hindi-Urdu': 'Hindi-Urdu',
  isiNdebele: 'isiNdebele',
  isiXhosa: 'isiXhosa',
  isiZulu: 'isiZulu',
  'Latin American Spanish': 'Español latinoamericano',
  Malayalam: 'Malayalam',
  Punjabi: 'Punjabi',
  Sepedi: 'Sepedi',
  'Southern Thai': 'Sur de Tailandia',
  Sesotho: 'Sesotho',
  Setswana: 'Setswana',
  siSwati: 'siSwati',
  'Tagalog (Filipino)': 'Tagalog (Filipino)',
  Turkmen: 'Turcomano',
  Tshivenda: 'Tshivenda',
  Xitsonga: 'Xitsonga',
  Male: 'Masculino',
  Female: 'Femenino',
  'Not Specified': 'No especificado',

  // errorMessages.js
  'Email address is not valid': 'La dirección de correo electrónico es inválida',

  // table bulkActions assessments.js
  'Edit battery': 'Editar batería',
  // table singleRowActions competency.js
  'Add competency': 'Agregar competencia',
  'Edit competency category': 'Editar categoría de competencia',
  'Delete competency category': 'Eliminar categoría de competencia',
  'Edit competency': 'Editar competencia',
  'Delete competency': 'Eliminar competencia',
  'Edit question': 'Editar pregunta',
  'Delete question': 'Eliminar pregunta',

  // table singleRowActions competencyGroup.js
  'Delete competency group': 'Eliminar grupo de competencias',
  'Go to competency groups details': 'Ir a los detalles de los grupos de competencias',

  // table singleRowActions  group.js
  'Invite respondents to questionnaire': 'Invitar a los participantes al cuestionario',
  'Administer answer sheet': 'Administrar hoja de respuestas',
  'Administer managed group': 'Administrar gestion de grupos',
  'Create an ideal profile based on selected group': 'Crea un perfil ideal basado en el grupo seleccionado',
  'Create a Norm based on selected group': 'Crear una norma basada en el grupo seleccionado',
  'View or edit': 'Ver o editar',
  Delete: 'Eliminar',

  // table singleRowActions invites.js
  Remind: 'Recordar',

  // table singleRowActions projects.js
  'Add to favorites': 'Agregar a favoritos',
  View: 'Ver',
  'Delete Project': 'Eliminar proyecto',

  // table singleRowActions raters.js
  'Delete rater': 'Eliminar evaluador',

  // table singleRowActions reports.jsAdminister questionnaire
  Open: 'Abierto',
  Download: 'Descargar',

  // table singleRowActions respondents.js
  'Administer questionnaire': 'Administrar cuestionario',
  'Invite to questionnaire': 'Invitar a hacer el  cuestionario',
  'Add tag': 'Añadir etiqueta',

  // table singleRowActions session.js
  'Invite raters who have not yet complete': 'Invite a los evaluadores que aún no hayan completado',
  'Invite who have not yet been invited': 'Invitar a quienes aún no han sido invitados',
  'Send reminder to raters who have already been invited but have not yet completed':
    'Enviar recordatorio a los evaluadores que ya han sido invitados pero que aún no han completado',
  'Invite to specify own rater': 'Invitar para especificar a los  propio(s) evaluador(es)',
  'Send invitation to self': 'Enviar invitación a sí mismo',
  'Edit session': 'Editar sesión',

  // table bulkActions assessments.js
  'Add custom battery': 'Agregar batería personalizada',
  'Edit assessment': 'Editar evaluación',
  'Delete Assessment(s)': 'Eliminar evaluación (es)',

  // table bulkActions groups.js
  'Create a Norm based on the selected group(s)': 'Crear una norma basada en los grupos seleccionados',
  'Create an Ideal profile based on the selected group(s)': 'Crear un perfil ideal basado en los grupos seleccionados',
  'Delete selected group(s)': 'Eliminar grupo (s) seleccionado (s)',
  'Generate an open group link': 'Generar un enlace de grupo abierto',

  // table bulkActions idealProfiles.js
  'Edit ideal profile': 'Editar perfil ideal',

  // table bulkActions invites.js
  'Copy invite link to clipboard': 'Copiar enlace de invitación al portapapeles',
  'Delete invites': 'Eliminar invitaciones',

  // table bulkActions projects.js
  'Edit project details': 'Editar detalles del proyecto',
  'Delete projects(s)': 'Eliminar proyectos (s)',
  // table bulkActions raters.js
  'Send invite to selected rater(s)': 'Enviar invitación a los evaluadores seleccionados',
  'Remove selected rater(s) from session': 'Eliminar de la sesión a los evaluadores seleccionados,',

  // table bulkActions reports.js
  Actions: 'Actions',
  'Delete selected report(s)': 'Eliminar los informes seleccionados',
  'Download selected report(s)': 'Descargar los informes seleccionado s',
  'View selected report(s)': 'Ver los informes seleccionados',

  // table bulkActions respondentGroups.js
  'Remove respondent from selected group(s)': 'Eliminar al encuestado de los grupos seleccionados',
  // table bulkActions respondents.js
  'Administer assessment': 'Administrar evaluación',
  'Administer assessment for': 'Administrar la evaluación de  %{respondentName}',
  'Invite respondent(s) to assessment ': 'Invitar a los encuestados a la evaluación',
  'Request report(s)': 'Solicitar informe (s)',
  'Go to respondent details': 'Ir a los detalles del encuestado',
  'Manage tags for selected respondent(s) ': 'Administrar etiquetas para encuestados seleccionados',
  'Add new group from selection': 'Agregar nuevo grupo de la selección',
  'Add to existing group ': 'Agregar a un grupo existente',
  'Delete selected respondents from group': 'Eliminar a los encuestados seleccionados del grupo',
  'Delete selected respondents': 'Eliminar encuestados seleccionados',
  'this respondent': 'este encuestadp',
  'selected respondents': 'encuestados seleccionados',

  // table bulkActions respondentsTest.js
  'Delete selected result(s)': 'Eliminar resultado (s) seleccionado (s)',

  // table bulkActions sessions.js
  'Invite to specify own raters': 'Invitar a especificar a sus propios evaluadores',
  'Delete Session(s)': 'Eliminar sesión (es)',

  // table bulkActions templates.js
  'Go to template details': 'Ir a los detalles de la plantilla',
  'Delete selected template(s)': 'Eliminar plantilla (s) seleccionada (s)',
  // table bulkActions welcomeText.js
  'Go to welcome text details': 'Ir a los detalles del texto de bienvenida',
  'Delete selected welcome text(s)': 'Eliminar texto (s) de bienvenida seleccionado',
  // table entitiesDropdownActions groupDetails.js
  'Edit title': 'Editar título',
  'Duplicate 360 assessment': 'Evaluación 360 duplicada',
  'Delete 360 assessment': 'Eliminar evaluación 360',
  // table entitiesDropdownActions sessions.js
  'Invite raters who have not yet completed': 'Invite a evaluadores que aún no hayan completado',

  // table tableActions competencyGroupsDetails.js
  'Review competencies': 'Revisar competencias',

  // table tableActions customGroups.js
  'Add new 360 assessment': 'Agregar nueva evaluación 360',

  // table tableActions raters.js
  'Add rater': 'Agregar evaluador',
  // table tableActions respondents.js
  'Import respondents': 'Importar encuestados',

  // table tableActions templates.js
  'Add new template': 'Agregar nueva  plantilla',
  'Edit template': 'Editar plantilla',
  'Edit welcome text': 'Editar texto de bienvenida',
  // QuestionOrder.js
  Default: 'Por defecto',
  Custom: 'Personalizado',
  Random: 'Aleatorio',
  // tables columns sessions.js
  Invited: 'Invitado',
  'Invite Not Sent': 'Invitación no enviada',

  'Show expired': 'Demostración caducada',
  'Sorry we could not find any': 'Lo sentimos, no pudimos encontrar ningún %{entityName},',
  'Sorry, we could not find any respondents': 'Lo sentimos, no pudimos encontrar ningún encuestado',
  'Sorry, we could not find any reports': 'Lo sentimos, no pudimos encontrar ningún reporte',
  'Sorry, we could not find any results': 'Lo sentimos, no pudimos encontrar ningún resultado',
  'Sorry, we could not find any invites': 'Lo sentimos, no pudimos encontrar ninguna invitación',
  'Sorry, we could not find any records': 'Lo sentimos, no pudimos encontrar ningún registro',
  'Sorry, we could not find any projects': 'Lo sentimos, no pudimos encontrar ningún projecto',
  'Sorry, we could not find any tests': 'Lo sentimos, no pudimos encontrar ninguna prueba',
  'Sorry, we could not find any groups': 'Lo sentimos, no pudimos encontrar ningún grupo',
  'Sorry, we could not find any notifications': 'Lo sentimos, no pudimos encontrar ninguna notificacion',
  'Sorry, we could not find any raters': 'Lo sentimos, no pudimos encontrar ningún calificador',
  'Sorry, we could not find any templates': 'Lo sentimos, no pudimos encontrar ninguna plantilla',
  'Sorry, we could not find any sessions': 'Lo sentimos, no pudimos encontrar ninguna sesión',
  'Sorry, we could not find any welcome texts': 'Lo sentimos, no pudimos encontrar ningún texto de bienvenida',
  'Sorry, we could not find any competency groups': 'Lo sentimos, no pudimos encontrar ningún grupo de competencia',
  'Sorry, we could not find any norms': 'Lo sentimos, no pudimos encontrar ninguna norma',
  'Sorry, we could not find any response scales': 'Lo sentimos, no pudimos encontrar ninguna escala de respuesta',
  'Number of items': 'Número de items',
  Categories: 'Categorías',
  'User name': 'Nombre de usuario',
  'Update amount': 'Actualizar monto',
  Members: 'Miembros',
  'Invitation Date': 'Fecha de la invitación',
  'Respondent Name': 'Nombre del candidato',
  Report: 'Reporte',
  'Date submitted': 'Fecha de Envío',
  Status: 'Estado',
  'Not invited': 'No invitado',
  'Not completed': 'No completado',
  'Completed Date': 'Fecha de finalización',
  'Direct Report': 'Reporte directo',
  Progress: 'Progreso',
  'Assessments Done': 'Evaluaciones realizadas',
  'Add new project': 'Agregar nuevo proyecto',
  Date: 'Fecha',
  'Loading entities': 'Cargando %{entityName}',
  Created: 'Creado',
  'No options': 'Sin opciones',
  '**Default': '** Por defecto',
  '**Default text': '**Por defecto',

  // Settings Account ChangePassword.js

  'Passwords must match': 'Las contraseñas deben coincidir',
  'Change password': 'Cambie la contraseña',
  'Confirm new password': 'Confirmar nueva contraseña',
  'New password': 'Nueva contraseña',
  Change: 'Cambiar',
  'Pins must match': 'El número de PIN debe coincidir',
  'Change pin': 'Cambiar número PIN',
  'Confirm new pin': 'Confirmar nuevo número de PIN',
  'New pin': 'Nuevo número de PIN',
  'Total:': 'Total:',
  'Loading credits history...': 'Cargando historial de créditos ...',
  'From date': 'Desde',
  'To date': 'Hasta',
  Pin: 'Pin',
  'Receive email notifications': 'Recibir notificaciones por correo electrónico',
  'Complete Status': 'Completado',
  'Invite Status': 'Invitado',
  'Not Invited': 'No invitado',
  'Sessions count': 'Cuenta de sesiones',

  // ImportSessions
  'Import sessions': 'Importar sesiones',
  prepare: 'preparar',
  preview: 'previzualizar',
  import: 'importar',
  'Prepare a csv file yourself or': 'Prepare un archivo csv o',
  'download the sample import file': 'Descargue una muestra del archivo',
  'Make sure the data order is set out correctly': 'Asegure que el orden de los datos sea correcto ',
  'Upload the file': 'Cargue el archivo',
  'Drag and drop file here or browse the file': 'Arrastre o busque el archivo',
  'Upload the csv file to import respondents': 'Cargue el archivo csv para importar encuestados',
  'The first line contains field headers': 'La primera linea contiene campo de encabezados',
  'Choose file': 'Escoja el archivo',

  // AccountTabs
  general: 'General',
  'credit updates': 'Actualizaciones de creditos',
  'Reports Stats': 'Reportes solicitados',
  'assessments stats': 'Uso de cuestionarios',

  // ReportUsage
  total: 'Total: %{total}',
  'Report name': 'Nombre de reporte',
  'Usage count': 'Conteo de uso',
  'Assessment name': 'Nombre de evaluación',
  Total: 'Total',
  'Loading requested reports': 'Cargando reportes solicitados',
  'Loading used assessments': 'Cargando evaluaciones usadas',

  // Toasts & system notifications
  'Tag(s) have been deleted': 'Etiqueta(s) han sido eliminadas',
  'Loading items': 'Cargando elementos',
  'Saving results': 'Guardando resultados',
  'Ideal profile has been created': 'Perfil ideal ha sido creado',
  'Copy of 360 survey has been created': 'Copia de encuesta 360 ha sido creada',
  'Tag(s) have been updated': 'Etiqueta(s) han sido actualizadas',
  'Welcome text has been saved': 'Texto de bienvenida ha sido guardado',
  'Welcome text has been updated': 'Texto de bienvenida ha sido actualizado',

  // variables
  createOption: 'Crear',

  // Toasts
  groupCreationToast: 'Grupo ha sido creado ',
  respondentsDeletionToast: 'El/Los participante(s) ha(n) sido eliminado(s)',
  resultsSuccessToast: 'Resultados para %{respondentInfo} agregados exitosamente',
  surveyDeletionCancelToast: 'No se pueden eliminar las encuestas de 360 utilizadas en el proyecto',
  creditBalanceUpdateToast: 'El saldo de crédito ha sido actualizado',
  correctDataToast: 'Por favor, asegúrese de que todos los datos se hayan ingresado correctamente',
  downloadLimitToast: '¡Oops! No puedes descargar más de 50 informes al mismo tiempo',
  categoryUpdateToast: 'La categoría ha sido actualizada',
  newCategoryToast: 'Nueva categoría ha sido añadida',
  createdRequestToast: 'Solicitud de informe creada',
  respondentCreationToast: 'Participante ha sido añadido',
  respondentUpdateToast: 'Participante ha sido actualizado',
  clipboardCopyToast: 'Copiado al portapapeles exitosamente',
  addedAssessmentToast: 'Evaluación ha sido añadida',
  deletedAssessmentToast: 'Evaluación ha sido eliminada',
  completedAssessmentToast: '%{respondentName} ha completado %{assessment}',
  importRespondentsErrorToast: 'Algo salió mal durante el proceso de importación de participantes',
  importSessionsErrorToast: 'Algo salió mal durante el proceso de importación de sesiones',
  competencyTitleUpdateToast: 'El título ha sido actualizado',
  competencyUpdateToast: 'La competencia ha sido actualizada',
  newCompetencyToast: 'Nueva competencia ha sido añadida',
  questionUpdateToast: 'La pregunta ha sido actualizada',
  questionAdditionToast: 'La pregunta ha sido añadida',
  groupLinkErrorToast: 'Algo salió mal durante la generación del enlace del grupo, por favor  inténtelo de nuevo',
  reportsDeletionToast: 'El / Los informe(s) ha(n) sido eliminado(s)',
  resultsSavingErrorToast: 'Error al guardar los resultados para %{respondentName} %{assessment}',
  reportRequestCreationToast: 'Solicitud de informe creada',
  assessmentDataFailToast: 'Error al cargar los datos de la evaluación',
  scaleUpdatedToast: 'La escala de respuestas ha sido actualizada',
  scaleCreatedToast: 'La escala de respuestas ha sido guardada',
  scaleDeletionToast: 'La(s) escala(s) de respuestas ha(n) sido eliminada(s)',
  surveyUpdatedToast: 'La encuesta de 360 ha sido actualizada',
  normsDeletionToast: 'La(s) norma(s) ha(n) sido eliminada(s)',
  'Deletion blocked': 'Eliminación bloqueada',
  groupCreationErrorToast: 'Error durante la creación del grupo',
  groupsDeletionToast: 'El / Los grupo(s) ha(n) sido eliminado(s)',
  reportsGenerationToast: 'El / Los informe(s) solicitado(s) exitosamente',
  invitesDeletionToast: 'La(s) invitación(es) ha(n) sido eliminada(s)',
  normCreationToast: 'La norma ha sido creada',
  normCreationErrorToast: 'No se pudo crear la norma, por favor inténtelo de nuevo',
  projectDeletionToast: 'El proyecto ha sido eliminado',
  projectsDeletionToast: 'Los proyectos han sido eliminados',
  projectCreationToast: 'El proyecto ha sido creado',
  projectUpdateToast: 'El proyecto ha sido actualizado',
  ratersDeletionToast: 'El / Los evaluador(es) ha(n) sido eliminado(s)',
  categoryDeletionToast: 'La categoría de competencia ha sido eliminada',
  resultsDeletionToast: 'El / Los resultado(s) ha(n) sido eliminado(s)',
  tagsAdditionToast: 'Se han añadido etiquetas',
  addedToGroupToast: 'Añadido exitosamente al grupo',
  questionnaireSessionErrorToast: 'No se pudo crear la sesión de cuestionario, por favor inténtelo de nuevo más tarde',
  sessionAdditionToast: 'La sesión ha sido añadida',
  ratersAdditionToast: 'Se han añadido evaluadores a la sesión',
  competencyDeletionToast: 'La competencia ha sido eliminada',
  questionDeletionToast: 'La pregunta ha sido eliminada',
  assessmentsDeletionToast: 'La(s) evaluación(es) ha(n) sido eliminada(s)',
  profilesDeletionToast: 'El / Los perfil(es) ideal(es) ha(n) sido eliminado(s)',
  profileUpdateToast: 'El perfil ideal ha sido actualizado',
  templatesDeletionToast: 'La(s) plantilla(s) ha(n) sido eliminada(s)',
  templateAdditionToast: 'La plantilla ha sido guardada',
  templateUpdateToast: 'La plantilla ha sido actualizada',
  welcomeTextsDeletionToast: 'El / Los texto(s) de bienvenida ha(n) sido eliminado(s)',
  batteryAdditionToast: 'La batería ha sido creada',
  batteryUpdateToast: 'La batería ha sido actualizada',
  batteriesDeletionToast: 'Las baterías han sido eliminadas',
  batteryDeletionToast: 'La batería ha sido eliminada',
  passwordUpdateSuccessToast: 'La contraseña ha sido actualizada exitosamente',
  pinUpdateSuccessToast: 'El PIN ha sido actualizado exitosamente',

  // General
  Continue: 'Continuar',
  'Add Bio Data template': 'Añadir plantilla de datos biográficos',
  'Biodata Configuration': 'Plantilla de datos biográficos',
  'Loading biodata templates': 'Cargando plantillas de datos biográficos',
  'Add Name': 'Añadir nombre',
  'Add description': 'Añadir descripción',
  selectEditCategoryValues: 'Seleccione o edite los valores deseados',
  'Add values to category': 'Añadir valores a categoría',
  'Select values for this category': 'Seleccionar valores para la categoría',
  'Select all': 'Seleccionar todos',
  'Biodata Template has been created': 'Plantilla de datos biográficos ha sido creada',
  'Biodata Template has been updated': 'Plantilla de datos biográficos ha sido actualizada',
  'Biodata template(s) deleted': 'Plantilla(s) de datos biográficos eliminada(s)',
  biodataDefaultTemplate: 'Hacer plantilla por defecto',
  'Delete selected biodata template(s)': 'Eliminar plantilla(s) seleccionada(s)',
  'Delete biodata templates': 'Eliminar plantillas',
  'the selected biodata templates?': 'las plantillas seleccionadas?',
  'this biodata template?': 'la plantilla seleccionada?',
  'Update default biodata template': 'Actualizar plantilla por defecto',
  'Are you sure you want to set this template as default?':
    'Está seguro que quiere seleccionar esta plantilla por defecto?',
  age: 'Edad',
  gender: 'Género',
  ethnicity: 'Etnia',
  education: 'Educación',
  occupation: 'Ocupación',
  industry: 'Industria',
  'first language': 'Primer idioma',
  firstLanguage: 'Primer idioma',
  sector: 'Sector',
  jobArea: 'Área de trabajo',
  'Reset assessment deadline': 'Actualizar fecha de expiración',
  Update: 'Actualizar',
  updateAssessmentInvitationToast: 'Invitaciones actualizada(s)',
  updateProjectInvitationToast: 'Invitaciones actualizada(s)',
  biodataTooltip:
    'Las plantillas de datos personales permiten la recopilación de datos biográficos personalizados de los encuestados. Cree plantillas de datos personales en el área de Configuración.',
  'Default Biodata Template has been updated': 'Plantilla por defecto actualizada',
  DEFAULT: 'Por defecto',
  'Bio data template': 'Plantilla de datos biográficos',
  biodataDefaultTemplateLabel: '** Sin plantilla de datos biográficos (Aplican todas las categorías) **',
  'This field is required': 'Este campo es requerido',
  'Country of origin': 'País de Origen',
  'Response Scales': 'Escala de respuestas',
  'Credit Updates': 'Actualizaciones de créditos',
  'Activity Log': 'Historial de actividad',
  'Report format': 'Formato de reportes',
  'Change report format': 'Cambiar formato de reportes',
  'tests completed': 'Pruebas Completadas',
  'reports requested': 'Reportes Solicitados',
  'Project rules': 'Reglas de proyecto',
  'Invites sending': 'Envío de invitaciones',
  'Select assessment, language and email template you want to use':
    'Seleccione un cuestionario, lenguaje e plantilla de correo que va a usar.',
  'Choose whether invites should go out on the scheduled date, get sent once respondent gets added to the project or manually with pre-selected test and template':
    'Escoge si las invitaciones deberían enviarse en una fecha programada, ser enviadas una vez el encuestado sea añadido al proyecto o manualmente con un cuestionario y fecha previamente seleccionados.',
  'Reports generation': 'Generación de reportes',
  'Reports selected during project creation will be automatically generated after respondent submits their results':
    'Los reportes seleccionados durante la creación del proyecto serán generados automáticamente luego de que el encuestado envíe sus resultados.',
  'Direct feedback report may be activated, if available for the selected test too (check for details with your local distributor)':
    'El reporte de retroalimentación puede ser activado, si está disponible para el cuestionario ( Verifique los detalles con su distribuidor local) ',
  'Credits usage': 'Uso de créditos',
  'To ensure you have enough credits to get reports generated automatically, we are freezing the required cost per respondent once they get added to the project':
    'Para asegurar que se tienen suficientes créditos para que los reportes sean generados automáticamente, congelaremos los créditos necesarios por encuestado una vez sean añadidos al proyecto',
  Back: 'Atrás',
  invites: 'Invitaciones',
  summary: 'Resumen',
  'Project name': 'Nombre de proyecto',
  Category: 'Categoría',
  'Expire after': 'Caduca después de',
  'How to send invites': 'Cómo enviar las invitaciones',
  Manually: 'Manualmente',
  Automatically: 'Automáticamente',
  Schedule: 'Programada',
  Daily: 'Diario',
  Weekly: 'Semanal',
  Monthly: 'Mensual',
  'Do not remind': 'No recordar',
  defaultCompleteBioTemplate: '** Con plantilla de datos biográficos (Aplican todas las categorías) **',
  defaultEmptyBioTemplate: '** Sin plantilla de datos biográficos (No incluye ninguna las categorías) **',
  invitesSentToast: 'Las invitaciones han sido enviadas',
  selectNormForReport: 'Por favor selecciona una norma',
  selectIdealProfileForReport: 'Por favor seleccione el perfil ideal',
  visibleNormForReport: 'Usar norma para informes',
  hideNormForReport: 'Ocultar norma para informes',
  showNormTitle: 'Hacer visible para la lista de solicitudes de informes',
  showConfirmationMessageTitle: '¿Está seguro de que desea hacer visible',
  hideNormTitle: 'Ocultar para la lista de solicitudes de informes',
  hideConfirmationMessageTitle: '¿Está seguro de que desea eliminar',
  showhideConfirmationMessageDescription1: 'normas seleccionadas para la generación de informes?',
  showhideConfirmationMessageDescription2: 'esta norma para la generación de informes?',
  visible: 'Visible',
  normsVisibilityToast: 'La visibilidad de la norma se ha actualizado',
  updateGroupToast: 'El grupo ha sido actualizado',
  updateGroupErrorToast: 'Error durante la actualización del grupo',
  'Edit Group Details': 'Editar detalles del grupo',
  'Duplicate Group': 'Duplicar grupo',
  emptyLanguageReports: 'Lo siento, no pudimos encontrar ningún informe disponible para este idioma',
  Notes: 'Notas',
  'Forgot password?': '¿Olvidaste tu contraseña?',
  'You should receive your reset link soon at': 'Deberías recibir tu enlace de restablecimiento pronto en',
  "Can't find the email?": '¿No puedes encontrar el correo electrónico?',
  'You should also check your spam folder': 'También deberías revisar tu carpeta de spam',
  'Email address': 'Dirección de correo electrónico',
  'Request a reset link': 'Solicitar un enlace de restablecimiento',
  'Back to login page': 'Volver a la página de inicio de sesión',
  downloadInvitesReport: 'Descargar csv',
  'You are about to launch a managed group session': 'Estás a punto de iniciar una sesión supervisada grupal.',
  'While the session is active, actions with the group will be limited':
    'Mientras la sesión esté activa, las acciones con el grupo estarán limitadas',
  fullName: 'full_name',
  email: 'email',
  inviteURL: 'invite_url',
  testID: 'test_id',
  langID: 'lang_id',
  csvReportName: 'Reporte_Invitaciones_%{date}.csv',
  'Copy and share generated links for': 'Copiar y compartir enlaces generados para',
  'that have email address missing': 'que no tienen dirección de correo electrónico',
  'You have chosen a group of': 'Has elegido un grupo de',
  'have email address missing': 'no tienen dirección de correo electrónico',
  'This 360 survey is used by one or more project, therefore can not be edited or deleted':
    'Esta encuesta 360 es utilizada por uno o más proyectos, por lo tanto no se puede editar o eliminar',
  and: 'y',
  more: 'más',
  missingRequestedNorms: 'Faltan normas y/o perfiles ideales para los informes seleccionados',
  'You will be redirected shortly': 'Será redirigido en breve',
  oneRemainingNorm: 'Al menos una norma debe permanecer visible para esta prueba',
  'Set reference as required': "Establecer el campo de referencia como obligatorio",
  referenceAsRequired: "Utiliza el campo 'reference' en la invitación al encuestado para pasar información útil a tu registro de encuestado"
};
export default translations;
