import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Router, useNavigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import PrivateRoute from './reusable/PrivateRoute';
import TokenAutoAuth from './TokenAutoAuth';
import Login from './Login';
import LoginPassword from './LoginPassword';
import ForgotPassword from './ResetPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';

import Sidebar from './Sidebar/Sidebar';
import Spinner from './reusable/Spinner';

import EditRespondent from './EditRespondent';

import { fetchLanguages, fetchTags, fetchTestsAndLanguages, fetchUser } from '../store/user/actions';
import { fetchAvailableReportsLanguages, fetchReportsCount } from '../store/reports/actions';
import { fetchAssessmentsChanges } from '../store/tests/actions';
import { fetchTemplates } from '../store/settings/templates/actions';
import { fetchWelcomeTexts } from '../store/settings/welcome-texts/actions';
import { fetchIdealProfiles } from '../store/settings/ideal-profiles/actions';
import AddResponseScale from './Settings/AddRespondentScale/AddResponseScale';
import { Freshdesk } from './Freshdesk';
import { fetchNormChanges } from '../store/norms/actions';
// import EditProject from './EditProject/EditProject';

import isChineseEnv from '../utils/isChineseEnv';

import RespondentsTable from './Respondents/Respondents';
import RespondentsGroupTable from './Respondents/RespondentsGroup';
import GroupsTable from './Groups/Groups';
import ManagedGroupView from './Groups/ManagedGroupView';
import InvitesTable from './Invites/Invites';
import ReportsTable from './Reports/Reports';
import ProjectsTable from './Projects/Projects';
import SessionsTable from './Sessions/Sessions';
import AssessmentProject from './Projects/AssessmentProject/AssessmentProject';
import EditAssessmentProject from './Projects/EditAssessmentProject';
import Settings from './Settings/Settings';
import Norms from './Settings/Norms/Norms';
import NormScales from './Settings/Norms/NormScales';
import IdealProfiles from './Settings/IdealProfiles/IdealProfiles';
import IdealProfileScales from './Settings/IdealProfiles/IdealProfileScales';
import Templates from './Settings/Templates/Templates';
import TemplateScreen from './Settings/Templates/TemplateScreen';
import WelcomeTexts from './Settings/Templates/WelcomeTexts';
import WelcomeTextScreen from './Settings/Templates/WelcomeTextScreen';
import CompetencyGroups from './Settings/CompetencyGroups';
import CompetencyGroupsDetails from './Settings/CompetencyGroupsDetails';
import AssessmentsAndBatteries from './Settings/AssessmentsAndBatteries';
import CustomCompetencies from './Settings/CustomCompetencies';
import ResponseScales from './Settings/ResponseScales/ResponseScales';
import CreditsHistory from './Settings/Account/CreditsHistory';
import ReportsUsage from './Settings/Account/ReportsUsage';
import AssessmentsUsage from './Settings/Account/AssessmentsUsage';
import ActivityLog from './Settings/Account/ActivityLog';

import RespondentBioDataConfiguration from './Settings/UserConfiguration/RespondentBioDataConfiguration';
import GeneralAccountInfo from './Settings/Account/GeneralInfo';
import Tutorials from './Settings/Tutorials/Tutorials';
import ProjectReportsTable from './Raters/Reports';
import ProjectResultsTable from './Raters/Results';
import ProjectInvitesTable from './Raters/Invites';
import ProjectRatersTable from './Raters/Raters';

const InnerRoute = ({ children }) => {
  return <RouteWrapper>{children}</RouteWrapper>;
};

// const RespondentsTable = React.lazy(() => import('./Respondents/Respondents'));
// const GroupsTable = React.lazy(() => import('./Groups/Groups'));
// const ManagedGroupView = React.lazy(() => import('./Groups/ManagedGroupView'));
// const InvitesTable = React.lazy(() => import('./Invites/Invites'));
// const ReportsTable = React.lazy(() => import('./Reports/Reports'));
// const ProjectsTable = React.lazy(() => import('./Projects/Projects'));
// const SessionsTable = React.lazy(() => import('./Sessions/Sessions'));
// const AssessmentProject = React.lazy(() => import('./Projects/AssessmentProject/AssessmentProject'));
// const EditAssessmentProject = React.lazy(() => import('./Projects/EditAssessmentProject'));
// // const RatersTable = React.lazy(() => import('./Raters/Raters'));
// const Settings = React.lazy(() => import('./Settings/Settings'));
// const Norms = React.lazy(() => import('./Settings/Norms/Norms'));
// const NormScales = React.lazy(() => import('./Settings/Norms/NormScales'));
// const IdealProfiles = React.lazy(() => import('./Settings/IdealProfiles/IdealProfiles'));
// const IdealProfileScales = React.lazy(() => import('./Settings/IdealProfiles/IdealProfileScales'));
// const Templates = React.lazy(() => import('./Settings/Templates/Templates'));
// const TemplateScreen = React.lazy(() => import('./Settings/Templates/TemplateScreen'));
// const WelcomeTexts = React.lazy(() => import('./Settings/Templates/WelcomeTexts'));
// const WelcomeTextScreen = React.lazy(() => import('./Settings/Templates/WelcomeTextScreen'));
// const CompetencyGroups = React.lazy(() => import('./Settings/CompetencyGroups'));
// const CompetencyGroupsDetails = React.lazy(() => import('./Settings/CompetencyGroupsDetails'));
// const AssessmentsAndBatteries = React.lazy(() => import('./Settings/AssessmentsAndBatteries'));
// const CustomCompetencies = React.lazy(() => import('./Settings/CustomCompetencies'));
// const ResponseScales = React.lazy(() => import('./Settings/ResponseScales/ResponseScales'));
// const CreditsHistory = React.lazy(() => import('./Settings/Account/CreditsHistory'));
// const ReportsUsage = React.lazy(() => import('./Settings/Account/ReportsUsage'));
// const AssessmentsUsage = React.lazy(() => import('./Settings/Account/AssessmentsUsage'));
// const ActivityLog = React.lazy(() => import('./Settings/Account/ActivityLog'));
// const RespondentBioDataConfiguration = React.lazy(() =>
//   import('./Settings/UserConfiguration/RespondentBioDataConfiguration'),
// );
// const RespondentPersonalInfo = React.lazy(() => import('./Settings/UserConfiguration/RespondentPersonalInfo'));
// const GeneralAccountInfo = React.lazy(() => import('./Settings/Account/GeneralInfo'));
// const Tutorials = React.lazy(() => import('./Settings/Tutorials/Tutorials'));
// const ProjectReportsTable = React.lazy(() => import('./Raters/Reports'));
// const ProjectResultsTable = React.lazy(() => import('./Raters/Results'));
// const ProjectInvitesTable = React.lazy(() => import('./Raters/Invites'));
// const ProjectRatersTable = React.lazy(() => import('./Raters/Raters'));

const HomeNav = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchTestsAndLanguages());
    dispatch(fetchReportsCount());
    dispatch(fetchUser());
    dispatch(fetchTags());
    dispatch(fetchLanguages());
    dispatch(fetchIdealProfiles());
    dispatch(fetchAvailableReportsLanguages());
    dispatch(fetchTemplates());
    dispatch(fetchWelcomeTexts());

    // dispatch(fetchReportsChanges());
    // setInterval(() => {
    //   dispatch(fetchReportsChanges());
    // }, 60000);

    dispatch(fetchAssessmentsChanges());
    dispatch(fetchNormChanges(navigate));
    setInterval(() => {
      dispatch(fetchAssessmentsChanges());
      dispatch(fetchNormChanges(navigate));
    }, 60000);
  }, []);

  return (
    <Container>
      <Sidebar />
      {isChineseEnv ? '' : <Freshdesk />}
      <ContentContainer>
        <React.Suspense fallback={<Spinner isLoading />}>
          <StyledRouter>
            <InnerRoute path="/respondents">
              <RespondentsTable path="/*" />
              <EditRespondent path="/:respondentId" />
            </InnerRoute>
            <InnerRoute path="/groups">
              <GroupsTable path="/" />
              <RespondentsGroupTable path="/:groupId" />
              <ManagedGroupView path="/administer/:groupId" />
            </InnerRoute>
            <InnerRoute path="/projects">
              <ProjectsTable path="/*" />
              <ProjectsTable path="/:featureKeyword" />
              <SessionsTable path="360/:projectId" />
              <AssessmentProject path="assessment/:projectId" />
              <EditAssessmentProject path="assessment/:projectId/settings" />
              <InnerRoute path="/360/:projectId/session/:sessionId">
                {/* <RatersTable path="/*" /> */}
                <ProjectRatersTable path="/session-raters" />
                <ProjectInvitesTable path="/session-invites" />
                <ProjectResultsTable path="/session-results" />
                {user && user.userType !== 3 && <ProjectReportsTable path="/session-reports" />}
              </InnerRoute>
            </InnerRoute>
            <InnerRoute path="/invites">
              <InvitesTable path="/*" />
            </InnerRoute>
            {user && user.userType !== 3 && (
              <InnerRoute path="/reports">
                <ReportsTable path="/*" />
              </InnerRoute>
            )}
            <InnerRoute path="/settings">
              <Settings path="/" />
              <CompetencyGroups path="/360-assessments" />
              <CompetencyGroupsDetails path="/360-assessments/:groupId" />
              <AssessmentsAndBatteries path="/assessments-and-batteries" />
              <CustomCompetencies path="/custom-competencies" />
              <Templates path="/templates" />
              <TemplateScreen path="/templates/new" />
              <TemplateScreen path="/templates/:templateId" />
              <WelcomeTexts path="/welcome-texts" />
              <WelcomeTextScreen path="/welcome-texts/new" />
              <WelcomeTextScreen path="/welcome-texts/:welcomeTextId" />
              {user && user.userType !== 3 && (
                <InnerRoute path="/">
                  <IdealProfiles path="/ideal-profiles" />
                  <IdealProfileScales path="/ideal-profiles/:idealProfileId" />
                  <Norms path="/norms" />
                  <NormScales path="/norms/:normId" />
                  <CreditsHistory path="/credits-history" />
                  <ReportsUsage path="/reports-usage" />
                </InnerRoute>
              )}
              <ResponseScales path="/response-scales" />
              <AddResponseScale path="/add-response-scale" />
              <AddResponseScale path="/response-scales/:responseId" />
              <GeneralAccountInfo path="/general" />
              <AssessmentsUsage path="/assessment-usage" />
              <ActivityLog path="/activity-log" />
              <Tutorials path="/tutorials" />
              <RespondentBioDataConfiguration path="/biodata-config" />
            </InnerRoute>
          </StyledRouter>
        </React.Suspense>
      </ContentContainer>
    </Container>
  );
};

const ContentRouter = () => {
  return (
    <Router>
      <Login path="/" />
      <Login path="/login" />
      <LoginPassword path="/login-region" />
      <ForgotPassword path="/forgot-password" />
      <ResetPassword path="/reset-password/:resetToken" />
      <TokenAutoAuth path="/auth/:accessToken/refresh/:refreshToken" />
      <PrivateRoute path="/*" component={(props) => <HomeNav {...props} />} />
    </Router>
  );
};

const Container = styled.div`
  display: flex;
  min-height: 800px;
`;
const ContentContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const StyledRouter = styled(Router)`
  max-height: 100vh;
  height: 100vh;
`;

const RouteWrapper = styled.div`
  height: 100%;

  & > div {
    height: 100%;
  }
`;

export default ContentRouter;
