import handleActions from '../../immerHandleActions';
import * as actions from './actions';

const initialState = {
  norms: [],
  filteringOptions: {},
  testsWithSystemNorms: [],
  selectedTestId: ['15FQ+'],
};

const normsReducer = handleActions(
  {
    [actions.getUserNormsSuccess]: (draft, { payload: { norms } }) => {
      draft.norms = norms;
    },
    [actions.getTestsWithSystemNormsSuccess]: (draft, { payload: { tests } }) => {
      draft.testsWithSystemNorms = tests;
    },
    [actions.setNormVisibility]: (draft, { payload }) => {
      draft.norms = draft.norms.map((norm) => {
        if (norm.normID === payload.normID) {
          const newState = { ...norm, isVisible: payload.visible };
          return newState;
        }
        return norm;
      });
    },
    [actions.setFilteringOptions]: (draft, { payload }) => {
      draft.filteringOptions = payload;
    },
    [actions.setSelectedTestId]: (draft, { payload }) => {
      draft.selectedTestId = payload;
    },
  },
  initialState,
);

export default normsReducer;
